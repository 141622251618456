// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';

@Component({
  selector: 'sfl-delete-entity-dialog',
  templateUrl: './delete-entity-dialog.component.html'
})
export class DeleteEntityDialogComponent implements OnInit {
  viewLoading = false;
  disableCancel = false;
  buttonText = 'Delete';
  constructor(public dialogRef: MatDialogRef<DeleteEntityDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    if (this.data.buttonText) {
      this.buttonText = this.data.buttonText;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    this.disableCancel = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, messages.MODEL_TIMEOUT);
  }
  getMessage() {
    if (this.data.description.includes(ACTION_MESSAGES.USER_EXISTS_ANOTHER_PROPERTY)) {
      return ACTION_MESSAGES.ACTIVE_USER_WITH_CONFIRMATION
    }
  }
}
