import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sfl-quick-action',
  templateUrl: './quick-action.component.html'
})
export class QuickActionComponent implements OnInit, AfterViewInit {

  // Set icon class name
  @Input() icon = 'flaticon2-gear';

  @Input() iconType: '' | 'warning';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG;

  // Set bg image path
  @Input() bgImage;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() gridNavSkin: 'light' | 'dark' = 'light';

  constructor() {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  onSVGInserted(svg) {
    svg.classList.add('kt-svg-icon', 'kt-svg-icon--success', 'kt-svg-icon--lg');
  }
}
