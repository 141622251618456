import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { chatConfigDTO } from '@shared/models/chat.model';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
import { ChatConfigService } from '../chat_config.service';
@Component({
  selector: 'sfl-conversation-info',
  templateUrl: './conversation-info.component.html',
  styleUrls: ['./conversation-info.component.scss']
})
export class ConversationInfoComponent implements OnInit, OnDestroy {
  @Input('selectedRoomDetails') selectedRoomDetails: any;
  @Output() backToConversationRoom: EventEmitter<string> = new EventEmitter<string>();
  @Output() startUserListFromRoomInfo: EventEmitter<string> = new EventEmitter<string>();
  @Output() backToChatHistoryList: EventEmitter<string> = new EventEmitter<string>();
  GROUP_USER_IMAGE_PATH = messages.GROUP_USER_IMAGE_PATH;
  IMAGE_PATH = messages.DEFAULT_IMAGE_PATH;
  subscription = new Subscription();
  roomDetails: chatConfigDTO = new chatConfigDTO();
  userList = [];
  isAcceptUpdate = false;
  currentuserToken: string;
  isSpinner = false;
  constructor(
    private readonly chatService: ChatService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly chatConfigService: ChatConfigService
  ) {}

  ngOnInit() {
    console.log('Room Info', this.selectedRoomDetails);
    this.currentuserToken = sessionStorage.getItem('userId');
    this.getRoomMemberDetails();
    this.subscription.add(
      this.chatService.onUpdateRoomAckSubscriber.subscribe(
        (msg: any) => {
          if (msg) {
            if (this.selectedRoomDetails.roomId === msg.roomId) {
              this.isAcceptUpdate = false;
              this.chatService.onUpdateRoomNameAck(null);
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
        }
      )
    );
    this.subscription.add(
      this.chatService.chatRoomCreatedEvent.subscribe(msg => {
        this.selectedRoomDetails = msg;
      })
    );
    this.subscription.add(
      this.chatService.onMakeAdminSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg[0].roomId) {
            this.userList = [];
            this.getRoomMemberDetails();
            this.chatService.onMakeAdminToUser(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onRemoveUserSubscriber.subscribe((msg: any) => {
        if (msg) {
          const isUserRemovedFromRoom = this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId;
          const isLoggedInUserAndRemovedUserAreSame = msg.messageDTO.userToken === sessionStorage.getItem('userId');

          if (isUserRemovedFromRoom && isLoggedInUserAndRemovedUserAreSame) {
            this.backToChatHistoryList.emit();
          } else {
            this.userList = [];
            this.getRoomMemberDetails();
          }
          this.chatService.onRemoveUserToGroup(null);
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId) {
            this.userList = [];
            this.getRoomMemberDetails();
            this.chatService.onDeleteGroupByUser(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId) {
            this.chatService.onDeleteGroupByUserAck(false);
            this.backToChatHistoryList.emit();
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onLeaveGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.chatService.onLeaveGroupByUserAck(false);
          this.backToChatHistoryList.emit();
        }
      })
    );
    this.subscription.add(
      this.chatService.onLeaveGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId) {
            this.userList = [];
            this.getRoomMemberDetails();
            this.chatService.onLeaveGroupByUser(false);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onJoinGroup.asObservable().subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId) {
            this.userList = [];
            this.getRoomMemberDetails();
            this.chatService.onJoinGroupByUser(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onJoinGroupAck.asObservable().subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId) {
            this.userList = [];
            this.getRoomMemberDetails();
            this.chatService.onJoinGroupByUserAck(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onUpdateRoomSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomId) {
            this.selectedRoomDetails.roomName = msg.roomName;
            this.chatService.onUpdateRoomName(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdmin.asObservable().subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomId) {
            this.backToChatHistoryList.emit();
            this.chatService.onDeleteGroupByAdmin(null);
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdminAck.asObservable().subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomId) {
            this.backToChatHistoryList.emit();
            this.chatService.onDeleteGroupByAdminAck(null);
          }
        }
      })
    );
  }
  getRoomMemberDetails() {
    this.isSpinner = true;
    setTimeout(() => {
      this.subscription.add(
        this.chatService.getRoomMemberDetails(this.selectedRoomDetails.roomId).subscribe(
          (msg: any) => {
            this.isSpinner = false;
            this.userList = this.swapArray(msg);
          },
          (error: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
          }
        )
      );
    }, 500);
  }
  onClickSetRoomName() {
    this.roomDetails.roomId = this.selectedRoomDetails.roomId;
    this.roomDetails.roomName = this.selectedRoomDetails.roomName;
    this.roomDetails.imageUrl = '';
    this.roomDetails.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.roomDetails.userToken = sessionStorage.getItem('userId');
    this.chatConfigService.updateChatRoomName(this.roomDetails);
  }
  onClickEnableRoomName() {
    this.isAcceptUpdate = !this.isAcceptUpdate;
  }
  onClickBackToConversationRoom() {
    this.backToConversationRoom.emit(this.selectedRoomDetails);
  }
  onClickAddUser() {
    this.startUserListFromRoomInfo.emit(this.selectedRoomDetails);
  }
  onClickMakeAdmin(item) {
    this.roomDetails.members = [item.userToken];
    this.roomDetails.roomId = this.selectedRoomDetails.roomId;
    this.roomDetails.userToken = sessionStorage.getItem('userId');
    this.roomDetails.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.makeAdminToChatRoomUser(this.roomDetails);
  }
  onClickRemoveFromGroup(item) {
    this.roomDetails.members = [item.userToken];
    this.roomDetails.roomId = this.selectedRoomDetails.roomId;
    this.roomDetails.userToken = sessionStorage.getItem('userId');
    this.roomDetails.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.removeUserFromGroup(this.roomDetails);
  }
  onClickDeleteGroup() {
    let userRoleType = this.selectedRoomDetails.currentUserType;
    this.roomDetails.roomId = this.selectedRoomDetails.roomId;
    this.roomDetails.userToken = sessionStorage.getItem('userId');
    this.roomDetails.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.deleteGroup(this.roomDetails, userRoleType);
  }
  onClickLeaveGroup() {
    this.roomDetails.members = [sessionStorage.getItem('userId')];
    this.roomDetails.roomId = this.selectedRoomDetails.roomId;
    this.roomDetails.userToken = sessionStorage.getItem('userId');
    this.roomDetails.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.leaveUserFromGroup(this.roomDetails);
  }
  swapArray(userList: any) {
    let creatorIndex = userList.findIndex(x => x.type === 'CREATOR');
    if (creatorIndex === 0 || creatorIndex > 0) {
      var temp = userList[0];
      userList[0] = userList[creatorIndex];
      userList[creatorIndex] = temp;
    }
    return userList;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
