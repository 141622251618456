export class UserProfileModel {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  notification: boolean;
  imageUrl: string;
  firebaseToken: string;

  companyName: string;
  loopVendorEmailId: string;
  suitNumber: string;
  openSurveyNotification: boolean;
}

export class UploadImageModel {
  documentUrl: string;
}
