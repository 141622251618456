import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Subject, Observable } from 'rxjs';

interface RecordedAudioOutput {
    blob: Blob;
    title: string;
  }

@Injectable({
    providedIn: 'root'
})
export class AudioRecordingService {

    constructor() {
    }

    private stream;
    private recorder;
    private readonly interval;
    private startTime;
    private readonly _recorded = new Subject<any>();
    private readonly _recordingTime = new Subject<string>();
    private readonly _recordingFailed = new Subject<string>();


    getRecordedBlob(): Observable<RecordedAudioOutput> {
        return this._recorded.asObservable();
    }

    getRecordedTime(): Observable<string> {
        return this._recordingTime.asObservable();
    }

    recordingFailed(): Observable<string> {
        return this._recordingFailed.asObservable();
    }

    startRecording() {

        if (this.recorder) {
          // It means recording is already started or it is already recording something
          return;
        }
    
        this._recordingTime.next('00:00');
        navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
          this.stream = s;
          this.record();
        }).catch(error => {
          this._recordingFailed.next();
        });
    
      }
    
      abortRecording() {
        this.stopMedia();
      }
    
      private record() {
    
        this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
          type: 'audio',
          mimeType: 'audio/webm'
        });
    
        this.recorder.record();
        // For future use
        this.startTime = true;
      }
    
      private toString(value) {
        let val = value;
        if (!value) {
          val = '00';
        }
        if (value < 10) {
          val = '0' + value;
        }
        return val;
      }
    
      stopRecording() {
        let file: File;
        if (this.recorder) {
          this.recorder.stop((blob) => {
            file = <File>blob;
            if (this.startTime) {
              const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
              this.stopMedia();
              this._recorded.next({ blob: blob, title: mp3Name });
            }
          }, () => {
            this.stopMedia();
            this._recordingFailed.next();
          });
        }
      }
    
      private stopMedia() {
        if (this.recorder) {
          this.recorder = null;
          clearInterval(this.interval);
          this.startTime = false;
          if (this.stream) {
            this.stream.getAudioTracks().forEach(track => track.stop());
            this.stream = null;
          }
        }
      }
}