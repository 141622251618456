import { Pageable } from '@shared/services/utils';
import { BaseModel } from './base.model';

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export class Inspections extends BaseModel {
  id: number;
  propertyName: string;
  accessToken: string;
  refreshToken: string;
  inspectionDate: string;
  inspectedBy: string;
  inspectionFor: string;
  inspectionId: number;
  contactPersonName: string;
  phone: string;
  area: string;
  note1: string;
  note2: string;
  verified: string;
  fullAddress: string;
  address: Address = new Address();
  question: string;
  option: string;

  clear(): void {
    this.id = undefined;
    this.propertyName = '';
    this.inspectionDate = '';
    this.inspectedBy = '';
    this.inspectionFor = '';
    this.contactPersonName = '';
    this.phone = '';
    this.area = '';
    this.note1 = '';
    this.note2 = '';
    this.verified = '';
  }
}
export class InspectionsPagebleModel extends Pageable {
  content: Inspections[] = [];
}

export class InspectionsList {
  content: InspectionsDetail[] = [];
}

export class InspectionsDetail {
  id: string;
  prop_name: string;
  prop_address: string;
  prop_poc: string;
  inspectedBy: string;
  mob_number: string;
  location_varified: boolean;
  areas: [] = [];
  inspection_date: string;
  inspectedFor: string;
  responses: InspectionsAreaName[] = [];
  score: string;
  counts: OptionCount;
  question: string;
  option: Options[] = [];
  latitude: number;
  longitude: number;
}

export class InspectionsAreaName {
  areaName: string;
  inspectionResponses: InspectionsNote[] = [];
}

export class InspectionsNote {
  question: string;
  options: Options[] = [];
  note: Notes;
  images: AttachmentDetail[] = [];
}
export class Options {
  option: string;
  selected: boolean;
}
export class Notes {
  noteText: string;
  noteId: number;
}
export class OptionCount {
  optionCount: OptionsScore[] = [];
}
export class OptionsScore {
  option: string;
  count: number;
  color: string;
}

export class AttachmentDetail {
  documentUrl: string;
  documentType: string;
}

export class AttachmentList extends Pageable {
  content: AttachmentDetail[] = [];
}
