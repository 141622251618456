// Angular
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatBadgeModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
} from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MyProfileComponent } from '@layout/my-profile/my-profile.component';
// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
// Core module
import { SharedModule } from '@shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ObserverEditComponent } from 'src/app/building/observer-edit/observer-edit.component';
import { UserEditComponent } from 'src/app/building/user-edit/user-edit.component';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';
// CRUD Partials
import { ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent } from './content/crud';
import { ActivateUserComponent } from './content/crud/activate-user/activate-user.component';
import { AlertInfoDialogComponent } from './content/crud/alert-info-dialog/alert-info-dialog.component';
import { GalleryDialogComponent } from './content/crud/gallery-dialog/gallery-dialog.component';
import { PropertySelectionDialogComponent } from './content/crud/property-selection-dialog/property-selection-dialog.component';
import { ViewProfileComponent } from './content/crud/view-profile/view-profile.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Layout partials
import { UserProfileQrCodeComponent } from 'src/app/building/user-profile-qr-code/user-profile-qr-code.component';
import { ChatModule } from 'src/app/chat/chat.module';
import { ReactedUserListComponent } from './content/crud/reacted-user-list/reacted-user-list.component';
import { TermsConditionDialogComponent } from './content/crud/terms-condition-dialog/terms-condition-dialog.component';
import { UserListDialogComponent } from './content/crud/user-list-dialog/user-list-dialog.component';
import {
  NotificationComponent,
  QuickActionComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  Subheader1Component,
  Subheader2Component,
  Subheader3Component,
  UserProfileComponent,
} from './layout';
import { ChatPanelComponent } from './layout/chat-panel/chat-panel.component';
import { CartComponent } from './layout/topbar/cart/cart.component';
@NgModule({
  declarations: [
    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ActivateUserComponent,
    AlertComponent,

    // topbar components
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    CartComponent,
    ChangePasswordComponent,
    MyProfileComponent,
    AlertInfoDialogComponent,
    ActivateUserComponent,
    PropertySelectionDialogComponent,
    UserEditComponent,
    UserProfileQrCodeComponent,
    ObserverEditComponent,
    GalleryDialogComponent,
    ViewProfileComponent,
    ReactedUserListComponent,
    UserListDialogComponent,
    ChatPanelComponent,
    TermsConditionDialogComponent,
  ],
  exports: [
    PortletModule,

    ScrollTopComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    ActivateUserComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    PropertySelectionDialogComponent,
    // topbar components
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    NotificationComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    CartComponent,
    AlertInfoDialogComponent,
    UserEditComponent,
    UserProfileQrCodeComponent,
    ObserverEditComponent,
    GalleryDialogComponent,
    ViewProfileComponent,
    ReactedUserListComponent,
    UserListDialogComponent,
    ChatPanelComponent,
    TermsConditionDialogComponent,
  ],
  imports: [
    PortletModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    InlineSVGModule,
    // angular material modules
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    InfiniteScrollModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    MatSidenavModule,
    MatBadgeModule,
    ChatModule,
  ],
  entryComponents: [
    ChangePasswordComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    PropertySelectionDialogComponent,
    ActivateUserComponent,
    AlertInfoDialogComponent,
    FetchEntityDialogComponent,
    GalleryDialogComponent,
    ViewProfileComponent,
    ReactedUserListComponent,
    UserListDialogComponent,
    TermsConditionDialogComponent,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class PartialsModule {}
