import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { LayoutConfigService, LayoutUtilsService, MessageType } from '@layout/services';
import { LayoutService } from '@layout/services/layout.service';
import { messages } from '@shared/constants/app.constants';
import { ToggleOptions } from '@shared/directives';
import { Subscription } from 'rxjs';
import { HtmlClassService } from '../services/html-class.service';
@Component({
  selector: 'sfl-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit, AfterViewInit {
  headerLogo: string;
  headerStickyLogo: string;
  LOGO_NEW = messages.LOGO_NEW;
  GRID_IMAGE = messages.GRID_IMAGE;
  LOGO_RXA = messages.LOGO_RXA;
  LOGO_RXM = messages.LOGO_RXM;
  isRXA = environment.isRXA;
  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-aside--minimize',
    togglerState: 'kt-aside__brand-aside-toggler--active',
  };

  constructor(
    private readonly layoutConfigService: LayoutConfigService,
    public htmlClassService: HtmlClassService,
    public readonly layoutService: LayoutService,
    private readonly layoutUtilsService: LayoutUtilsService,
  ) {}
  subscriptions = new Subscription();
  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();
    this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
  }
  onClickRedirectToRXA(item) {
    this.subscriptions.add(
      this.layoutService.getIdForRedirectToRXA(item).subscribe(
        (uuid: string) => {
          if (item === 'analytics') {
            window.location.href = `${environment.urlRXA}/redirect?uuid=${uuid}`;
          } else if (item === 'manufacturing') {
            window.location.href = `${environment.urlRXM}/redirect?uuid=${uuid}`;
          }
        },
        (error: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
        },
      ),
    );
  }
  ngAfterViewInit(): void {}
}
