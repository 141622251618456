import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[sflPhoneValidate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true }]
})
export class PhoneValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validatePhoneFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validatePhoneFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value && c.value.toString().match(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/)) {
      return null;
    } else {
      return {
        appPhoneValidate: true
      };
    }
  };
}

@Directive({
  selector: '[sflPasswordValidate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }]
})
export class PasswordValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validatePasswordFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validatePasswordFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value && c.value.match('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}')) {
      return null;
    } else {
      return {
        appPasswordValidate: true
      };
    }
  };
}

@Directive({
  selector: '[sflNoSpaceValidate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredNoSpaceValidator,
      multi: true
    }
  ]
})
export class RequiredNoSpaceValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateNoSpaceFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateNoSpaceFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (c.value.trim() !== '') {
        return null;
      } else {
        return {
          appNoSpaceValidate: true
        };
      }
    } else {
      return {
        appNoSpaceValidate: true
      };
    }
  };
}

@Directive({
  selector: '[sflEmailValidate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})
export class EmailValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateEmailFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateEmailFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (
        c.value.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,1}\.[0-9]{1,1}\.[0-9]{1,1}\.[0-9]{1,1}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[^ ]*$/
        )
      ) {
        return null;
      } else {
        return {
          appEmailValidate: true
        };
      }
    }
  };
}

@Directive({
  selector: '[sflAutoFocus]'
})
export class AutofocusDirective implements AfterContentInit {
  @Input() sflAutoFocus: boolean;

  constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 200);
  }
}
