import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PortletBodyComponent } from './portlet-body.component';
import { PortletFooterComponent } from './portlet-footer.component';

export interface PortletOptions {
  test?: any;
}

@Component({
  selector: 'sfl-portlet',
  templateUrl: './portlet.component.html',
  exportAs: 'ktPortlet'
})
export class PortletComponent implements OnInit, AfterViewInit {
  // Public properties
  @Input() loading$: Observable<boolean>;
  // portlet extra options
  @Input() options: PortletOptions;
  // portlet root classes
  @Input() class: string;

  @ViewChild('portlet', { static: true }) portlet: ElementRef;

  // portlet body component template
  @ViewChild(PortletBodyComponent, { static: true }) body: PortletBodyComponent;
  // portlet footer component template
  @ViewChild(PortletFooterComponent, { static: true })
  footer: PortletFooterComponent;

  constructor(
    public loader: LoadingBarService
  ) {
    this.loader.complete();
  }

  ngOnInit() {}

  ngAfterViewInit() {}
}
