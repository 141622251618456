import { Component } from '@angular/core';
import { ScrollTopOptions } from '@shared/directives';

@Component({
  selector: 'sfl-scroll-top',
  templateUrl: './scroll-top.component.html'
})
export class ScrollTopComponent {
  // Public properties
  scrollTopOptions: ScrollTopOptions = {
    offset: 300,
    speed: 600
  };
}
