import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'sfl-terms-condition-dialog',
  templateUrl: './terms-condition-dialog.component.html',
  styleUrls: ['./terms-condition-dialog.component.scss']
})
export class TermsConditionDialogComponent implements OnInit {
  isChecked = false;
  constructor(
    public dialogRef: MatDialogRef<TermsConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly router: Router
  ) {}

  ngOnInit() {
    console.log(this.data);
  }
  onNoClick() {
    this.dialogRef.close();
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
}
