// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
// Partials for CRUD
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from '@layout/partials/content/crud';
import { messages } from '@shared/constants/app.constants';

export enum MessageType {
  Create,
  Read,
  Update,
  Delete
}

@Injectable()
export class LayoutUtilsService {

  constructor(private readonly snackBar: MatSnackBar, private readonly dialog: MatDialog) {}

  showActionNotification(
    oMessage: string,
    oType: MessageType = MessageType.Create,
    oDuration: number = messages.oDuration,
    oShowCloseButton = true,
    oShowUndoButton = true,
    oUndoButtonDuration: number = messages.oUndoButtonDuration,
    oVerticalPosition: 'top' | 'bottom' = 'bottom'
  ) {
    const oData = {
      message: oMessage,
      snackBar: this.snackBar,
      showCloseButton: oShowCloseButton,
      showUndoButton: oShowUndoButton,
      undoButtonDuration: oUndoButtonDuration,
      verticalPosition: oVerticalPosition,
      type: oType,
      action: 'Undo'
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: oDuration,
      data: oData,
      verticalPosition: oVerticalPosition
    });
  }

  deleteElement(
    title = '',
    description = '',
    waitDesciption = ''
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px'
    });
  }

  fetchElements(dataInfo) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: dataInfo,
      width: '400px'
    });
  }

  updateStatusForEntities(title, statuses, messages) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: '480px'
    });
  }
}
