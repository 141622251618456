import { SortDirection } from '@angular/material/sort';
import { Pageable } from '@shared/services/utils';
import { State } from './login.model';
import { FeaturesDTOList } from './user-feature.model';
export class User {
  id: number;
  firstName: string;
  lastName: string;
  emailId: string;
  accessToken: string;
  refreshToken: string;
  roleName: string;
  position: string;
  fullname: string;
  phoneNumber: string;
  address: Address = new Address();
  imageUrl: string;
  companyName: string;
  managementCompanyEmail: string;
  manager: boolean;
  propertyUUIDList: string[];
  propertyId: string;
  defaultPOC: boolean;
  vendorPOCFirstName: string;
  vendorPOCLastName: string;
  vendorPOCPhoneNumber: string;
  featuresDTOS: FeaturesDTOList[] = [];
  positionUpdate: boolean;
  featureUpdate: boolean;
  companyPropertyId: number;
  companyPropertyUserType: string;
  companyPropertyUserId: number;
  defaultUser: boolean;
  userScreen: string;
  companyDisplayName: string;
  companyType: string;
  checked: boolean;
  clear(): void {
    this.id = undefined;
    this.firstName = '';
    this.lastName = '';
    this.emailId = '';
    this.roleName = '';
    this.position = '';
    this.phoneNumber = '';
    this.address = new Address();
  }
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: State[] = [];
  zipCode: string;
}

export class UserPageableModel extends Pageable {
  content: User[] = [];
}

export class DeleteUserModel {
  emailId: string;
  status: boolean;
}
export class Property {
  propertyId: string;
  propertyName: string;
  companyPropertyId: number;
}
export class PropertyDetails extends Property {
  userPosition: string;
  companyPropertyId: number;
  isActive: boolean;
}

export class PropertyArray {
  properties: Property[] = [];
}

export class UsersArray {
  firstName: string;
  lastName: string;
  emailId: string;
  imageUrl: string;
  defaultObserver: boolean;
}
export class PropertyUsers {
  users: UsersArray[] = [];
}

export class ManagerArray {
  defaultPoc: boolean;
  lastName: string;
  emailId: string;
  imageUrl: string;
}
export class PropertyManagers {
  users: ManagerArray[] = [];
}

export class PropertyUserPageableQuery {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  search: string;
  status: boolean;
  propertyId: string;
  companyPropertyId: number;
  companyPropertyUserTypes: string;
  timeZone: string;
}
