import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'dateTimeSlot'
})
export class DateTimeSlotPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date): string {
    const currentDate = new Date();
    const inputDate = new Date(value);

    if (this.isSameDay(currentDate, inputDate)) {
      return this.datePipe.transform(inputDate, 'hh:mm a');
    } else if (this.isYesterday(currentDate, inputDate)) {
      return 'Yesterday ' + this.datePipe.transform(inputDate, 'hh:mm a');
    } else if (this.isSameYear(currentDate, inputDate)) {
      return this.datePipe.transform(inputDate, ' MMM dd hh:mm a');
    } else {
      return this.datePipe.transform(inputDate, ' MMM dd yyyy hh:mm a');
    }
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  private isYesterday(date1: Date, date2: Date): boolean {
    const yesterday = new Date(date1);
    yesterday.setDate(date1.getDate() - 1);
    return this.isSameDay(yesterday, date2);
  }

  private isSameYear(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear();
  }
}
