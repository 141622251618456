import { Pageable } from '@shared/services/utils';
import { BaseModel } from './base.model';

export class CreateBuilding extends BaseModel {
  id: number;
  buildingName: string;
  buildingPoc: string;
  cleaningPoc: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  contactPersonName: string;
  contactFirstPersonName: string;
  contactLastPerson: string;
  cleaningCompany: string;
  propertyManagementCompany: string;
  type: string;
  buildingPOCFirstName: string;
  buildingPOCLastName: string;
  buildingPOCEmail: string;
  status: string;
  areas: string;
  areaName: string;
  employeeName: string;

  clear(): void {
    this.id = undefined;
    this.buildingName = '';
    this.buildingPoc = '';
    this.cleaningPoc = '';
    this.city = '';
    this.email = '';
    this.state = '';
    this.contactPersonName = '';
    this.cleaningCompany = '';
    this.propertyManagementCompany = '';
    this.type = '';
    this.buildingPOCFirstName = '';
    this.buildingPOCLastName = '';
    this.buildingPOCEmail = '';
    this.status = '';
    this.areas = '';
    this.areaName = '';
    this.employeeName = '';
  }
}

export class LoopVendors {
  id: number;
  companyName: string;
  companyDisplayName: string;
  address: Address = new Address();
  companyEmailId: string;
  accessToken: string;
  refreshToken: string;
  phoneNumber: string;
  fax: string;
  contactPersonName: string;
  status: string;
  building: string;
  note: string;
  cleaningCompany;
  string;
  propertyManagementCompany: string;
  editStatus: string;
  adminFirstName: string;
  adminLastName: string;
  adminPhoneNumber: string;
  adminEmailId: string;
  mobile: string;
  suitNumber: string;

  clear(): void {
    this.companyName = '';
    this.companyEmailId = '';
    this.phoneNumber = '';
    this.contactPersonName = '';
    this.status = '';
    this.building = '';
    this.cleaningCompany = '';
    this.propertyManagementCompany = '';
  }
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export class Property {
  address: Address = new Address();
  propertyName: string;
  ccPOCEmailId: string;
  isActive: boolean;
  pmcName: string;
  pmcPOCEmailId: string;
  pmcPOCFirstName: string;
  pmcPOCLastName: string;
  pmcPOCPhoneNumber: string;
  areas: number;
  cc: string;
  ccPOC: string;
  fullAddress: string;
  propertyId: string;
  cleaningCompanyName: string;
  cleaningPOCFirstName: string;
  cleaningPOCLastName: string;
  cleaningCompanyEmailId: string;
  latitude: number;
  longitude: number;
  sendNotification: boolean;
  standPOCChangeVMList: StandAlonePOCList[] = [];
  userPosition: string;
  companyPropertyId: number;
}
export class ExtendedQRProperty extends Property {
  userPosition: string;
}
export class PropertyList extends Pageable {
  content: Property[] = [];
}

export class CompanyUser {
  emailId: string;
}

export class PropertyCCPOC {
  companyUsers: CompanyUser[] = [];
}

export class PMCName {
  companyName: string;
}

export class PropertyPMC {
  comapies: PMCName[] = [];
}

export class CCPOC {
  default: boolean;
  emailId: string;
}

export class UpdateCCPOC {
  candidates: CCPOC[] = [];
}

export class PropertyDataByEmail {
  address: Address = new Address();
  firstName: string;
  lastName: string;
  phoneNumber: number;
  roleName: string;
  emailId: string;
}

export class AddTenant {
  suitNumber: string;
  email: string;
  firstName: string;
  lastName: string;
}

export class PMCPOCName {
  emailId: string;
  firstName: string;
  lastName: string;
}

export class AddObserver {
  imageUrl: string;
  emailId: string;
  firstName: string;
  lastName: string;
  defaultObserver: boolean;
}

export class Observers {
  existing: AddObserver[] = [];
  additional: AddObserver[] = [];
}

export class DeleteObserver {
  message: string;
}

export class TenantList extends Pageable {
  content: Tenant[] = [];
}

export class Tenant {
  propertyId: string;
  tenantVM: AddTenant[] = [];
}

export class PropertyName {
  propertyId: string;
  propertyName: string;
}

export class Area {
  areaName: string;
  emails: EmployeeEmail[] = [];
  id: string;
  status: boolean;
}

export class ManageArea {
  areaVM: Area[] = [];
  propertyId: string;
}

export class EmployeeEmail {
  emailId: string;
}

export class Employees {
  companyUsers: EmployeeEmail[] = [];
}

export class ReplaceStandAlonePOC {
  ccPOCEmailId: string;
  pmcName: string;
  propertyId: string;
  pmcPOCEmailId: string;
  standPOCChangeVMList: StandAlonePOCList[] = [];
}

export class StandAlonePOCList {
  pmcPOCEmailId: string;
  pmcPOCFirstName: string;
  pmcPOCLastName: string;
  pmcPOCPhoneNumber: string;
}
