export const ACTION_MESSAGES = {
  LOGIN_FAILURE: 'Invalid Email or Password',
  FORGET_PASSWORD_INIT_SUCCESS: 'Please Check Your Email, A One Time Password Has Been Sent To Reset Your Password',
  INVALID_EMAIL:
    'Our Apologies. The Email Address You Entered Isn’t In Our System. Please Check The Address On Your End. If The Issue Continues, Contact Us And We Will Look Into The Issue.',
  RESET_PASSWORD_SUCCESS: 'Your Password Has Been Changed Successfully. You Can Now Login Using Your New Password',
  PROFILE_UPDATE_SUCCESS: 'Profile Is Updated Successfully',
  CHANGE_PASSWORD_SUCCESS: 'Password Is Changed Successfully',
  ADD_CLEANING_COMPANY_SUCCESS: 'Cleaning Company Added successfully',
  ADD_USER_SUCCESS: 'User is created successfully for the Property',
  EDIT_USER_SUCCESS: 'User is updated successfully for the Property',
  DELETE_USER_SUCCESS: 'User Deleted Successfully',
  ACTIVATE_USER_SUCCESS: 'User Activated Successfully',
  ALERT_WARNING: {
    ALERT_TITLE: 'Delete User',
    ALERT_DESC: 'Are You Sure You Want To Delete This User?',
    ALERT_WAITING: 'User Has Been Deleted'
  },
  DEACTIVATE_USER_ALERT_WARNING: {
    ALERT_TITLE: 'Deactivate User',
    ALERT_DESC: 'Are You Sure You Want To Deactivate This User?',
    ALERT_WAITING: 'User Has Been Deactivated',
    BUTTON: 'Deactivate'
  },
  DELETE_INSPECTION_ALERT_WARNING: {
    ALERT_TITLE: 'Delete Inspection',
    ALERT_DESC: 'Are You Sure You Want To Delete This Inspection?',
    ALERT_WAITING: 'Inspection Has Been Deleted',
    BUTTON: 'Delete'
  },
  ACTIVATE_WARNING: {
    ALERT_TITLE: 'Activate User',
    ALERT_DESC: 'Are You Sure You Want To Activate This User?',
    ALERT_WAITING: 'User Has Been Activated',
    BUTTON: 'Activate'
  },
  DELETE_OPTION_WARNING: {
    ALERT_TITLE: 'Delete Option',
    ALERT_DESC: 'Are You Sure You Want To Delete This Option?',
    ALERT_WAITING: 'Option Has Been Deleted'
  },
  DELETE_QUESTION_WARNING: {
    ALERT_TITLE: 'Delete Question',
    ALERT_DESC: 'Are You Sure You Want To Delete This Question?',
    ALERT_WAITING: 'Question Has Been Deleted'
  },
  DELETE_AREA_WARNING: {
    ALERT_TITLE: 'Delete Area',
    ALERT_DESC: 'Are You Sure You Want To Delete This Area?',
    ALERT_WAITING: 'Area Has Been Deleted'
  },
  ALERT_WARNING_FOR_TENANT: {
    ALERT_TITLE: 'Delete Tenant',
    ALERT_DESC: 'Are You Sure You Want To Delete This Tenant?',
    ALERT_WAITING: 'Teant Has Been Deleted'
  },
  ALERT_FOR_COMPANY_REGISTERED: {
    ALERT_DESC:
      'Great! Thank you for signing up. We have sent a one time passcode to your email address. Since we just met, your email may not know us yet, so check your spam and junk folder.'
  },
  EDIT_CLEANING_COMPANY_SUCCESS: 'Cleaning Company Updated Successfully',
  ADD_PROPERTY_COMPANY_SUCCESS: 'Management Company Added Successfully',
  EDIT_PROPERTY_COMPANY_SUCCESS: 'Management Company Updated Successfully',
  ADD_PROPERTY_SUCCESS: 'Property Added Successfully',
  UPDATE_PROPERTY_SUCCESS: 'Property Updated Successfully',
  REPLACE_POC_SUCCESS: 'POC Is Replaced Successfully',
  ADD_TICKET_SUCCESS: 'Your Request Has Been Submitted Successfully.',
  INSIDE_TICKET_SUCCESS: 'Ticket Created Successfully',
  ADD_SURVEY_SUCCESS: 'Survey Added Successfully',
  EDIT_SURVEY_SUCCESS: 'Survey Updated Successfully',
  DELETE_SURVEY_QUESTION_SUCCESS: 'Survey Question Deleted Successfully',
  UPDATE_TICKET_SUCCESS: 'Ticket Updated Successfully',
  ADD_DEFAULT_OBSERVER_MESSAGE: 'Observer Modified Successfully',
  ADD_NOTE_SUCCESS: 'Note Added Successfully',
  ADD_INSPECTION_QUESTION_CONFIG: 'Inspection Options Have Been Created Successfully',
  ADD_INSPECTION_QUESTION: 'Inspection Questions Are Created Successfully',
  ADD_TENANT: 'Tenant Added Successfully',
  ADD_EVENT_SUCCESS: 'Event Added Successfully',
  EDIT_INSPECTION_QUESTION: 'Inspection Questions Updated Successfully',
  DELETE_INSPECTION_OPTION: 'Inspection Option Updated Successfully',
  STATUS_CHANGE: {
    STATUS_CHANGE_TITLE: 'Update Ticket Status',
    STATUS_BODY: 'Are You Sure You Want To Change The Ticket Status To Resolved?',
    STATUS_SUCCESS: 'Ticket Status Was Updated Succcesfully',
    STATUS_BODY_RESOLVED_AND_FLAGGED: 'Are You Sure You Want To Change The Ticket Status To Resolved and Flagged?',
    STATUS_SUSCESS_WITHOUT_TEXT: 'Ticket Created Successfully. External Users Number Is Not Valid.',
    STATUS_SUSCESS_WITHOUT_TEXT_LIST: 'Ticket Status Updated Successfully. External Users Number Is Not Valid.'
  },
  ADD_QR_CODE_SUCCESS: ' QR Code Added Successfully',
  UPDATE_EVENT_SUCCESS: 'Event Updated Successfully',
  UPDATE_EVENT_SUCCESS_WITHOUT_TEXT: 'Event Created Successfully. External Users Number Is Not Valid.',

  ADD_AREA_MESSAGE: 'Area Added Successfully',
  SIGN_UP_SUCCESS: 'You Have Registerd Successfully',
  EDIT_QRCODE_SUCCESS: 'QR Code Updated Successfully',
  DELETE_QRCODE_SUCCESS: 'QR Code Deleted Successfully',
  ALERT_WARNING_QR_CODE: {
    ALERT_TITLE: 'Delete QR Code',
    ALERT_DESC: 'Are You Sure You Want To Delete This QR Code?',
    ALERT_WAITING: 'QR Code Has Been Deleted'
  },
  DELETE_LOOPVENDOR_SUCCESS: 'Loop Vendor Deleted Successfully',
  ACTIVATE_LOOPVENDOR_SUCCESS: 'Loop Vendor Activated Successfully',
  DELETE_LOOPVENDOR_FOR_PROPERTY: {
    ALERT_TITLE: 'Deactivate Loop Vendor',
    ALERT_DESC: 'Are You Sure You Want To Deactivate This Loop Vendor?',
    ALERT_WAITING: 'Loop Vendor Has Been Deactivated'
  },
  REACTIVATE_LOOPVENDOR_FOR_PROPERTY: {
    ALERT_TITLE: 'Activate Loop Vendor',
    ALERT_DESC: 'Are You Sure You Want To Activate This Loop Vendor?',
    ALERT_WAITING: 'Loop Vendor Has Been Activate',
    BUTTON: 'Activate'
  },
  DELETE_OBSERVER_FOR_PROPERTY: {
    ALERT_TITLE: 'Deactivate Observer',
    ALERT_DESC: 'Are You Sure You Want To Deactivate This Observer?',
    ALERT_WAITING: 'Observer Has Been Deactivated',
    BUTTON: 'Deactivate'
  },
  REACTIVATE_OBSERVER_FOR_PROPERTY: {
    ALERT_TITLE: 'Activate Observer',
    ALERT_DESC: 'Are You Sure You Want To Activate This Observer?',
    ALERT_WAITING: 'Observer Has Been Activate',
    BUTTON: 'Activate'
  },
  DELETE_LOOPVENDOR_POC_WARNING: {
    ALERT_TITLE: 'Delete Loop Vendor POC',
    ALERT_DESC: 'Are You Sure You Want To Delete This Loop Vendor POC?',
    ALERT_WAITING: 'Loop Vendor POC Has Been Deleted'
  },
  DELETE_LOOPPROPERTY_WARNING: {
    ALERT_TITLE: 'Delete Loop Property',
    ALERT_DESC: 'Are You Sure You Want To Delete This Loop Property?',
    ALERT_WAITING: 'Loop Property Has Been Deleted'
  },
  DELETE_LOOPPROPERTY_POC_WARNING: {
    ALERT_TITLE: 'Delete Loop Property POC',
    ALERT_DESC: 'Are You Sure You Want To Delete This Loop Property POC?',
    ALERT_WAITING: 'Loop Property POC Has Been Deleted'
  },
  DELETE_EVENT_WARNING: {
    ALERT_TITLE: 'Delete Event',
    ALERT_DESC_ONE: 'Are You Sure to Delete Current Occurrence of This Event?',
    ALERT_DESC_ALL: 'Are You Sure to Delete All Occurrences of This Event?',
    ALERT_WAITING: 'Event Has Been Deleted',
    BUTTON: 'Delete'
  },
  ADD_EXTERNAL_TICKET_SUCCESS: 'Your Request Has Been Submitted Successfully.',
  SEND_SURVEY_SUCCESS: 'Your Survey Was Sent Successfully',
  COPY_SURVEY_SUCCESS: 'Survey Copy Successfully',
  ADD_SURVEY_OPTION: 'Survey Option Added Successfully',
  READ_ALL_NOTIFICATION: 'All Notifications Are Marked As Read',
  ADD_LOOP_VENDOR_SUCCESS: 'Loop Vendor Added Successfully',
  ADD_LOOP_VENDOR_POC_SUCCESS: 'Loop Vendor POC Added Successfully',
  UPDATE_LOOP_VENDOR_SUCCESS: 'Loop Vendor Updated Successfully',
  UPDATE_LOOP_VENDOR_POC_SUCCESS: 'Loop Vendor POC Updated Successfully',
  ADD_LOOP_PROPERTY: 'Loop Property Added Successfully',
  ADD_LOOP_PROPERTY_POC: 'Loop Property POC Added Successfully',
  UPDATE_LOOP_PROPERTY: 'Loop Property Updated Successfully',
  UPDATE_LOOP_PROPERTY_POC: 'The Loop Property POC Updated Successfully for the Loop Property',
  DELETE_BUTTON: 'Delete',
  YES_IAM_SURE_BUTTON: "Yes. I'm Sure",
  CURRENTLY_WORKING_ON_IT: 'Currently We Are Working On It',
  INACTIVE_PROPERTY: 'Is In-active',
  OPEN_SURVEY_SAVE_DRAFT: 'Survey Template Saved in Draft State',
  OPEN_SURVEY_SAVE_UPDATED: 'Survey Template Updated Successfully',
  OPEN_SURVEY_SAVE_SUCCESS: 'Survey Template Created Successfully',
  OPEN_SURVEY_STATUS_INACTIVE: {
    TITLE: 'Inactive Open Survey',
    DESCRIPTION: 'Are You Sure You want To Inactive This Open Survey?'
  },
  OPEN_SURVEY_STATUS_REMOVE: {
    TITLE: 'Delete Open Survey',
    DESCRIPTION: 'Are You Sure You want To Delete This Open Survey?'
  },
  OPEN_SURVEY_STATUS_ACTIVATE: {
    TITLE: 'Active Open Survey',
    DESCRIPTION: 'Are You Sure You want To Activate This Open Survey?'
  },
  NOTIFICATION_REMOVE: {
    ALERT_TITLE: 'Delete Notification',
    ALERT_DESC: 'Are You Sure You Want To Delete Notification?',
    ALERT_WAITING: 'Notification Has Been Deleted'
  },
  DELETE_PROPERTY_FOR_LOOPVENDOR: {
    ALERT_TITLE: 'Deactivate Loop Vendor',
    ALERT_DESC: 'Are You Sure You Want To Deactivate Loop Vendor For This Property?',
    ALERT_WAITING: 'Loop Vendor Has Been Deactivated',
    BUTTON: 'Deactivate'
  },
  REACTIVATE_PROPERTY_FOR_LOOPVENDOR: {
    ALERT_TITLE: 'Activate Loop Vendor',
    ALERT_DESC: 'Are You Sure You Want To Activate Loop Vendor For This Property?',
    ALERT_WAITING: 'Loop Vendor Has Been Activate',
    BUTTON: 'Activate'
  },
  OPEN_SURVEY_STATUS_INACTIVE_SUCCESS: 'Survey Template Has Been Inactived Successfully',
  OPEN_SURVEY_STATUS_RE_INACTIVE_SUCCESS: 'Survey Template Has Been Re-Activated Successfully',
  OPEN_SURVEY_STATUS_DELETE_SUCCESS: 'Survey Template Has Been Deleted Successfully',
  ALERT_FOR_DUPLICATE_SURVEY:
    'Survey template has been duplicated successfully. Keep in mind you will need to update the property, to whom the survey goes to and any ticket assignment fields prior to the QR code generation.',
  SURVEY_SUBMIT_AS_ANONYMOUS: 'Survey submited as anonymous.',
  SURVEY_SAVE_AS_DRAFT: 'Survey Template Saved in Draft State',
  MANUALY_CREATED_TICKET: 'Manually Created Ticket From Survey Response',
  ANONYMOUS_SUBMITED_SURVEY: 'Survey Response Submitted Anonymously',
  CONTACTED_PERSON: 'Performer Has Been Already Contacted',
  SURVEY_EXPIRED: 'This Survey Has Been Inactivated. Please Scan Another Qr Code to Perform Different Survey.',
  ADMIN_EXISTS:
    'Specified Admin Email Is Associated either to a Property as Specific Role or to a Company as an Admin. Use Different Email for Admin.',
  USER_EXISTS_ANOTHER_PROPERTY: 'will deactivate the current assigned role',
  ACTIVE_USER_WITH_CONFIRMATION: 'Are you sure you want to change the user role? ',
  COMMENT_REQUIRED_TO_DISAGREE: 'Please add comment to disagree.',
  TERMS_AND_CONDITION_LOGIN: 'You Must Accept the Terms and Conditions for Use of the System.',
  TERMS_AND_CONDITION_CHAT: 'You Must Accept the Terms and Conditions for Use of Chat.'
};
