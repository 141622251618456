import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { messages } from '@shared/constants/app.constants';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CleaningCompanyGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] === messages.ROLE_ADMIN) {
        resolve(true);
      }
      resolve(false);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PMCGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] === messages.ROLE_COMPANY_ADMIN || roles[0] === messages.ROLE_CLEANING_POC || roles[0] === messages.ROLE_MANAGER) {
        resolve(true);
      }
      resolve(false);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PropertyGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] !== messages.ROLE_ADMIN && roles[0] !== messages.USER_POSITION) {
        resolve(true);
      }
      resolve(false);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class TicketGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] !== messages.ROLE_ADMIN) {
        resolve(true);
      }
      resolve(false);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class EventGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] !== messages.ROLE_ADMIN) {
        resolve(true);
      }
      resolve(false);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class InspectionGuard implements CanActivateChild {
  constructor() {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles: string[] = JSON.parse(sessionStorage.getItem('roles'));
    return new Promise(resolve => {
      if (roles[0] !== messages.ROLE_ADMIN && roles[0] !== messages.ROLE_PMC_POC) {
        resolve(true);
      }
      resolve(false);
    });
  }
}
