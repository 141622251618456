export const messages = {
  TENANT: 'TENANT',
  TENANT_ROLE: 'Tenant',
  CREATE_USER: 'Add user',
  KT_ASIDE: 'kt-aside--minimize',
  LIGHT_LOGO_URL: './assets/media/logos/logo-light.png',
  CITY_ICON: 'flaticon2-architecture-and-city',
  DASHBOARD: '/dashboard',
  MENU_DASHBOARD: 'MENU.DASHBOARD',
  USER_MANAGEMENT: 'User Management',
  USER_OUTLINE_ICON: 'flaticon2-user-outline-symbol',
  FA_CITY_ICON: 'fa fa-city',
  FA_QRCODE_ICON: 'fa fa-qrcode',
  INSPECTION_TITLE: 'Inspection Questions',
  INSPECTION_QUESTION_ICON: 'flaticon-questions-circular-button',
  INSPECTION: '/inspection-question',
  INSPECTION_QUESTION_CONFIG: 'Inspection Question Configuration',
  REPLY_ICON: 'fa fa-reply',
  INSPECTION_QUESTION_CONFIG_PATH: '/inspection-question-config',
  FA_SEARCH_ICON: 'flaticon-search-1',
  FA_FEEDBACK_ICON: 'fas fa-comments',
  INSPECTION_PATH: '/inspections',
  TICKET_ICON: 'fas fa-ticket-alt',
  TICKET_PATH: '/tickets/ticket/list',
  ARCHIVE_TICKET_PATH: '/tickets/ticket/archiveList',
  CALENDAR_ICON: 'flaticon-calendar-3',
  EVENT_PATH: '/events/event',
  SURVEY_PATH: '/surveys',
  OPEN_SURVEY_PATH: '/open-survey/list',
  OPEN_SURVEY_COMPARE_PATH: '/open-survey/compareTemplate',
  KT_MENU_ITEM: 'kt-menu__item--hover',
  CONTENT_TYPE: 'Content-Type',
  APPLICATION_JSON: 'application/json',
  DEFAULT_IMAGE_PATH: '../../../../../../assets/media/users/default.jpg',
  CURSOR_POINTER: '../../../../../../assets/media/users/cursor-pointer.svg',
  SINGLE_USER_IMAGE_PATH: '../../../../../../assets/media/users/Single_User.png',
  GROUP_USER_IMAGE_PATH: '../../../../../../assets/media/users/group_user.png',
  HEARTS_EYES: '../../../../../../assets/media/users/heart-eyes.png',
  SMILEY_FACE: '../../../../../../assets/media/users/smiley-face.svg',
  PENSIVE_FACE: '../../../../../../assets/media/users/pensive.svg',
  NEUTRAL_FACE: '../../../../../../assets/media/users/neutral-face.svg',
  LIKE_IMAGE: '../../../../../../assets/media/users/like.png',
  LIKE_EMOJI: '../../../../../../assets/media/users/like-emoji.png',
  DISLIKE_IMAGE: '../../../../../../assets/media/users/dislike.png',
  DISLIKE_EMOJI: '../../../../../../assets/media/users/dislike-emoji.png',
  QUESTION_EMOJI: '../../../../../../assets/media/users/question-emoji.png',
  EXCLAMATORY_EMOJI: '../../../../../../assets/media/users/exclamatory-emoji.png',
  EXCLAMATORY_EMOJI_SELECTED: '../../../../../../assets/media/users/explanation_selected.png',
  QUESTION_EMOJI_SELECTED: '../../../../../../assets/media/users/question_mark_selected.png',
  DISLIKE_EMOJI_SELECTED: '../../../../../../assets/media/users/dislike_selected.png',
  LIKE_EMOJI_SELECTED: '../../../../../../assets/media/users/like_selected.png',
  LIKE_EMOJI_BLACK: '../../../../../../assets/media/users/like-emoji-black.png',
  DISLIKE_EMOJI_BLACK: '../../../../../../assets/media/users/dislike-emoji-black.png',
  QUESTION_EMOJI_BLACK: '../../../../../../assets/media/users/question-emoji-black.png',
  EXCLAMATORY_EMOJI_BLACK: '../../../../../../assets/media/users/exclamatory-emoji-black.png',
  PHONE_NUMBER_MASK: '000-000-0000',
  PASSWORD_PATTERN: '/^(?=S*[a-z])(?=S*[A-Z])(?=S*d)S{4,15}$/',
  DEFAULT_PAGE_SIZE: 25,
  DEFAULT_PAGE_NUMBER: 0,
  PAGE_SIZE_OPTIONS: [25, 50, 75, 100],
  PAPER_CLIP_IMAGE: '../../../assets/media/icons/paperclip.png',
  DEFAULT_IMAGE: './assets/media/users/default.jpg',
  ADDRESS_IMAGE: './assets/media/icons/address_small.png',
  BUILDING_IMAGE: './assets/media/icons/building.png',
  PROPERTY_IMAGE: './assets/media/logos/buildings.png',
  COMPANY_IMAGE: './assets/media/logos/buildings.png',
  AUDIO_IMAGE_URL: '../../../assets/media/icons/audio.jpeg',
  RECORD_AUDIO_IMAGE: '../../../assets/media/icons/record.jpg',
  CALENDAR_MODES: ['month', 'agendaWeek', 'agendaDay', 'list'],
  CALENDAR_NAV_MODES: ['prev', 'next', 'today'],
  SORTBYFIELD: 'ID',
  SORTBYDIRESCTION: 'ASC',
  SORTBYDESCENDING: 'DESC',
  SORTBYFIELDUSER: 'FIRSTNAME',
  LAST_MODIFIED_DATE: 'LAST_MODIFIED_DATE',
  LASTMODIFIEDDATE: 'lastModifiedDate',
  SORTBYFIELDSURVEYLIST: 'id',
  SORTBYFIELDID: 'id',
  POPUP: '435px',
  POPUP_USER_LIST: '335px',
  DEFAULT_QRCODE_IMAGE: '../../../assets/media/logos/qr-code.png',
  DELETE_QRCODE_DIALOG: '415px',
  OPEN_SURVEY_STATUS_DIALOG: '415px',
  GENERATEQRCODE: '/qr-codes/generate/',
  ADD_QRCODE_TITLE: 'Add QR Code',
  EDIT_QR_CODE_TITLE: 'Edit QR Code',
  PLAY_AUDIO_IMAGE: '../../../assets/media/icons/play.png',
  PAUSE_AUDIO_IMAGE: '../../../assets/media/icons/pause.png',
  LOGO: '../../../assets/media/logos/bh_loaderImage_dark.png',
  LOGO_NEW: '../../../assets/media/logos/bh_light.png',
  LOGO_RXA: '../../../assets/media/logos/bha_dark.png',
  LOGO_RXM: '../../../assets/media/logos/bhm_dark.png',
  GRID_IMAGE: '../../../assets/media/icons/grid.svg',
  LOGO_BG_BLACK: './assets/media/logos/bh_loaderImage_dark.png',
  ROLE_COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
  ROLE_CLEANING_POC: 'ROLE_CLEANING_POC',
  ROLE_PMC_POC: 'ROLE_PMC_POC',
  ROLE_TENANT: 'ROLE_TENANT',
  ROLE_STAND_POC: 'ROLE_STAND_POC',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_MANAGER: 'ROLE_MANAGER',
  USER_POSITION: 'USER_POSITION',
  OBSERVER_POSITION: 'OBSERVER_POSITION',
  OBSERVER: 'OBSERVER',
  ROLE_LOOP_VENDOR: 'ROLE_LOOP_VENDOR',
  LOOP_VENDOR_POC_POSITION: 'LOOP_VENDOR_POC_POSITION',
  PROPERTY_POC_POSITION: 'PROPERTY_POC_POSITION',
  PROPERTY_POC: 'PROPERTY_POC',
  ROLE_POC_L: 'ROLE_POC_L',
  POC_L: 'POC_L',
  POC: 'POC',
  ROLE_USER: 'ROLE_USER',
  DEFAULT_PROPERTY_SORT_FIELD: 'PNAME',
  ADD_SURVEY_TITLE: 'Add Survey',
  EDIT_SURVEY_TITLE: 'Edit Survey',
  EVENT_VIEW_COLOUR: '#5867dd',
  DATE_FORMAT: 'YYYY-MM-DD',
  EVENT_DATE_FORMATE: 'yyyy-MM-dd HH:mm',
  FEEDBACK_PATH: '/feedback',
  ROUTE_LOGIN: 'auth/login',
  INVALID_CREDENTIAL_MESSAGE: 'Bad Credentials',
  ROLE_CHANGE: 'You have been logged out due to role change',
  TIME_FORMAT: 'HH:mm',
  PMCPOC: 'PMCPOC',
  MANAGER: 'MANAGER',
  Manager: 'Manager',
  MANAGER_POSITION: 'MANAGER_POSITION',
  ADMIN: 'ADMIN',
  COMPANYADMIN: 'COMPANYADMIN',
  USER: 'USER',
  CLEANINGPOC: 'CLEANINGPOC',
  STANDPOC: 'STANDALONEPOC',
  STAND_POC: 'STANDALONE POC',
  COMPANY_ADMIN_VIEW: 'COMPANY ADMIN',
  CLEANING_POC_VIEW: 'CLEANING POC',
  PMC_POC_VIEW: 'PMC POC',
  REPORTS_PATH: '/reports',
  FA_REPORT_ICON: 'fa fa-line-chart',
  PROPERTY_ADDRESS: './assets/media/icons/address_small.png',
  LOGO_IMAGE: '../../../assets/media/logos/bh_loaderImage_dark.png',
  LOCATION_VERIFIED_IMAGE: './assets/media/icons/locationVerification.png',
  CHART_MONTH: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  CHART_TYPE: 'line',
  LABEL_X_AXIS: 'Months',
  LABEL_Y_AXIS: 'Percentage',
  COUNTRY_NAME: 'US',
  ARCHIVED: 'ARCHIVED',
  INSPECTION_LIST: '/inspections/list',
  INSPECTION_QUESTION_LIST: '/inspection-question/inspection/list',
  QR_CODE_LIST: '/qr-code/QRlist',
  ASSIGNEE: 'ASSIGNEE',
  VALIDATIONS: {
    KT_PAGE_LOADED: 500,
    MIN_PASSWORD_LENGTH: 7,
    MAX_PASSWORD_LENGTH: 15,
    MAX_FORGOT_PWD_EMAIL: 320,
    MAX_SUIT_NO: 60,
    MAX_FIRST_NAME: 20,
    MAX_LAST_NAME: 20,
    MAX_EMAIL: 60,
    MAX_PROPERTY_LENGTH: 45,
    MAX_ADDRESS_1: 200,
    MAX_CITY: 20,
    ZIPCODE_LENGTH: 5,
    MAX_LOCATION: 250,
    MAX_CLEANING_COMPANY: 25,
    MAX_NOTE: 5000,
    MAX_TITLE_LENGTH: 500,
    OPTION_LENGTH: 15,
    PROPERTY_TYPE_LENGTH: 50,
    QUESTION_LENGTH: 250,
    SURVEY_TITLE: 30,
    TICKET_NOTE_PAGESIZE: 4,
    MAX_HUNDRED: 100,
    MAX_TWO_HUNDRED_FIFTY_FIVE: 255,
    MAX_SEVENTY_FIVE: 75,
    MAX_ATTACHMENT: 5,
  },
  ENTER_KEY: 13,
  DASHBOARD_LOAD: {
    TICKET_COUNT: 1,
    PROPERTY_COUNT: 2,
    INSPECTION_COUNT: 3,
    EVENT_COUNT: 4,
    UNASSIGNED_TICKET_COUNT: 5,
  },
  INSPECTION_PAGE_SIZE: 7,
  ASIDE_LEFT: 100,
  PRINT_TIMEOUT: 5000,
  SEARCH_TOPBAR: 500,
  oDuration: 10000,
  oUndoButtonDuration: 3000,
  ANIMATE_TIMEOUT: 800,
  PLAY_SET_TIMEOUT: 300,
  LOGIN_PAGE_REDIRECT: 700,
  ROUTE_CONFIG_LOAD_START: 35,
  ROUTE_CONFIG_LOAD_END: 75,
  INTERNAL_SERVER_ERROR: 500,
  MODEL_TIMEOUT: 1000,
  OPEN_SURVEY_MODEL_TIMEOUT: 500,
  SERVER_DOWN: 'System is down for now. Try again later.',
  LOCATION_URL: 'https://maps.google.com/?q=',
  LOOP_VENDOR: 'LOOP_VENDOR',
  LV_POC: 'LV_POC',
  COMPANYNAME: 'COMPANYNAME',
  USER_DETAIL: 'USER_DETAIL',
  PROPERTY_DETAIL: 'PROPERTY_DETAIL',
  PROPERTY_POC_EMAIL: 'propertyPOCEmail',
  MANAGER_VMS: 'managerVMS',
  OBSERVERS: 'observers',
  ASSIGNEES: 'assignees',
  LIVE_URL: 'live.resolvedx.io',
  RESOLVED_WEBSITE: 'https://resolvedx.io',
  LOOP_VENDOR_ONLY: 'LOOP VENDOR',
  TICKET_DETAILS_TABLES: {
    OBSERVERS: 'Observers',
    ASSIGNEES: 'Assignees',
    POCS: 'POCs',
    MANAGERS: 'Managers',
    LOOP_VENDORS: 'Loop Vendors',
  },
  CHAT_CONFIG_EVENT: {
    RX_CHAT_ROOM: 'RX_CHAT_ROOM',
    RX_CREATE_ROOM: 'RX_CREATE_ROOM',
    RX_CONNECT: 'CONNECT',
    RX_ROOM_HISTORY: 'ROOM_HISTORY',
    ROOM_MESSAGE: 'ROOM_MESSAGE',
    UPDATE_ROOM: 'UPDATE_ROOM',
    RX_JOIN_ROOM: 'RX_JOIN_ROOM',
    DELETE_ROOM: 'DELETE_ROOM',
    USER_DELETE_ROOM: 'USER_DELETE_ROOM',
    MAKE_ADMIN: 'MAKE_ADMIN',
    REMOVE_USER: 'REMOVE_USER',
    LEAVE_ROOM: 'LEAVE_ROOM',
    ARCHIVE_ROOM: 'ARCHIVE_ROOM',
    UNARCHIVE_ROOM: 'UNARCHIVE_ROOM',
    REACT_MESSAGE: 'REACT_MESSAGE',
    MESSAGE_DELETE: 'MESSAGE_DELETE',
    MESSAGE_READ: 'MESSAGE_READ',
    RX_DEACTIVATE_USER: 'RX_DEACTIVATE_USER',
  },
  CHAT_CONFIG_EVENT_LISTENER: {
    ON_CREATE_ROOM: 'ON_CREATE_ROOM',
    ON_CHAT_ROOM: 'ON_CHAT_ROOM',
    ON_ROOM_HISTORY: 'ON_ROOM_HISTORY',
    ON_ROOM_MESSAGE: 'ON_ROOM_MESSAGE',
    CONNECT_HANDSHAKE_COMPLETE: 'CONNECT_HANDSHAKE_COMPLETE',
    DISCONNECT_HANDSHAKE_COMPLETE: 'DISCONNECT_HANDSHAKE_COMPLETE',
    USER_DISCONNECTED: 'USER_DISCONNECTED',
    ON_UPDATE_ROOM: 'ON_UPDATE_ROOM',
    ON_JOIN_ROOM: 'ON_JOIN_ROOM',
    ON_DELETE_ROOM: 'ON_DELETE_ROOM',
    ON_USER_DELETE_ROOM: 'ON_USER_DELETE_ROOM',
    ON_MAKE_ADMIN: 'ON_MAKE_ADMIN',
    ON_REMOVE_USER: 'ON_REMOVE_USER',
    ON_LEAVE_ROOM: 'ON_LEAVE_ROOM',
    ON_ARCHIVE_ROOM: 'ON_ARCHIVE_ROOM',
    ON_UNARCHIVE_ROOM: 'ON_UNARCHIVE_ROOM',
    ON_REACT_MESSAGE: 'ON_REACT_MESSAGE',
    ON_MESSAGE_DELETE: 'ON_MESSAGE_DELETE',
    ON_MESSAGE_READ: 'ON_MESSAGE_READ',
    ON_DEACTIVATE_USER: 'ON_DEACTIVATE_USER',
  },
};
