// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
// Angular Material
import {
  MatButtonModule,
  MatProgressBarModule,
  MatTabsModule,
  MatInputModule
} from '@angular/material';
import { NgxPermissionsModule } from 'ngx-permissions';
// NgBootstrap
import {
  NgbProgressbarModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
// Translation
// import { TranslateModule } from '@ngx-translate/core';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Core Module
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from '@layout/header/header.component';
import { AsideLeftComponent } from '@layout/aside/aside-left.component';
import { FooterComponent } from '@layout/footer/footer.component';
import { SubheaderComponent } from '@layout/subheader/subheader.component';
import { BrandComponent } from '@layout/brand/brand.component';
import { TopbarComponent } from '@layout/header/topbar/topbar.component';
import { MenuHorizontalComponent } from '@layout/header/menu-horizontal/menu-horizontal.component';
import { PartialsModule } from '@layout/partials/partials.module';
import { BaseComponent } from '@layout/base/base.component';
import { HtmlClassService } from './services/html-class.service';
import { HeaderMobileComponent } from '@layout/header/header-mobile/header-mobile.component';
import { DataService } from './services/data.service';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,
    SubheaderComponent,
    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    // topbar components
    TopbarComponent,
    // aside left menu components
    AsideLeftComponent,
    // horizontal menu components
    MenuHorizontalComponent
  ],
  exports: [
    BaseComponent,
    FooterComponent,
    SubheaderComponent,
    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,
    // topbar components
    TopbarComponent,
    // aside left menu components
    AsideLeftComponent,
    // horizontal menu components
    MenuHorizontalComponent
  ],
  providers: [HtmlClassService,DataService],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    PartialsModule,
    SharedModule,
    PerfectScrollbarModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    // TranslateModule.forChild(),
    LoadingBarModule,
    InlineSVGModule,
    // ng-bootstrap modules
    NgbProgressbarModule,
    NgbTooltipModule,
    MatInputModule
  ]
})
export class LayoutModule {}
