import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DeleteEntityDialogComponent } from '@layout/partials/content/crud';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { OffcanvasOptions } from '@shared/directives';
import { Alert } from '@shared/models/base.model';
import { Property } from '@shared/models/create-building.model';
import { Notification, NotificationContent, NotificationDelete } from '@shared/models/notification.model';
import { AuthService } from '@shared/services';
import { NotificationPageableQuery } from '@shared/services/utils';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { BuildingService } from 'src/app/building/building.service';
import { QuickPanelService } from './quick-panel.service';
@Component({
  selector: 'sfl-quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss']
})
export class QuickPanelComponent implements OnInit, OnDestroy {
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'sfl-quick-panel',
    closeBy: 'kt_quick_panel_close_btn',
    toggleBy: 'kt_quick_panel_toggler_btn'
  };
  @ViewChild('closeSideBar', { static: false }) myDiv: ElementRef<HTMLElement>;
  pageableQuery: NotificationPageableQuery = new NotificationPageableQuery();
  totalElements: number;
  subscription: Subscription = new Subscription();
  dataSource: Notification[] = [];
  isData = false;
  roles: string[] = [];
  properties = [];
  propertyId: string;
  propertyDetails: Property = new Property();
  isDeleteChecked = false;
  notificationDelete: NotificationDelete = new NotificationDelete();
  constructor(
    private readonly notificationService: QuickPanelService,
    private readonly layoutUtilsService: LayoutUtilsService,
    public router: Router,
    private authes: AuthService,
    private readonly buildingService: BuildingService,
    private readonly matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.subscription.add(
      this.notificationService.getNotificationHistorySubject().subscribe(() => {
        this.pageableQuery.page = 0;
        this.dataSource = [];
        this.isDeleteChecked = false;
        this.notificationDelete = new NotificationDelete();
        this.getNotificationListing();
        this.getPropertyByUser();
      })
    );
  }

  // Get notification listing with pagination
  getNotificationListing() {
    this.isData = true;
    this.propertyId = this.propertyId ? this.propertyId : '';
    this.subscription.add(
      this.notificationService.getNotificationListing(this.pageableQuery, this.propertyId).subscribe(
        (response: NotificationContent) => {
          this.isData = false;
          this.dataSource.push(...response.content);
          this.dataSource.forEach(date => {
            date.displayDate = moment(date.createdDate).fromNow();
          });
          this.dataSource = this.dataSource;
          this.totalElements = response.totalElements;
          this.pageableQuery.page = response.pageable.pageNumber + 1;
        },
        (listingError: HttpErrorResponse) => {
          this.isData = false;
          this.layoutUtilsService.showActionNotification(listingError.error.message, MessageType.Create);
        }
      )
    );
  }
  // To get basic details of property
  getPropertyBasicDetail(propertyId) {
    this.subscription.add(
      this.buildingService.getBuildingById(propertyId).subscribe((res: Property) => {
        if (res) {
          sessionStorage.setItem('propertyDetails', JSON.stringify(res));
        }
      })
    );
  }
  // Mark as read to single notification on click
  async readNotification(notificationId: string, redirectionId: string, type: string, propertyId: string) {
    if (type === 'PROPERTY' && (this.roles[0] === messages.ROLE_COMPANY_ADMIN || this.roles[0] === messages.ROLE_LOOP_VENDOR)) {
      if (sessionStorage.getItem('propertyDetails') === null) {
        const response = await this.buildingService.getBuildingById(propertyId).toPromise();
        if (response) {
          sessionStorage.setItem('propertyDetails', JSON.stringify(response));
        }
      }
    }
    if (propertyId) {
      this.notificationService.setNotificationPropertySubject(propertyId);
    }
    this.subscription.add(
      this.notificationService.readSingleNotification(notificationId).subscribe(
        () => {
          this.dataSource.forEach(data => {
            if (data.notificationId === notificationId) {
              data.read = true;
            }
          });
          this.notificationService.setUnreadNotificationSubject(0);

          if (redirectionId) {
            this.offcanvasOptions.overlay = false;
            if (type === 'TICKET') {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['tickets/ticket/detail/' + redirectionId]); // navigate to same route
              });
            } else if (type === 'EVENT') {
              this.router.navigate(['events/event/list/']);
            } else if (type === 'PROPERTY') {
              this.router.navigate(['building/detail/' + redirectionId]);
            } else if (type === 'INSPECTIONS') {
              this.router.navigate(['inspections/report/' + redirectionId]);
            }
          }

          this.triggerFalseClick();
        },
        (error: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
        }
      )
    );
  }

  // tigger click event
  triggerFalseClick() {
    const el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }
  // Mark as read all on click
  markAllRead() {
    this.subscription.add(
      this.notificationService.markAllAsRead(this.propertyId).subscribe(
        () => {
          this.pageableQuery.page = 0;
          this.dataSource = [];
          this.getNotificationListing();
          this.notificationService.setUnreadNotificationSubject(0);
          this.layoutUtilsService.showActionNotification(ACTION_MESSAGES.READ_ALL_NOTIFICATION, MessageType.Create);
        },
        (allError: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(allError.error.message, MessageType.Create);
        }
      )
    );
  }

  // On scroll call the listing on basis of page
  onScroll() {
    this.getNotificationListing();
  }
  getPropertyByUser() {
    this.subscription.add(
      this.notificationService.getPropertyByUser().subscribe((response: any) => {
        this.properties = response.properties;
      })
    );
  }
  onChangeProperty() {
    this.pageableQuery.page = 0;
    this.dataSource = [];
    this.notificationDelete = new NotificationDelete();
    this.getNotificationListing();
  }
  //Method for select checkbox
  onCheckDeleteCheckbox(event, item) {
    if (event.checked) {
      this.notificationDelete.notificationID.push(item.notificationId);
    } else {
      this.notificationDelete.notificationID.splice(this.notificationDelete.notificationID.indexOf(item.notificationId), 1);
    }
  }
  // Method for delete notification
  onClickDelete(item) {
    const alertData = new Alert();
    let dialogRef = null;
    alertData.title = ACTION_MESSAGES.NOTIFICATION_REMOVE.ALERT_TITLE;
    alertData.description = ACTION_MESSAGES.NOTIFICATION_REMOVE.ALERT_DESC;
    alertData.buttonText = ACTION_MESSAGES.YES_IAM_SURE_BUTTON;
    dialogRef = this.matDialog.open(DeleteEntityDialogComponent, {
      data: alertData,
      disableClose: true,
      width: messages.POPUP
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        if (item) {
          this.notificationDelete.notificationID.push(item.notificationId);
        }
        if (this.notificationDelete.notificationID.length > 0) {
          this.notificationDelete.propertyId = "";
        } else {
          this.notificationDelete.propertyId = this.propertyId;
        }
        this.notificationDelete.status = false;
        this.subscription.add(
          this.notificationService.deleteNotification(this.notificationDelete).subscribe(
            (response: any) => {
              this.layoutUtilsService.showActionNotification(response.message, MessageType.Create);
              this.pageableQuery.page = 0;
              this.dataSource = [];
              this.notificationDelete = new NotificationDelete();
              this.isDeleteChecked = false;
              this.notificationService.setUnreadNotificationSubject(0);
              this.getNotificationListing();
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
            }
          )
        );
      }
    });
  }
  onModelChange(event) {
    if (!event) {
      this.notificationDelete.notificationID = [];
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
