// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-update-status-dialog',
  templateUrl: './update-status-dialog.component.html'
})
export class UpdateStatusDialogComponent implements OnInit {
  selectedStatusForUpdate = new FormControl('');
  viewLoading = false;
  disableCancel = false;
  loadingAfterSubmit = false;
  buttonText = "Update";
  timeout = messages.PRINT_TIMEOUT;
  constructor(
    public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.buttonText) {
      this.buttonText = this.data.buttonText; 
    }

    if (this.data.timeout) {
      this.timeout = this.data.timeout
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // close the dialog box after save
  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    this.disableCancel = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, this.timeout);
  }
}
