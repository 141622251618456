import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {
  LayoutConfigService,
  SplashScreenService
} from '@layout/services';
import { AuthNoticeService } from '@shared/auth-notice/auth-notice.service';
import { messages } from '@shared/constants/app.constants';
import { environment } from '@environment/environment';
@Component({
  selector: 'sfl-auth',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit {
  today: number = Date.now();
  headerLogo: string;
  LOGO = messages.LOGO_BG_BLACK;
  PRIVACY_POLICY_URL= environment.serverUrl.replace('api', "#/privacy-policy");
  constructor(
    private readonly el: ElementRef,
    private readonly render: Renderer2,
    private readonly layoutConfigService: LayoutConfigService,
    public authNoticeService: AuthNoticeService,
    private readonly splashScreenService: SplashScreenService
  ) {}

  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();
  }

  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }
}
