import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { messages } from '@shared/constants/app.constants';
import { Event, EventCalendarList, EventListFilter, EventTime } from '@shared/models/event.model';
import { Property } from '@shared/models/user.model';
import moment from 'moment';
import { EventObject, FullCalendarOptions } from 'ngx-fullcalendar';
import { Subscription } from 'rxjs';
import { EventService } from '../../events/events.service';

@Component({
  selector: 'sfl-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class EventCalendarComponent implements OnInit, OnDestroy, AfterViewChecked {
  options: FullCalendarOptions;
  events: EventObject[] = [];
  modes = messages.CALENDAR_MODES;
  navButtons = messages.CALENDAR_NAV_MODES;
  eventToggle = false;
  eventDetailToggle = false;
  subscription = new Subscription();
  eventFilter: EventListFilter = new EventListFilter();
  @Input() ticketId: string;
  isData = false;
  propertyDetails: Property = new Property();
  eventsList: Event[] = [];
  @Input('currentState')
  timezone:string;
  set currentState(value: EventTime) {
    if (value.startTime && value.endTime) {
      this.getAllEvents(this.setDefaultFilter(value.startTime, value.endTime));
    } else if (value.month) {
      this.getAllEvents(this.setDefaultMonthFilter(value.month));
    }
  }

  constructor(private readonly eventService: EventService, private readonly cdRef: ChangeDetectorRef, public readonly datepipe: DatePipe) {}

  ngOnInit() {
    this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.options = {
      defaultDate: moment().format(messages.DATE_FORMAT),
      editable: false,
      header: {
        left: this.navButtons.join(','),
        center: 'title',
        right: this.modes.join(',')
      }
    };
    this.events = [];
    this.subscription.add(
      this.eventService.getEventListingSubject().subscribe((res: EventTime) => {
        this.getAllEvents(this.eventFilter);
      })
    );
  }

  // To filter data according to date filters
  dateFilter(event) {
    const rangeUnit = event.info.view.props.dateProfile.currentRangeUnit;
    const currentRangeDate = event.info.view.props.dateProfile.currentRange;
    const eventTime = new EventTime();
    this.eventFilter = new EventListFilter();
    // TODO: can cause an issue.
    if (rangeUnit.toLowerCase() === 'month') {
      this.eventFilter.month = moment(currentRangeDate.start).add(4, 'h').utc().format('YYYY-MM');
      eventTime.startTime = null;
      eventTime.endTime = null;
    } else if (rangeUnit.toLowerCase() === 'week' || rangeUnit.toLowerCase() === 'day') {
      this.eventFilter.startDate = moment(currentRangeDate.start).add(4, 'h').utc().format(messages.DATE_FORMAT);
      this.eventFilter.endDate = moment(currentRangeDate.end).format(messages.DATE_FORMAT);
      this.eventFilter.startDateTime = new Date(this.eventFilter.startDate).toISOString();
      this.eventFilter.endDateTime = new Date(this.eventFilter.endDate).toISOString();
      eventTime.startTime = this.eventFilter.startDate;
      eventTime.endTime = this.eventFilter.endDate;
      eventTime.month = null;
    }
    this.eventFilter.ticketId = this.ticketId ? this.ticketId : null;
    this.eventFilter.timeZone = this.timezone;
    eventTime.timeZone = this.timezone;
    this.eventService.setCalendarEventSubject(eventTime);
    this.getAllEvents(this.eventFilter);
  }

  // Get all events
  getAllEvents(eventFilter: EventListFilter) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.events = [];
    this.isData = true;
    eventFilter.timeZone = this.timezone
    // TODO Remove once confirm
    // if (this.propertyDetails) {
      // eventFilter.propertyId = this.propertyDetails.propertyId;
    // }
    this.subscription.add(
      this.eventService.getAllEvents(eventFilter).subscribe((res: Event[]) => {
        this.eventsList = res;
        this.isData = false;
        res.forEach(event => {
          this.events.push(this.setCalendarData(event));
        });
      })
    );
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  // Set data to form
  setCalendarData(event: Event): EventCalendarList {
    const calendarData = new EventCalendarList();
    calendarData.id = event.eventId;
    calendarData.title = event.title;
    const startDateValidate = event.startDateTime.charAt(event.startDateTime.length - 1);
    if (startDateValidate === 'Z') {
      calendarData.start = event.startDateTime.slice(0, event.startDateTime.length - 1);
      calendarData.end = event.endDateTime.slice(0, event.endDateTime.length - 1);
    } else {
      calendarData.start = event.startDateTime;
      calendarData.end = event.endDateTime;
    }
    calendarData.backgroundColor = messages.EVENT_VIEW_COLOUR;
    calendarData.allDay = event.allDayEvent;
    return calendarData;
  }

  // Set default filter for the first time
  setDefaultFilter(startTime: string, endTime: string): EventListFilter {
    const defaultFilter = new EventListFilter();
    defaultFilter.startDate = moment(startTime).format(messages.DATE_FORMAT);
    defaultFilter.endDate = moment(endTime).format(messages.DATE_FORMAT);
    defaultFilter.ticketId = this.ticketId ? this.ticketId : null;
    return defaultFilter;
  }

  // Set default filter for month for the first time
  setDefaultMonthFilter(month: string): EventListFilter {
    const defaultFilter = new EventListFilter();
    defaultFilter.month = month;
    defaultFilter.ticketId = this.ticketId ? this.ticketId : null;
    return defaultFilter;
  }

  // Open template on click of particular event by eventId
  handleDateClick(event) {
    const eventData = this.eventsList.filter(x => x.eventId === event.event.id);
    // TO DO Once confirm remove this comment.
    // if (eventData) {
    //   if (!eventData[0].editable) {
    //     return;
    //   }
    // }
    const date = new Date();
    const currentDate = this.datepipe.transform(date, messages.EVENT_DATE_FORMATE);
    const endData = this.datepipe.transform(eventData[0].endDate, messages.EVENT_DATE_FORMATE);
    const startDate = this.datepipe.transform(eventData[0].startDate, messages.EVENT_DATE_FORMATE);
      this.eventService.setEditEventSubject(event.event.id);
    // TO DO Remove once confirm.
    // if (currentDate < endData) {
      // } else if (event.event.allDay) {
    //   if (currentDate < startDate || currentDate === startDate) {
    //     this.eventService.setEditEventSubject(event.event.id);
    //   }
    // }
    // const endData = event.event.end;
    // const startDate = event.event.start;
    // if (currentDate < endData) {
    //   this.eventService.setEditEventSubject(event.event.id);
    // } else if (event.event.allDay) {
    //   if (currentDate < startDate || currentDate === startDate) {
    //     this.eventService.setEditEventSubject(event.event.id);
    //   }
    // }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
