import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { messages } from '@shared/constants/app.constants';
import { ConversationDTO, chatConfigDTO } from '@shared/models/chat.model';
import { UserModel } from '@shared/models/login.model';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
import { ChatConfigService } from '../chat_config.service';
@Component({
  selector: 'sfl-chat-list-history',
  templateUrl: './chat-list-history.component.html',
  styleUrls: ['./chat-list-history.component.scss']
})
export class ChatListHistoryComponent implements OnInit, OnDestroy {
  @Output() startPropertyList: EventEmitter<string> = new EventEmitter<string>();
  @Output() startConversationRoom: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSearchMessageOrGroup: EventEmitter<boolean> = new EventEmitter<boolean>();
  SINGLE_USER_IMAGE_PATH = messages.SINGLE_USER_IMAGE_PATH;
  GROUP_USER_IMAGE_PATH = messages.GROUP_USER_IMAGE_PATH;
  subscription = new Subscription();
  allConversationList: ConversationDTO[] = [];
  allConversationListBackup: ConversationDTO[] = [];
  PropertyConversationList = [];
  ticketConversationList = [];
  chatRoom: chatConfigDTO = new chatConfigDTO();
  isSpinner = false;
  allChatSearch;
  propertyChatSearch;
  ticketChatSearch;
  pageHeader = 'Chat';
  isArchiveChatList = false;
  isLoopVendor = false;
  user: UserModel = new UserModel();
  isLoading = true;
  conversationList = [];
  constructor(public readonly chatService: ChatService, private readonly chatConfigService: ChatConfigService) {}

  ngOnInit() {
    this.user.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.isLoopVendor = this.user.roles[0] === messages.ROLE_LOOP_VENDOR;
    this.subscription.add(
      this.chatService.latestChatGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.conversationList = msg;
          this.setConversation(msg);
        }
      })
    );
    this.subscription.add(
      this.chatService.chatClickedEvent.subscribe(msg => {
        this.allConversationList = [];
        this.chatConfigService.getChatRoomList();
      })
    );
    this.subscription.add(
      this.chatService.onCreateRoomSubscriber.subscribe(msg => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.latestChatMessageSubscriber.subscribe((msg: any) => {
        if (msg) {
          let roomIndex = this.allConversationList.findIndex(x => x.roomId === msg.messages[0].roomId);
          if (this.allConversationList[roomIndex].entireLastMessage) {
            this.allConversationList[roomIndex].entireLastMessage.message = msg.messages[0].message;
            this.allConversationList[roomIndex].lastMessageSentTime = msg.messages[0].timestamp;
          } else {
            this.allConversationList[roomIndex].entireLastMessage = msg.messages[0];
            this.allConversationList[roomIndex].lastMessageSentTime = msg.messages[0].timestamp;
          }
          this.allConversationList[roomIndex].unreadMessageCount = msg.unreadMessageCount;
          this.sortListByLatest();
        }
      })
    );
    this.chatService.onReactMessageAck.asObservable().subscribe(msg => {
      if (msg) {
        // this.markAllMessagesAsRead();
        // let roomIndex = this.allConversationList.findIndex(x => x.roomId === msg.messages[0].roomId);
        // this.allConversationList[roomIndex].unreadMessageCount = 0;
      }
    });
    this.subscription.add(
      this.chatService.onUpdateRoomSubscriber.subscribe(msg => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onRemoveUserSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onLeaveGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onLeaveGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onArchiveGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
        }
      })
    );
    this.subscription.add(
      this.chatService.onJoinGroup.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
          this.chatService.onJoinGroupByUser(null);
        }
      })
    );
    this.subscription.add(
      this.chatService.onUnArchiveGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.allConversationList = msg.chats.filter(x => x.isArchived === true);
          this.PropertyConversationList = this.allConversationList.filter(x => x.roomChatType === 'PROPERTY_BASE');
          this.ticketConversationList = this.allConversationList.filter(x => x.roomChatType === 'TICKET_BASE');
          this.sortListByLatest();
          this.isLoading = false;
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdmin.asObservable().subscribe(msg => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
          this.chatService.onDeleteGroupByAdmin(null);
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdminAck.asObservable().subscribe(msg => {
        if (msg) {
          this.allConversationList = [];
          this.chatConfigService.getChatRoomList();
          this.chatService.onDeleteGroupByAdminAck(null);
        }
      })
    );

    this.subscription.add(
      this.chatService.onDeactivateUserAck.asObservable().subscribe(msg => {
        if (msg) {
          for (const roomId of msg.roomIds) {
            const roomIndex = this.allConversationList.findIndex(x => x.roomId === roomId);
            if (this.allConversationList && this.allConversationList[roomIndex]) {
              if (roomIndex > -1 && this.allConversationList[roomIndex].entireLastMessage) {
                this.allConversationList[roomIndex].entireLastMessage.message = msg.messageDTO.message;
                this.allConversationList[roomIndex].lastMessageSentTime = msg.messageDTO.timestamp;
              } else {
                this.allConversationList[roomIndex].entireLastMessage = msg.messageDTO;
                this.allConversationList[roomIndex].lastMessageSentTime = msg.messageDTO.timestamp;
              }
            }
          }
        }
      })
    );

    this.subscription.add(
      this.chatService.onReadMessageAck.asObservable().subscribe(msg => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (
            selectedConversation > -1 &&
            this.conversationList[selectedConversation].unreadMessageCount > 0 &&
            msg.userToken === sessionStorage.getItem('userId')
          ) {
            this.conversationList[selectedConversation].unreadMessageCount = 0;
          }
        }
      })
    );
  }
  setConversation(msg) {
    if (msg.length > 1) {
      this.allConversationList = [];
    }
    this.allConversationList.push(...msg);

    this.allConversationList = this.allConversationList.sort(
      (a: any, b: any) => new Date(b.lastMessageSentTime).getTime() - new Date(a.lastMessageSentTime).getTime()
    );
    this.allConversationListBackup = this.allConversationList;
    this.allConversationList = this.allConversationList.filter(x => x.isArchived === false);
    this.PropertyConversationList = this.allConversationList.filter(x => x.roomChatType === 'PROPERTY_BASE');
    this.ticketConversationList = this.allConversationList.filter(x => x.roomChatType === 'TICKET_BASE');
    this.sortListByLatest();
    console.log('allConversationList', this.allConversationList);
    this.chatService.isConversationLoading = false;
    this.isLoading = false;
  }
  onClickPropertyList(item) {
    this.startPropertyList.emit(item);
  }
  onClickDeleteGroup(item) {
    this.isLoading = true;
    let userRoleType = item.currentUserType;
    this.chatRoom.roomId = item.roomId;
    this.chatRoom.userToken = sessionStorage.getItem('userId');
    this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.deleteGroup(this.chatRoom, userRoleType);
  }
  onClickLeaveGroup(item) {
    this.isLoading = true;
    this.chatRoom.members = [sessionStorage.getItem('userId')];
    this.chatRoom.roomId = item.roomId;
    this.chatRoom.userToken = sessionStorage.getItem('userId');
    this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.leaveUserFromGroup(this.chatRoom);
  }
  onClickArchiveGroup(item) {
    this.isLoading = true;
    this.chatRoom.members = [sessionStorage.getItem('userId')];
    this.chatRoom.roomId = item.roomId;
    this.chatRoom.userToken = sessionStorage.getItem('userId');
    this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.archiveGroupByUser(this.chatRoom);
  }
  onClickUnArchiveGroup(item) {
    this.isLoading = true;

    this.chatRoom.members = [sessionStorage.getItem('userId')];
    this.chatRoom.roomId = item.roomId;
    this.chatRoom.userToken = sessionStorage.getItem('userId');
    this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.unArchiveGroupByUser(this.chatRoom);
  }

  onSelectConversation(items) {
    this.chatRoom.userToken = sessionStorage.getItem('userId');
    this.chatRoom.roomId = items.roomId;
    this.chatRoom.size = messages.DEFAULT_PAGE_SIZE;
    this.chatRoom.page = messages.DEFAULT_PAGE_NUMBER;
    this.chatRoom.target = items.roomName;
    this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.getChatRoomMessagesHistory(this.chatRoom);
    this.startConversationRoom.emit(items);
  }

  onMuteUnMuteNotification(muteNotification: boolean, groupInfo: ConversationDTO): void {
    this.subscription.add(
      this.chatService
        .muteUnmuteNotificationsForChat(muteNotification, groupInfo.roomId, sessionStorage.getItem('userId'))
        .subscribe(() => {
          const selectedRoomIndex = this.allConversationList.findIndex(conversation => conversation.roomId === groupInfo.roomId);
          if (selectedRoomIndex > -1) {
            this.allConversationList[selectedRoomIndex].mute = muteNotification;
          }
        })
    );
  }

  onClickArchivedList() {
    this.isArchiveChatList = true;
    this.allConversationList = this.allConversationListBackup.filter(x => x.isArchived === true);
    this.PropertyConversationList = this.allConversationList.filter(x => x.roomChatType === 'PROPERTY_BASE');
    this.ticketConversationList = this.allConversationList.filter(x => x.roomChatType === 'TICKET_BASE');
  }
  onClickUnArchivedList() {
    this.isArchiveChatList = false;
    this.isLoading = true;
    this.chatConfigService.getChatRoomList();
  }
  checkArchiveList() {
    let checkList: any = [];
    if (this.allConversationListBackup.length > 0) {
      checkList = this.allConversationListBackup.filter(x => x.isArchived === true);
      if (checkList.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  sortListByLatest() {
    return this.allConversationList.sort(
      (a, b) =>
        new Date(b.lastMessageSentTime ? b.lastMessageSentTime : b.roomCreatedTime).getTime() -
        new Date(a.lastMessageSentTime ? a.lastMessageSentTime : a.roomCreatedTime).getTime()
    );
  }

  onSearch() {
    this.onSearchMessageOrGroup.emit(true);
  }
  ngOnDestroy() {
    console.log('ngOnDestroy');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
