import { Pageable } from '@shared/services/utils';
import { LoopVendorPOC } from './loopVendorPOC.model';
import { ManagerArray, User } from './user.model';

export class ExternalUser {
  emailId: string;
  firstName: string;
  lastName: string;
  notificationPreference: string;
  phoneNumber: string;
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export class Tickets {
  id: string;
  propertyId: string;
  status: string;
  priority: string;
  observers: Observers[] = [];
  tags: string[] = [];
  assignees: Assignees[] = [];
  summary: string;
  description: string;
  attachments: Attachment[] = [];
  fullAddress: string;
  address: Address = new Address();
  ccPOCFirstName: string;
  ccPOCLastName: string;
  createdDate: string;
  updatedDate: string;
  propertyName: string;
  pmcPOCFirstName: string;
  pmcPOCLastName: string;
  ticketNumber: number;
  notification: boolean;
  externalUserVM: ExternalUser = new ExternalUser();
  anonymous: boolean;
  location: string;
  createdBy: string;
  checkins: Checkins[] = [];
  createdByEmail: string;
  createdByPhone: string;
  completionNotificationPreference: string;
  pmcPOCEmailId: string;
  ccPOCEmailId: string;
  standPOCFirstName: string;
  standPOCLastName: string;
  standPOCEmailId: string;
  creationType: string;
  propertyPOCEmail: string[] = [];
  loopVendorId: string;
  vendorPOCEmail: LoopVendorPOC[] = [];
  qrCodeId: string;
  createdUsing: string;
  surveyContactedDescription: string;
  surveyContactedDate: string;
  surveyPerformedAnonymously: boolean;
  managerVMS: ManagerArray[] = [];
  latitude: number;
  longitude: number;
  managerUsers: CompanyPropertyUserId[] = [];
  observerUsers: CompanyPropertyUserId[] = [];
  propertyPOCUsers: CompanyPropertyUserId[] = [];
  vendorPOCUsers: CompanyPropertyUserId[] = [];
  loopVendors: CompanyPropertyId[] = [];
  assigneeUsers: CompanyPropertyUserId[] = [];
  companyPropertyId: number;
  parentTicketId: string;
  loopVendorVMList: LoopVendorVMList[] = [];
  clear(): void {
    this.status = '';
    this.priority = '';
    this.summary = '';
    this.description = '';
  }
}
export class CompanyPropertyUserId {
  companyPropertyUserId: number;
}
export class CompanyPropertyId {
  companyPropertyId: number;
}

export class PMCPOCByProperty {
  defaultPmcPoc: boolean;
  pmcPocFlag: boolean;
  userVM: UserVM = new UserVM();
}

export class Observers {
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailId: string;
  defaultObserver: boolean;
}

export class Assignees {
  firstName: string;
  lastName: string;
  imageUrl: string;
  emailId: string;
}

export class ObserverArray {
  users: Observers[] = [];
}

export class AssigneeArray {
  users: Assignees[] = [];
}

export class Property {
  propertyId: string;
  propertyName: string;
  pmcPoc: boolean;
  companyPropertyId: number;
  userPosition: string;
}

export class PropertyArray {
  properties: Property[] = [];
}

export class Attachment {
  documentUrl: string;
  documentType: string;
}

export class Tag {
  tagName: string;
}

export class TagList {
  tagNameVMs: Tag[] = [];
}

export class TicketList extends Pageable {
  content: Tickets[] = [];
}

export class TicketFilter {
  byAssignees: string[] = [];
  byDescrip: string;
  byId: number;
  byObservers: string[] = [];
  byPriority: string;
  byPropName: string;
  byStatus: string;
  byTags: string[] = [];
  byPropertyId: string;
  byManagers: string[] = [];
  byPOCs: string[] = [];
  byQRCodeId: string;
  byCompanyPropertyId: number;
  //new added
  byMobileParameter: string;
  byLocation: string;
  byMobTicketId: string;
}

export class ObserversAndAssignees {
  emailId: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}

export class Note {
  description: string;
  attachments: Attachment[] = [];
  ticketId: string;
  parentNoteId: number;
}

export class NoteDetail {
  description: string;
  createdDate: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByImageUrl: string;
  noteId: number;
  images: AttachmentDetail[] = [];
  parentNoteId: number;
  reactionCounts: any;
  currentReaction: string;
}

export class ThreadNote {
  child: NoteDetail = new NoteDetail();
  parent: NoteDetail = new NoteDetail();
}

export class NoteList extends Pageable {
  content: ThreadNote[] = [];
}

export class AttachmentDetail {
  documentUrl: string;
  documentType: string;
}

export class AttachmentList extends Pageable {
  content: AttachmentDetail[] = [];
}

export class Checkins {
  firstName: string;
  lastName: string;
  checkinAddress: string;
}

export class UserVM {
  firstName: string;
  lastName: string;
  emailId: string;
}

export class MessageVM {
  message: string;
  textMessageSuccess: boolean;
  qrCodeId: string;
}

export class CCPOCByProperty {
  defaultCcPoc: boolean;
  ccPocFlag: boolean;
  userVM: UserVM = new UserVM();
}

export class StandPOCByProperty {
  defaultStandPoc: boolean;
  standPocFlag: boolean;
  userVM: UserVM = new UserVM();
}

export class CheckInInfo {
  firstName: string;
  lastName: string;
  checkinAddress: string;
  latitude: number;
  longitude: number;
}
export class LoopVendorVMList {
  companyName: string;
  companyPropertyId: string;
  lvPOCUsers: User[] = [];
}

export class NoteReaction {
  id: number;
  noteId: number;
  reaction: string;
  status: boolean;
  userFullName: string;
  userId: number;
  userImageUrl: string;
}

export class UserReact {
  id: number;
  noteId: number;
  reaction: string;
  status: boolean;
  updatedDateTime: string;
  userFullName: string;
  userId: number;
  userImageUrl: string;
}
