import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BuildingService } from '../building.service';

@Component({
  selector: 'sfl-user-profile-qr-code',
  templateUrl: './user-profile-qr-code.component.html',
  styleUrls: ['./user-profile-qr-code.component.scss'],
})
export class UserProfileQrCodeComponent implements OnInit {
  @Input() template: any;
  @Output() toggle = new EventEmitter<boolean>();
  profileQrCodeUrl: string = '';
  isSize = 450;
  subscriptions = new Subscription();
  constructor(private readonly buildingService: BuildingService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.buildingService.getUserProfileQrSubject().subscribe((res: string) => {
        if (res) {
          this.profileQrCodeUrl = res;
        }
      }),
    );
  }

  printQRCode() {
    window.print();
  }

  closeSidebar() {
    this.toggle.emit(false);
  }
}
