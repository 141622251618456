export class AddloopPropertyPOC {
  id: string;
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}
export class LoopPropertyPOC {
  id: string;
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  loopPropertyId: string;
  users: AddloopPropertyPOC[] = [];
  forProperty: [];
  status: string;
  companyName: string;
  suitNumber: string;
}
