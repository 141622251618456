import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from '@layout/services';
import { ToggleOptions } from '@shared/directives';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-header-mobile',
  templateUrl: './header-mobile.component.html'
})
export class HeaderMobileComponent implements OnInit {
  // Public properties
  headerLogo: string;
  asideDisplay: boolean;
  LOGO = messages.LOGO;

  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-header__topbar--mobile-on',
    togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
  };

  constructor(private readonly layoutConfigService: LayoutConfigService) {}

  ngOnInit() {
    this.headerLogo = this.layoutConfigService.getStickyLogo();
    this.asideDisplay = this.layoutConfigService.getConfig(
      'aside.self.display'
    );
  }
}
