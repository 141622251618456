import { Injectable } from '@angular/core';
import { messages } from '@shared/constants/app.constants';
import { UserModel } from '@shared/models/login.model';
import * as objectPath from 'object-path';
import { BehaviorSubject } from 'rxjs';
import { MenuConfigService } from './menu-config.service';
@Injectable()
export class MenuAsideService {
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  user: UserModel = new UserModel();
  menuItem: string;
  menuItemsList: any = [];
  propertyDetails: any = [];
  menuItems: any = [];

  constructor(private readonly menuConfigService: MenuConfigService) {
    this.loadMenu(null);
  }

  // This method loads sidebar as per the role the user login.
  loadMenu(item) {
    // get menu list
    this.setUserInfo();
    if (item) {
      this.user.roles[0] = item;
    }
    switch (this.user.roles[0]) {
      case 'ROLE_COMPANY_ADMIN':
      case 'PROPERTY_POC_POSITION':
      case 'USER_POSITION':
      case 'OBSERVER_POSITION':
        this.menuItem = 'asideCleaning.items';
        break;
      case messages.ROLE_ADMIN:
        this.menuItem = 'aside.items';
        break;
      //<!-- TODO Remove once confirm -->
      // case 'ROLE_COMPANY_ADMIN':
      // case 'ROLE_CLEANING_POC':
      //   this.menuItem = 'asideCleaning.items';
      //   break;
      // case 'ROLE_PMC_POC':
      //   this.menuItem = 'asideProperty.items';
      //   break;
      // case 'ROLE_STAND_POC':
      //   this.menuItem = 'asideStandAlone.items';
      //   break;
      // case 'ROLE_TENANT':
      //   this.menuItem = 'asideTenant.items';
      //   break;
      // case 'ROLE_MANAGER':
      //   this.menuItem = 'asideManager.items';
      //   break;
      // case 'PROPERTY_POC_POSITION':
      //   this.menuItem = 'PROPERTY_POC_POSITION.items';
      //   break;
      // case 'USER_POSITION':
      //   this.menuItem = 'asideUser.items';
      //   break;
      case 'LOOP_VENDOR_POC_POSITION':
        this.menuItem = 'ROLE_LOOP_VENDOR_POC_MENU.items';
        break;
      case 'ROLE_LOOP_VENDOR':
        this.menuItem = 'ROLE_LOOP_VENDOR_MENU.items';
        break;
      default:
        this.menuItem = 'asideCleaning.items';
        break;
    }
    if (
      this.user.roles[0] === messages.ROLE_COMPANY_ADMIN ||
      this.user.roles[0] === messages.ROLE_USER ||
      this.user.roles[0] === 'OBSERVER_POSITION' ||
      this.user.roles[0] === 'PROPERTY_POC_POSITION'
    ) {
      if (this.propertyDetails) {
        if (this.propertyDetails.userPosition === 'LOOP_VENDOR_POC_POSITION') {
         this.menuItems = objectPath.get(this.menuConfigService.getMenus(), 'ROLE_LOOP_VENDOR_MENU.items');
          this.menuList$.next(this.setMenu(this.menuItems));
          return;
        }
      }
      const optionsList = JSON.parse(sessionStorage.getItem('menuList'));
      if (optionsList) {
        this.menuList$.next(this.setMenu(optionsList));
      } else {
        this.menuItems = objectPath.get(this.menuConfigService.getMenus(), this.menuItem);
        if (this.user.roles[0] !== messages.ROLE_COMPANY_ADMIN) {
          this.menuItems = this.menuItems.filter(item => item.title !== "Loop Vendors"
          );
        }
        sessionStorage.setItem('menuList', JSON.stringify(this.menuItems));
        this.menuList$.next(this.setMenu(this.menuItems));
      }
    } else {
      this.menuItems = objectPath.get(this.menuConfigService.getMenus(), this.menuItem);
      this.menuList$.next(this.setMenu(this.menuItems));
    }
  }

  setUserInfo() {
    this.user.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.user.imageUrl = sessionStorage.getItem('imageUrl');
    this.user.fullName = sessionStorage.getItem('fullName');
    this.user.emailId = sessionStorage.getItem('emailId');
    this.user.access = JSON.parse(sessionStorage.getItem('userAccess'));
    this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
  }
  setMenu(menuItems) {
    this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
    if (this.user.roles[0] === 'ROLE_COMPANY_ADMIN') {
      return menuItems;
    } else if (this.user.roles[0] !== 'ROLE_COMPANY_ADMIN') {
      if (this.propertyDetails && this.user.access) {
        const PROPERTY_INDEX = menuItems.findIndex(x => x.title === 'Properties');
        if (PROPERTY_INDEX !== -1) {
          menuItems.splice(PROPERTY_INDEX, 1);
        }
        if (this.propertyDetails.userPosition !== 'PROPERTY_POC_POSITION') {
          const REPORT_INDEX = menuItems.findIndex(x => x.title === 'Reports');
          if (REPORT_INDEX !== -1) {
            menuItems.splice(REPORT_INDEX, 1);
          }
        }
        const MANAGE_USERS_IN_PROPERTY_INDEX = this.findIndex(this.user.access, 'MANAGE_USERS_IN_PROPERTY');
        if (MANAGE_USERS_IN_PROPERTY_INDEX !== -1 && this.user.access[MANAGE_USERS_IN_PROPERTY_INDEX].status === false) {
          const MANAGE_USERS_INDEX = this.findIndexMenu(menuItems, 'User Management');
          if (MANAGE_USERS_INDEX !== -1) {
            menuItems.splice(MANAGE_USERS_INDEX, 1);
          }
        }
        const COMPARE_SURVEY_TEMPLATES_INDEX = this.findIndex(this.user.access, 'COMPARE_SURVEY_TEMPLATES');
        if (COMPARE_SURVEY_TEMPLATES_INDEX !== -1 && this.user.access[COMPARE_SURVEY_TEMPLATES_INDEX].status === false) {
          const COMPARE_SURVEY_INDEX = this.findIndexMenu(menuItems, 'Compare Templates');
          if (COMPARE_SURVEY_INDEX !== -1) {
            menuItems.splice(COMPARE_SURVEY_INDEX, 1);
          }
        }
        const MANAGE_INSPECTION_TEMPLATES_INDEX = this.findIndex(this.user.access, 'MANAGE_INSPECTION_TEMPLATES');
        if (MANAGE_INSPECTION_TEMPLATES_INDEX !== -1 && this.user.access[MANAGE_INSPECTION_TEMPLATES_INDEX].status === false) {
          const INSPECTION_TEMPLATES_INDEX = this.findIndexMenu(menuItems, 'Inspection Questions');
          if (INSPECTION_TEMPLATES_INDEX !== -1) {
            menuItems.splice(INSPECTION_TEMPLATES_INDEX, 1);
          }
        }
        if (this.propertyDetails.userPosition === 'LV_POC') {
          const QR_CODE_INDEX = menuItems.findIndex(x => x.title === 'QR Code');
          if (QR_CODE_INDEX !== -1) {
            menuItems.splice(QR_CODE_INDEX, 1);
          }
          const INSPECTIONS_INDEX = menuItems.findIndex(x => x.title === 'Inspections');
          if (INSPECTIONS_INDEX !== -1) {
            menuItems.splice(INSPECTIONS_INDEX, 1);
          }
          const SURVEY_INDEX = menuItems.findIndex(x => x.title === 'Survey');
          if (SURVEY_INDEX !== -1) {
            menuItems.splice(SURVEY_INDEX, 1);
          }
        }
        return menuItems;
      } else {
        return menuItems;
      }
    }
  }
  findIndex(arrayName, itemName) {
    if (arrayName) {
      const index = arrayName.findIndex(x => x.name === itemName);
      return index;
    }
  }
  findIndexMenu(arrayName, itemName) {
    if (arrayName) {
      const index = arrayName.findIndex(x => x.title === itemName);
      return index;
    }
  }
}
