import { State } from './login.model';
import { Pageable } from '@shared/services/utils';
import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

export class PropertyManagementCompany {
  id: number;
  companyName: string;
  companyDisplayName: string;
  address: Address = new Address();
  companyEmailId: string;
  accessToken: string;
  refreshToken: string;
  phoneNumber: string;
  fax: string;
  contactPersonName: string;
  status: string;
  building: string;
  note: string;
  cleaningCompany;
  string;
  propertyManagementCompany: string;
  editStatus: string;
  adminFirstName: string;
  adminLastName: string;
  adminPhoneNumber: string;
  adminEmailId: string;
  mobile: string;
  suitNumber: string;
  propertyId: string;

  clear(): void {
    this.companyName = '';
    this.companyEmailId = '';
    this.phoneNumber = '';
    this.contactPersonName = '';
    this.status = '';
    this.building = '';
    this.cleaningCompany = '';
    this.propertyManagementCompany = '';
  }
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: State[] = [];
  zipCode: string;
}

export class PropertyCompanyPageableModel extends Pageable {
  content: PropertyManagementCompany[] = [];
}

export class PropertyPageableQuery {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  search: string;
  status: boolean;
  propertyId: string;
}
