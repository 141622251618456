export class BaseModel {
  isEditMode = false;
  // To DO remove once confirm
  // userId = 0;
  createdDate: string;
  updatedDate: string;
}

export class Alert {
  constructor(
    public title?: string,
    public description?: string,
    public buttonText?: string,
    public timeout?: number,
    public button?: boolean
  ) {}
}
