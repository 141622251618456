import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

export interface OffcanvasOptions {
  baseClass: string;
  overlay?: boolean;
  closeBy: string;
  toggleBy?: any;
}

@Directive({
  selector: '[sflOffcanvas]',
  exportAs: 'sflOffcanvas'
})
export class OffcanvasDirective implements AfterViewInit {
  @Input() options: OffcanvasOptions;
  private offcanvas: any;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.offcanvas = new sflOffcanvas(this.el.nativeElement, this.options);
    });
  }

  getOffcanvas() {
    return this.offcanvas;
  }
}
