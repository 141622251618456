import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { User } from '@shared/models';
import { AddTenant, Property, Tenant } from '@shared/models/create-building.model';
import { AddloopPropertyPOC } from '@shared/models/loopPropertyPOC.model';
import { LoopVendorPOC } from '@shared/models/loopVendorPOC.model';
import { MessageVM } from '@shared/models/tickets.model';
import { AuthService } from '@shared/services';
import { Subscription } from 'rxjs';
import { BuildingService } from '../building.service';

@Component({
  selector: 'sfl-observer-edit',
  templateUrl: './observer-edit.component.html',
  styleUrls: ['./observer-edit.component.scss']
})
export class ObserverEditComponent implements OnInit {
  @Input() template: any;
  @Output() toggle = new EventEmitter<boolean>();
  addTenant: AddTenant = new AddTenant();
  hasFormErrors: false;
  addMoreTenants: FormGroup[] = new Array();
  addNewTenants: Tenant = new Tenant();
  propertyId: string;
  isData = false;
  isDisableButton = false;
  PHONE_NUMBER_MASK = messages.PHONE_NUMBER_MASK;
  subscriptions = new Subscription();
  editLoopPropertyPOC: AddloopPropertyPOC = new AddloopPropertyPOC();
  isDisableEdit = false;
  loopVendorPOC: LoopVendorPOC = new LoopVendorPOC();
  observer: User = new User();
  observerList: User[] = [];
  editObserver: User = new User();
  observerObject: User = new User();
  roles: string[] = [];
  propertyDetails: Property = new Property();
  userAccesList: any = [];
  userAccesListForMap: any = [];
  isFeatureUpdate = false;
  companyPropertyId: number;
  constructor(
    private readonly buildingService: BuildingService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.propertyId = params['propertyId'];
        this.companyPropertyId = params['companyPropertyId'];
      })
    );
    this.subscriptions.add(
      this.buildingService.getObserverForPropertySubject().subscribe((res: User) => {
        if (res) {
          this.editObserver = res;
          this.isDisableEdit = true;
          this.isFeatureUpdate = false;
          this.setObserver(this.editObserver);
        } else {
          this.userAccesList = [];
          this.addMoreTenant();
        }
      })
    );

    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    if (this.roles[0] === messages.ROLE_USER) {
      this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
      this.companyPropertyId = this.propertyDetails.companyPropertyId;
      if (this.propertyDetails && this.propertyDetails.propertyId) {
        this.propertyId = this.propertyDetails.propertyId;
      }
    }
  }
  // Initialize values to form
  initializeForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_FIRST_NAME)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_LAST_NAME)]),
      phoneNumber: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(messages.VALIDATIONS.MAX_EMAIL)]),
      defaultObserver: new FormControl(''),
      companyPropertyUserId: new FormControl('')
    });
  }

  // Close add tenant template
  closeSidebar() {
    this.isDisableEdit = false;
    this.editObserver = new User();
    this.addMoreTenants = [];
    this.observerList = [];
    this.observerObject = new User();
    this.template.close();
    this.toggle.emit(false);
  }

  // To save tenant on submit
  submit() {
    let tenantData = false;
    //TO DO remove once confirm
    // this.observer.observerUsersVMS = [];
    // this.addMoreTenants.forEach(tenant => {
    //   const tenantControls = tenant.controls;
    //   if (tenant.invalid) {
    //     Object.keys(tenantControls).forEach(controlName => tenantControls[controlName].markAsTouched());
    //     tenantData = true;
    //     return;
    //   }
    //   this.observerObject = new AddObserversProperty();

    //   this.observerObject.firstName = tenant.controls['firstName'].value;
    //   this.observerObject.lastName = tenant.controls['lastName'].value;
    //   this.observerObject.emailId = tenant.controls['emailId'].value;
    //   this.observerObject.phoneNumber = tenant.controls['phoneNumber'].value;
    //   this.observerObject.defaultObserver = tenant.controls['defaultObserver'].value;
    //   this.observerObject.defaultObserver = this.observerObject.defaultObserver === true ? true : false;
    //   this.observerObject.id = this.editObserver.id;
    //   this.observerObject.featureUpdate = this.isFeatureUpdate;
    //   if (this.editObserver.emailId) {
    //     this.observer.firstName = tenant.controls['firstName'].value;
    //     this.observer.lastName = tenant.controls['lastName'].value;
    //     this.observer.phoneNumber = tenant.controls['phoneNumber'].value;
    //     this.observer.emailId = tenant.controls['emailId'].value;
    //   }
    //   this.observer.observerUsersVMS.push(this.observerObject);
    //   this.observerList.push(this.observerObject);
    // });
    this.addMoreTenants.forEach(tenant => {
      const tenantControls = tenant.controls;
      if (tenant.invalid) {
        Object.keys(tenantControls).forEach(controlName => tenantControls[controlName].markAsTouched());
        tenantData = true;
        return;
      }
      this.observerObject = new User();
      this.observerObject.firstName = tenant.controls['firstName'].value;
      this.observerObject.lastName = tenant.controls['lastName'].value;
      this.observerObject.emailId = tenant.controls['emailId'].value;
      this.observerObject.phoneNumber = tenant.controls['phoneNumber'].value;
      this.observerObject.defaultUser = tenant.controls['defaultObserver'].value;
      this.observerObject.defaultUser = this.observerObject.defaultUser === true ? true : false;
      this.observerObject.id = this.editObserver.id;
      this.observerObject.featureUpdate = this.isFeatureUpdate;
      this.observerObject.companyPropertyId = this.companyPropertyId;
      this.observerObject.featuresDTOS = this.userAccesList;
      this.observerObject.companyPropertyUserType = messages.OBSERVER;
      this.observerObject.companyPropertyUserId = tenant.controls['companyPropertyUserId'].value;
      if (this.editObserver.emailId) {
        this.observer.firstName = tenant.controls['firstName'].value;
        this.observer.lastName = tenant.controls['lastName'].value;
        this.observer.phoneNumber = tenant.controls['phoneNumber'].value;
        this.observer.emailId = tenant.controls['emailId'].value;
      }
      this.observerList.push(this.observerObject);
    });
    if (tenantData) {
      return;
    }
    if (this.userAccesList) {
      this.observerObject.featuresDTOS = this.userAccesList;
    }
    if (this.propertyDetails && this.propertyDetails.propertyId) {
      this.propertyId = this.propertyDetails.propertyId;
    }
    this.isDisableButton = true;
    if (this.editObserver.emailId) {
      this.subscriptions.add(
        this.buildingService.updateObserverForProperty(this.observerObject).subscribe(
          (res: MessageVM) => {
            this.isDisableButton = false;
            this.isDisableEdit = false;
            this.buildingService.setObserverSubject(0);
            //TO DO remove once confirm
            // this.buildingService.setObserverForPropertyData(this.observerObject);

            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create);
            this.closeSidebar();
          },
          (addError: HttpErrorResponse) => {
            this.observerList = [];
            this.isDisableButton = false;
            this.layoutUtilsService.showActionNotification(addError.error.message, MessageType.Create);
          }
        )
      );
    } else {
      this.subscriptions.add(
        this.buildingService.createObserver(this.observerList).subscribe(
          (res: MessageVM) => {
            this.isDisableButton = false;
            this.isDisableEdit = false;
            this.buildingService.setObserverSubject(0);
            this.authService.setUserSubject(0);
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create);
            this.closeSidebar();
          },
          (addError: HttpErrorResponse) => {
            this.observer = null;
            this.observerList = [];
            this.isDisableButton = false;
            this.layoutUtilsService.showActionNotification(addError.error.message, MessageType.Create);
          }
        )
      );
    }
  }

  // Add multiple tenants on click of add more button.
  addMoreTenant() {
    this.addMoreTenants.push(this.initializeForm());
  }

  // Remove particular tenant from dialog on clicking
  // delete.
  deleteUser(i, tenant: FormGroup) {
    this.addMoreTenants.splice(i, 1);
  }

  // It takes the name of control and validation Type and throws error message
  // accordingly when it is dirty, touched or invalid.
  isControlHasError(controlName: string, validationType: string, controlGroup: FormGroup): boolean {
    if (controlGroup) {
      const control = controlGroup.controls[controlName];
      if (!control) {
        return false;
      }

      return control.hasError(validationType) && (control.dirty || control.touched);
    }
  }
  onFocusOutEvent(item, index) {
    if (this.addMoreTenants[index].controls.emailId.status === 'INVALID') {
      return;
    }
    this.subscriptions.add(
      this.authService.getUserByEmail(item.target.value).subscribe(
        (res: User) => {
          this.addMoreTenants[index].controls['firstName'].setValue(res.firstName);
          this.addMoreTenants[index].controls['lastName'].setValue(res.lastName);
          this.addMoreTenants[index].controls['phoneNumber'].setValue(res.phoneNumber);
        },
        (error: HttpErrorResponse) => {}
      )
    );
  }
  setObserver(items) {
    if (this.addMoreTenants.length === 0) {
      this.isDisableEdit = true;
      this.addMoreTenants.push(this.initializeForm());
    }
    this.addMoreTenants[0].controls['firstName'].setValue(items.firstName);
    this.addMoreTenants[0].controls['lastName'].setValue(items.lastName);
    this.addMoreTenants[0].controls['emailId'].setValue(items.emailId);
    this.addMoreTenants[0].controls['phoneNumber'].setValue(items.phoneNumber);
    this.addMoreTenants[0].controls['defaultObserver'].setValue(items.defaultUser);
    this.addMoreTenants[0].controls['companyPropertyUserId'].setValue(items.companyPropertyUserId);
    this.propertyId = items.propertyId ? items.propertyId : this.propertyId;
    this.getAccess(items);
  }
  onChaneToggle(event, i, item) {
    this.userAccesListForMap = sessionStorage.getItem('userAddAccess');
    this.userAccesList[i].status = event.checked;
    if (item.name === 'UPDATE_TICKET' || item.name === 'UPDATE_TICKET_STATUS_ONLY') {
      if (item.name === 'UPDATE_TICKET') {
        const STATUS_ONLY_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_ONLY');
        this.userAccesList[STATUS_ONLY_INDEX].status = event.checked;
        this.userAccesList[STATUS_ONLY_INDEX].isDisable = event.checked;
      }
      const STATUS_TO_RESOLVED_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED');
      this.userAccesList[STATUS_TO_RESOLVED_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_RESOLVED_INDEX].isDisable = event.checked;
      const STATUS_TO_RESOLVED_AND_FLAG_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED_AND_FLAG');
      this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].isDisable = event.checked;
      const STATUS_TO_ARCHIVE_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_ARCHIVE');
      this.userAccesList[STATUS_TO_ARCHIVE_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_ARCHIVE_INDEX].isDisable = event.checked;
    }
    if (this.editObserver.companyPropertyUserId) {
      this.isFeatureUpdate = this.userAccesListForMap !== JSON.stringify(this.userAccesList);
    }
  }
  findIndex(arrayName, itemName) {
    const index = arrayName.findIndex(x => x.name === itemName);
    return index;
  }
  getAccess(observer: User) {
    this.userAccesList = [];
    if (observer.emailId) {
      this.subscriptions.add(
        this.buildingService.getPropertyUserFeature(observer.companyPropertyUserId).subscribe((res: any) => {
          this.userAccesList = res;
          this.userAccesList.map(x => (x.isDisable = false));
          this.userAccesList.forEach(element => {
            if ((element.name === 'UPDATE_TICKET' || element.name === 'UPDATE_TICKET_STATUS_ONLY') && element.status) {
              if (element.name === 'UPDATE_TICKET') {
                const STATUS_ONLY_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_ONLY');
                this.userAccesList[STATUS_ONLY_INDEX].status = true;
                this.userAccesList[STATUS_ONLY_INDEX].isDisable = true;
              }
              const STATUS_TO_RESOLVED_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED');
              this.userAccesList[STATUS_TO_RESOLVED_INDEX].status = true;
              this.userAccesList[STATUS_TO_RESOLVED_INDEX].isDisable = true;
              const STATUS_TO_RESOLVED_AND_FLAG_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED_AND_FLAG');
              this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].status = true;
              this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].isDisable = true;
              const STATUS_TO_ARCHIVE_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_ARCHIVE');
              this.userAccesList[STATUS_TO_ARCHIVE_INDEX].status = true;
              this.userAccesList[STATUS_TO_ARCHIVE_INDEX].isDisable = true;
            }
          });
          sessionStorage.setItem('userAddAccess', JSON.stringify(this.userAccesList));
        })
      );
    }
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    sessionStorage.removeItem('userAddAccess');
  }
}
