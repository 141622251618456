import { messages } from '@shared/constants/app.constants';

export class MenuConfig {
  public defaults: any = {
    header: {
      self: {}
    },

    aside: {
      self: {},

      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },

        {
          title: messages.USER_MANAGEMENT,

          root: true,

          bullet: 'dot',

          icon: messages.USER_OUTLINE_ICON,

          page: '/user-management/user-accounts',

          menuIdentifier: 1
        },

        {
          title: 'Companies',

          root: true,

          icon: 'flaticon2-supermarket',

          alignment: 'left',

          page: '/cleaning-companies',

          menuIdentifier: 2
        },
        {
          title: 'Loop Vendors',

          root: true,

          icon: 'flaticon2-supermarket',

          alignment: 'left',

          page: '/loop-vendors',

          menuIdentifier: 3
        }
      ]
    },

    asideCleaning: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: messages.USER_MANAGEMENT,

          root: true,

          bullet: 'dot',

          icon: messages.USER_OUTLINE_ICON,

          page: '/user-management',

          menuIdentifier: 1
        },
        {
          title: 'Loop Vendors',

          root: true,

          icon: 'flaticon2-supermarket',

          alignment: 'left',

          page: '/loop-vendors',

          menuIdentifier: 3
        },
        // TODO afer confirmation remove it
        // {
        //   title: 'Management Companies',

        //   root: true,

        //   icon: 'flaticon2-document',

        //   alignment: 'left',

        //   page: '/property-management-companies',

        //   menuIdentifier: 3,
        // },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building/list',

          menuIdentifier: 4
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: messages.INSPECTION_TITLE,

          root: true,

          icon: messages.INSPECTION_QUESTION_ICON,

          alignment: 'left',

          page: messages.INSPECTION,

          menuIdentifier: 8
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Event',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        {
          title: 'Survey',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_PATH,

          menuIdentifier: 9
        },
        // TO DO Remove once confirm
        // {
        //   title: 'Feedback',

        //   root: true,

        //   icon: messages.FA_FEEDBACK_ICON,

        //   alignment: 'left',

        //   page: messages.FEEDBACK_PATH
        // },
        {
          title: 'Reports',

          root: true,

          icon: messages.FA_REPORT_ICON,

          alignment: 'left',

          page: messages.REPORTS_PATH
        },
        {
          title: 'Archived Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.ARCHIVE_TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Compare Templates',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_COMPARE_PATH,

          menuIdentifier: 8
        },
      ]
    },
    asideProperty: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building',

          menuIdentifier: 4
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        // TO DO Remove once confirm
        // {
        //   title: 'Feedback',

        //   root: true,

        //   icon: messages.FA_FEEDBACK_ICON,

        //   alignment: 'left',

        //   page: messages.FEEDBACK_PATH
        // }
      ]
    },
    asideStandAlone: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building',

          menuIdentifier: 4
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Event',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        }
      ]
    },
    asideTenant: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building',

          menuIdentifier: 4
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        }
      ]
    },
    asideManager: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        // TODO afer confirmation remove it
        // {
        //   title: 'Management Companies',

        //   root: true,

        //   icon: 'flaticon2-document',

        //   alignment: 'left',

        //   page: '/property-management-companies',

        //   menuIdentifier: 3,
        // },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building',

          menuIdentifier: 4
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        {
          title: 'Survey',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_PATH,

          menuIdentifier: 9
        },
        // TO DO Remove once confirm
        // {
        //   title: 'Feedback',

        //   root: true,

        //   icon: messages.FA_FEEDBACK_ICON,

        //   alignment: 'left',

        //   page: messages.FEEDBACK_PATH
        // },
        {
          title: 'Reports',

          root: true,

          icon: messages.FA_REPORT_ICON,

          alignment: 'left',

          page: messages.REPORTS_PATH
        }
      ]
    },
    asideUser: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          menuIdentifier: 7
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        {
          title: 'Survey',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_PATH,

          menuIdentifier: 7
        },
        {
          title: 'Archived Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.ARCHIVE_TICKET_PATH,

          menuIdentifier: 6
        }
      ]
    },
    //For PROPERTY POC USER
    PROPERTY_POC_POSITION: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot',

          isAccess: true,

          menuIdentifier: 1,
        },
        {
          title: messages.USER_MANAGEMENT,

          root: true,

          bullet: 'dot',

          icon: messages.USER_OUTLINE_ICON,

          page: '/user-management',

          isAccess: true,

          menuIdentifier: 2,
        },
        // TODO afer confirmation remove it
        // {
        //   title: 'Management Companies',

        //   root: true,

        //   icon: 'flaticon2-document',

        //   alignment: 'left',

        //   page: '/property-management-companies',

        //   menuIdentifier: 3,
        // },
        // {
        //   title: 'Properties',

        //   root: true,

        //   icon: messages.FA_CITY_ICON,

        //   alignment: 'left',

        //   page: '/building',

        //   menuIdentifier: 4,
        //},
        {
          title: 'QR Code',

          root: true,

          icon: messages.FA_QRCODE_ICON,

          alignment: 'left',

          page: '/qr-code',

          isAccess: true,

          menuIdentifier: 3,
        },
        {
          title: messages.INSPECTION_TITLE,

          root: true,

          icon: messages.INSPECTION_QUESTION_ICON,

          alignment: 'left',

          page: messages.INSPECTION,
          
          isAccess: true,

          menuIdentifier: 4,
        },
        {
          title: 'Inspections',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.INSPECTION_PATH,

          isAccess: true,

          menuIdentifier: 5,
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          isAccess: true,

          menuIdentifier: 6,
        },
        {
          title: 'Event',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          isAccess: true,

          menuIdentifier: 7,
        },
        {
          title: 'Survey',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_PATH,

          isAccess: true,

          menuIdentifier: 8,
        },
        // TO DO Remove once confirm
        // {
        //   title: 'Feedback',

        //   root: true,

        //   icon: messages.FA_FEEDBACK_ICON,

        //   alignment: 'left',

        //   page: messages.FEEDBACK_PATH,

        //   isAccess: true,

        //   menuIdentifier: 9,
        // },
        {
          title: 'Reports',

          root: true,

          icon: messages.FA_REPORT_ICON,

          alignment: 'left',

          page: messages.REPORTS_PATH,

          isAccess: true,

          menuIdentifier: 10,
        },
        {
          title: 'Archived Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.ARCHIVE_TICKET_PATH,

          isAccess: true,

          menuIdentifier: 11,
        },
        {
          title: 'Compare Templates',

          root: true,

          icon: messages.FA_SEARCH_ICON,

          alignment: 'left',

          page: messages.OPEN_SURVEY_COMPARE_PATH,
          
          isAccess: true,

          menuIdentifier: 12
        },
      ]
    },
    ROLE_LOOP_VENDOR_MENU: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'Properties',

          root: true,

          icon: messages.FA_CITY_ICON,

          alignment: 'left',

          page: '/building/list',

          menuIdentifier: 4
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        {
          title: 'Archived Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.ARCHIVE_TICKET_PATH,

          menuIdentifier: 6
        }
      ]
    },
    ROLE_LOOP_VENDOR_POC_MENU: {
      self: {},
      items: [
        {
          title: 'Dashboard',

          root: true,

          icon: messages.CITY_ICON,

          page: messages.DASHBOARD,

          translate: messages.MENU_DASHBOARD,

          bullet: 'dot'
        },
        {
          title: 'Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.TICKET_PATH,

          menuIdentifier: 6
        },
        {
          title: 'Events',

          root: true,

          icon: messages.CALENDAR_ICON,

          alignment: 'left',

          page: messages.EVENT_PATH,

          menuIdentifier: 5
        },
        {
          title: 'Archived Tickets',

          root: true,

          icon: messages.TICKET_ICON,

          alignment: 'left',

          page: messages.ARCHIVE_TICKET_PATH,

          menuIdentifier: 6
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
