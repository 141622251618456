import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@layout/base/base.component';
import { PropertyUserPageableQuery } from '@shared/models/user.model';
import { Subject, Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
import { ConversationDTO, SearchedGroups, SearchedMessages, chatConfigDTO } from '@shared/models/chat.model';
import { messages } from '@shared/constants/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatConfigService } from '../chat_config.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'sfl-chat-search-group-message',
  templateUrl: './chat-search-group-message.component.html',
  styleUrls: ['./chat-search-group-message.component.scss']
})
export class ChatSearchGroupMessageComponent implements OnInit, OnDestroy {
  @Input() storedSearchTerm: string;

  @Output() backToHistory = new EventEmitter<void>();
  @Output() startConversationRoom: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSearchTermChanged: EventEmitter<string> = new EventEmitter<string>();

  subscription: Subscription = new Subscription();
  searchedGroupList: Array<ConversationDTO> = [];
  searchedMessageList: Array<chatConfigDTO> = [];
  filterMessageAndGroupList = { ALL: 'All', GROUPS: 'Groups', MESSAGES: 'Messages' };
  selectedFilterType = this.filterMessageAndGroupList.ALL;
  SINGLE_USER_IMAGE_PATH = messages.SINGLE_USER_IMAGE_PATH;
  GROUP_USER_IMAGE_PATH = messages.GROUP_USER_IMAGE_PATH;
  searchTerm: string;
  isMessageLoading = true;
  isGroupLoading = true;
  showMessagesForClientIds = [];
  debounceForMessageChange = new Subject<string>();

  pageGroupInfo: {
    page: number;
    size: number;
    totalElements?: number;
  } = { page: 0, size: 3 };

  pageMessagesInfo: {
    page: number;
    size: number;
    totalElements?: number;
  } = { page: 0, size: 3 };

  constructor(private readonly chatService: ChatService, private readonly chatConfigService: ChatConfigService) {}

  ngOnInit() {
    this.searchTerm = this.storedSearchTerm;
    if (this.searchTerm) {
      this.applyFilterToNull(this.searchTerm);
    }

    this.debounceForMessageChange.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      this.applyFilterToNull(value);
    });
  }

  onClickBackToHistoryList(): void {
    this.backToHistory.emit();
  }

  applyFilterToNull(value: string): void {
    this.setGroupLoader(true);
    this.setMessageLoader(true);
    this.searchTerm = value ? value : '';
    if (this.searchTerm) {
      this.onSearchTermChanged.emit(this.searchTerm);
      this.onSearchedGroups(this.searchTerm);
      this.onSearchedMessages(this.searchTerm);
    }
  }

  emitSearchTerm(value: string) {
    this.debounceForMessageChange.next(value);
  }

  onFilterChange(filterName: string): void {
    this.selectedFilterType = filterName;
    this.setGroupLoader(true);
    this.setMessageLoader(true);
    switch (filterName) {
      case this.filterMessageAndGroupList.MESSAGES:
        this.changeFilterToMessages();
        break;
      case this.filterMessageAndGroupList.GROUPS:
        this.changeFilterToGroups();
        break;
      default:
        this.onSearchedGroups(this.searchTerm);
        this.onSearchedMessages(this.searchTerm);
        break;
    }
  }

  onSearchedGroups(searchTerm: string): void {
    this.resetGroupPageInfo(this.selectedFilterType === this.filterMessageAndGroupList.ALL ? 3 : 10);
    this.searchedGroupList = [];
    this.searchedGroupResult(searchTerm);
  }

  searchedGroupResult(searchTerm: string): void {
    this.subscription.add(
      this.chatService.getSearchedGroups(this.pageGroupInfo.page, this.pageGroupInfo.size, searchTerm).subscribe(
        (response: SearchedGroups) => {
          this.searchedGroupList = [...this.searchedGroupList, ...response.content];
          this.pageGroupInfo.totalElements = response.totalElements;
          this.setGroupLoader(false);
        },
        (error: HttpErrorResponse) => {
          this.setGroupLoader(false);
        }
      )
    );
  }

  setMessageLoader(loader: boolean): void {
    this.isMessageLoading = loader;
  }

  setGroupLoader(loader: boolean): void {
    this.isGroupLoading = loader;
  }

  onSearchedMessages(searchTerm: string): void {
    this.resetMessagePageInfo(this.selectedFilterType === this.filterMessageAndGroupList.ALL ? 3 : 10);
    this.searchedMessageList = [];
    this.searchedMessageResult(searchTerm);
  }

  searchedMessageResult(searchTerm: string): void {
    this.subscription.add(
      this.chatService.getSearchedMessages(this.pageMessagesInfo.page, this.pageMessagesInfo.size, searchTerm).subscribe(
        (response: SearchedMessages) => {
          this.searchedMessageList = [...this.searchedMessageList, ...response.content];
          this.pageMessagesInfo.totalElements = response.totalElements;
          this.setMessageLoader(false);
        },
        (error: HttpErrorResponse) => {
          this.setMessageLoader(false);
        }
      )
    );
  }

  changeFilterToGroups(): void {
    this.setGroupLoader(true);
    this.selectedFilterType = this.filterMessageAndGroupList.GROUPS;
    this.onSearchedGroups(this.searchTerm);
  }

  changeFilterToMessages(): void {
    this.setMessageLoader(true);
    this.selectedFilterType = this.filterMessageAndGroupList.MESSAGES;
    this.onSearchedMessages(this.searchTerm);
  }

  resetMessagePageInfo(pageSize: number): void {
    this.pageMessagesInfo.page = 0;
    this.pageMessagesInfo.size = pageSize;
  }

  resetGroupPageInfo(pageSize: number): void {
    this.pageGroupInfo.page = 0;
    this.pageGroupInfo.size = pageSize;
  }

  onMessageScroll(): void {
    if (this.searchedMessageList.length < this.pageMessagesInfo.totalElements) {
      this.pageMessagesInfo.page = this.pageMessagesInfo.page + 1;
      this.searchedMessageResult(this.searchTerm);
    }
  }

  onGroupsScroll(): void {
    if (this.searchedGroupList.length < this.pageGroupInfo.totalElements) {
      this.pageGroupInfo.page = this.pageGroupInfo.page + 1;
      this.searchedGroupResult(this.searchTerm);
    }
  }

  onSelectConversation(items) {
    let chatRoom: chatConfigDTO = new chatConfigDTO();
    chatRoom.userToken = sessionStorage.getItem('userId');
    chatRoom.roomId = items.roomId;
    chatRoom.size = messages.DEFAULT_PAGE_SIZE;
    chatRoom.page = messages.DEFAULT_PAGE_NUMBER;
    chatRoom.target = items.roomName;
    chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.getChatRoomMessagesHistory(chatRoom);
    this.startConversationRoom.emit(items);
  }

  onShowMessage(message: chatConfigDTO): void {
    const isSelectedClientMessageIdPresent = this.showMessagesForClientIds.some(id => id === message.clientMessageId);
    if (isSelectedClientMessageIdPresent) {
      this.showMessagesForClientIds = this.showMessagesForClientIds.filter(id => id !== message.clientMessageId);
      return;
    }
    this.showMessagesForClientIds.push(message.clientMessageId);
  }

  shouldShowMessages(clientId: string): boolean {
    return !this.showMessagesForClientIds.includes(clientId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.debounceForMessageChange.unsubscribe();
  }
}
