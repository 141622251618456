import { AccountService } from '@account/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { UserProfileModel } from '@layout/my-profile/my-profile.model';
import { TermsConditionDialogComponent } from '@layout/partials/content/crud/terms-condition-dialog/terms-condition-dialog.component';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { DataService } from '@layout/services/data.service';
import { LayoutService } from '@layout/services/layout.service';
import { AuthNoticeService } from '@shared/auth-notice';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { ResponseModel, SubscriptionVM, UserModel } from '@shared/models/login.model';
import { AuthService } from '@shared/services';
import { MessagingService } from '@shared/services/messaging.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ChatConfigService } from 'src/app/chat/chat_config.service';

@Component({
  selector: 'sfl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  subscriptions = new Subscription();
  subscriptionVM: SubscriptionVM = new SubscriptionVM();
  hide = true;
  private readonly unsubscribe: Subject<any>;

  returnUrl: string;

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly authNoticeService: AuthNoticeService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly messagingService: MessagingService,
    private readonly layoutService: LayoutService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly matDialog: MatDialog,
    private readonly accountService: AccountService,
    private readonly chatConfigService: ChatConfigService,
    private readonly data: DataService,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.initLoginForm();

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  submit() {
    const controls = this.loginForm.controls;
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;

    const authData = {
      emailId: controls.email.value,
      password: controls.password.value,
      rememberMe: true,
    };
    this.subscriptions.add(
      this.auth.login(authData).subscribe(
        (user: ResponseModel) => {
          this.accountService.storeToken(user.token);
          if (user) {
            this.afterLoggedIn(user);
          }
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.authNoticeService.setNotice(error.error.message, 'danger');
        },
      ),
    );
    this.auth.eventEmitter('login', 'project', 'login-comp', 'click', 1);
  }

  afterLoggedIn(user: ResponseModel): void {
    if (user.isFirstTime) {
      this.userLoggedInForFirstTime();
    } else {
      this.accountService.initNotificationService();
      this.accountInfo();
    }
  }

  userLoggedInForFirstTime(): void {
    this.loading = false;
    this.router.navigateByUrl('auth/reset-password');
  }

  accountInfo(): void {
    this.subscriptions.add(
      this.auth.account().subscribe((accountInfo: UserModel) => {
        this.loading = false;
        this.afterAccountInfo(accountInfo);
      }),
    );
  }

  afterAccountInfo(accountInfo: UserModel): void {
    this.accountService.storeUserInfoInStorage(accountInfo);
    if (!accountInfo.agreement) {
      this.showAgreements();
    } else {
      this.setRedirect();
    }
  }

  showAgreements(): void {
    environment.isRXChat ? this.getIsTermAccept() : this.setRedirect();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  onClickVisibilityPassword() {
    this.hide = !this.hide;
  }

  getIsTermAccept() {
    const dialogRef = this.matDialog.open(TermsConditionDialogComponent, {
      data: 'NORMAL',
      disableClose: true,
      height: '90%',
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.subscriptions.add(
          this.accountService.addAcceptTermsAndCondition(`"NORMAL"`).subscribe(
            () => {
              this.setRedirect();
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
            },
          ),
        );
      } else {
        this.layoutUtilsService.showActionNotification(ACTION_MESSAGES.TERMS_AND_CONDITION_LOGIN, MessageType.Update);
        this.subscriptions.add(
          this.auth.logout().subscribe(
            (data: any) => {
              this.subscriptions.add(this.messagingService.unSubscribeToken().subscribe((response: UserProfileModel) => {}));
              if (environment.isRXChat) {
                this.chatConfigService.teardownConnection();
              }
              this.data.changeMessage('');
              sessionStorage.clear();
              localStorage.clear();
              this.router.navigateByUrl('/auth/login');
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
            },
          ),
        );
      }
    });
  }

  setRedirect() {
    if (this.returnUrl) {
      this.subscriptions.add(
        this.layoutService.getIdForRedirectToRXA('analytics').subscribe(
          (uuid: string) => {
            window.location.href = `${environment.urlRXA}/redirect?uuid=${uuid}&&returnUrl=${this.returnUrl}`;
          },
          (error: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
          },
        ),
      );
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }
}
