import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { TicketFilter } from '@shared/models/tickets.model';
import { PageableQuery, concatenateString } from '@shared/services/utils';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
@Component({
  selector: 'sfl-chat-ticket-list',
  templateUrl: './chat-ticket-list.component.html',
  styleUrls: ['./chat-ticket-list.component.scss']
})
export class ChatTicketListComponent implements OnInit {
  @Output() backToPropertyList: EventEmitter<string> = new EventEmitter<string>();
  @Output() startUserListFromTicket: EventEmitter<string> = new EventEmitter<string>();
  @Input('propertyId') companyProperty: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  conversationTicketList: any = [];
  isSpinner = false;
  subscriptions = new Subscription();
  dataSource = [];
  pageableQuery: PageableQuery = new PageableQuery();
  ticketFilter: TicketFilter = new TicketFilter();
  defaultFilter = new TicketFilter();
  searchText: string;
  selectedIndex = 0;
  selectedTicket: any;
  totalElements = 0;

  constructor(private readonly chatService: ChatService, private readonly layoutUtilsService: LayoutUtilsService) {}

  ngOnInit() {
    if (this.companyProperty) {
      this.setPageableObject(messages.LAST_MODIFIED_DATE, messages.SORTBYDESCENDING, 0);
      this.getTicketList(this.defaultFilter);
    }
  }
  getTicketList(ticketFilter: TicketFilter) {
    ticketFilter.byStatus = 'OPEN';
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getTicketByPropertyId(this.pageableQuery, ticketFilter).subscribe(
        (res: any) => {
          if (res) {
            res.content.forEach(content => {
              content.fullAddress = concatenateString(
                content.address.addressLine1,
                content.address.city,
                content.address.state,
                content.address.zipCode
              );
            });
            this.isSpinner = false;
            this.conversationTicketList.push(...res.content);
            this.totalElements = res.totalElements;
            this.selectedTicket = this.conversationTicketList[0];
            this.pageableQuery.page = res.pageable.pageNumber + 1;
          }
        },
        (error: HttpErrorResponse) => {
          this.isSpinner = false;
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
        }
      )
    );
  }
  // This method is for providing values to pageableQuery object.
  setPageableObject(field, sort, page, size: number = messages.DEFAULT_PAGE_SIZE) {
    this.pageableQuery.page = page;
    this.pageableQuery.size = size;
    this.pageableQuery.sort = sort;
    this.pageableQuery.fields = field;
  }
  onScroll() {
    if (!this.isSpinner && this.totalElements > this.conversationTicketList.length) {
      this.getTicketList(this.ticketFilter);
    }
  }
  onSearchTicket(event) {
    event = event.trim();
    if (event.length <= 3) {
      return;
    }
    this.conversationTicketList = [];
    this.ticketFilter.byMobileParameter = event;
    this.ticketFilter.byDescrip = event;
    this.ticketFilter.byLocation = event;
    this.ticketFilter.byMobTicketId = event;
    this.pageableQuery.page = 0;
    this.getTicketList(this.ticketFilter);
  }
  onClickPropertyList() {
    this.backToPropertyList.emit('Ticket');
  }
  onClickUserList() {
    this.startUserListFromTicket.emit(this.selectedTicket);
  }
  onClickSelect(events, item, index) {
    this.selectedIndex = index;
    this.selectedTicket = item;
  }
  getSelected(items) {
    if (items.ticketNumber === this.selectedTicket.ticketNumber) {
      return true;
    } else {
      return false;
    }
  }
}
