import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,Subject } from 'rxjs';
import { CompanyCount, TicketCount, PropertyCount, InspectionCount, EventCount, UnassignedTicketCount, SurveyCount } from '@shared/models/dashboard.model';
import { URL } from '@shared/constants/url.constant';
import { Property } from '@shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly inactivePropertySubject: Subject<Property> = new Subject();
  constructor(
    private readonly http: HttpClient
  ) { }

  // GET call for company count
  getCountOfCompanies(): Observable<CompanyCount> {
    return this.http.get<CompanyCount>(URL.GET_COMPANY_COUNT);
  }
  getCountOfCompaniesOnPropertyChange(propertyChangeInfo = null): Observable<CompanyCount> {
    return this.http.get<CompanyCount>(URL.GET_COMPANY_COUNTT_ON_PROPERTY_SELECT + `?companyPropertyId=${propertyChangeInfo.companyPropertyId}`);
  }

  // GET call for ticket count
  getTicketCount(): Observable<TicketCount> {
    return this.http.get<TicketCount>(URL.GET_TICKET_COUNT);
  }
   getTicketCountOnPropertyChange(propertyChangeInfo = null): Observable<TicketCount> {
    return this.http.get<TicketCount>(URL.GET_TICKET_COUNT_ON_PROPERTY_SELECT+`?companyPropertyId=${propertyChangeInfo.companyPropertyId}`);
  }
  getTicketCountForAdmin(propertyChangeInfo = null): Observable<TicketCount> {
    return this.http.get<TicketCount>(URL.GET_TICKET_COUNT_ON_PROPERTY_SELECT);
  }

  // GET call for property count
  getPropertyCount(): Observable<PropertyCount> {
    return this.http.get<PropertyCount>(URL.GET_PROPERTY_COUNT);
  }

  // GET call for inspection count
  getInspectionCount(): Observable<InspectionCount> {
    return this.http.get<InspectionCount>(URL.GET_INSPECTION_COUNT);
  }
  getInspectionCountOnPropertyChange(propertyChangeInfo = null): Observable<InspectionCount> {
    return this.http.get<InspectionCount>(URL.GET_INSPECTION_COUNT_ON_PROPERTY_SELECT+`?companyPropertyId=${propertyChangeInfo.companyPropertyId}`);
  }

  // GET call for event count
  getEventCount(): Observable<EventCount> {
    return this.http.get<EventCount>(URL.GET_EVENT_COUNT);
  }
  getEventCountOnPropertyChange(propertyChangeInfo = null): Observable<EventCount> {
    return this.http.get<EventCount>(URL.GET_EVENT_COUNT_ON_PROPERTY_SELECT+`?propertyId=${propertyChangeInfo.propertyId}`);
  }

  // GET call for unassigned ticket count
  getUnassignedTicketCount(): Observable<UnassignedTicketCount> {
    return this.http.get<UnassignedTicketCount>(URL.GET_UNASSIGNED_TICKET_COUNT);
  }
  getUnassignedTicketCountOnPropertyChange(propertyChangeInfo = null): Observable<UnassignedTicketCount> {
    return this.http.get<UnassignedTicketCount>(URL.GET_UNASSIGNED_TICKET_COUNT_ON_PROPERTY_SELECT+`?companyPropertyId=${propertyChangeInfo.companyPropertyId}`);
  }
  // getter for update data
  getInactiveProeprtyData(): Observable<Property> {
    return this.inactivePropertySubject.asObservable();
  }

  // Setter for property data from listing
  setInactiveProeprtyData(propertyData:Property) {
    this.inactivePropertySubject.next(propertyData);
  }
  //Function for get survey count
  getSurveyCount(propertyChangeInfo = null): Observable<SurveyCount> {
    if (propertyChangeInfo) {
      return this.http.get<SurveyCount>(URL.GET_SURVEY_COUNT_ON_PROPERTY_SELECT+`?companyPropertyId=${propertyChangeInfo.companyPropertyId}`);
    } else {
      return this.http.get<SurveyCount>(URL.GET_SURVEY_COUNT_ON_PROPERTY_SELECT);
    }
    
  }
}
