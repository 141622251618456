import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  listingSubject: Subject<number> = new Subject();
  constructor() { }

  // Getter for listing for all modules
  getListingSubject(): Observable<number> {
    return this.listingSubject.asObservable();
  }

  // Setter for listing for all modules
  setListingSubject(openStatus: number) {
    return this.listingSubject.next(openStatus);
  }
}
