import { environment } from '@environment/environment';
import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

export const rxStompConfig: InjectableRxStompConfig = {
  brokerURL: environment.socketUrl,
  heartbeatIncoming: 0,
  heartbeatOutgoing: 20000,
  reconnectDelay: 20000,
  debug: (msg: string): void => {
     if (environment.socketLogs) {
      console.log('scoket message');
      console.log(msg);
     }
  }
};
