import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

export interface ScrollTopOptions {
  offset: number;
  speed: number;
}


@Directive({
  selector: '[sflScrollTop]'
})
export class ScrollTopDirective implements AfterViewInit {
  @Input() options: ScrollTopOptions;

  private scrollTop: any;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    this.scrollTop = new sflScrollTop(this.el.nativeElement, this.options);
  }

  getScrollTop() {
    return this.scrollTop;
  }
}
