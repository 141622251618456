import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as objectPath from 'object-path';
import { LayoutConfigService, SplashScreenService } from '@layout/services';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  loaderLogo: string;
  loaderType: string;
  loaderMessage: string;
  LOGO = messages.LOGO_BG_BLACK;

  @ViewChild('splashScreen', { static: true }) splashScreen: ElementRef;

  constructor(
    private readonly el: ElementRef,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly splashScreenService: SplashScreenService
  ) {}

  ngOnInit() {
    // init splash screen, see loader option in layout.config.ts
    const loaderConfig = this.layoutConfigService.getConfig('loader');
    this.loaderLogo = objectPath.get(loaderConfig, 'logo');
    this.loaderType = objectPath.get(loaderConfig, 'type');
    this.loaderMessage = objectPath.get(loaderConfig, 'message');

    this.splashScreenService.init(this.splashScreen);
  }
}
