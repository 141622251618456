import { Pageable } from '@shared/services/utils';
import { EventObject } from 'ngx-fullcalendar';
import { Assignees, Attachment } from './tickets.model';

export class Event {
  eventId: string;
  title: string;
  eventDate: string;
  allDayEvent: boolean;
  note: string;
  startTime: string;
  endTime: string;
  propertyId: string;
  createdForTicketId: string;
  remindBefore: number;
  assignees: Assignees[] = [];
  attachments: Attachment[] = [];
  startDate: string;
  endDate: string;
  textMessageSuccess: boolean;
  editable: boolean;
  updateType: string;
  multiDayEvent: boolean;
  companyPropertyId: number;
  startDateTime: string;
  endDateTime: string;
}

export class EventListFilter {
  month: string;
  ticketId: string;
  propertyId: string;
  startDate: string;
  endDate: string;
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
}

export class EventList extends Pageable {
  content: Event[] = [];
}

export class EventCalendarList implements EventObject {
  id: string;
  title: string;
  allDay: boolean;
  start: string;
  end: string;
  backgroundColor: string;
}

export class EventTime {
  startTime: string;
  endTime: string;
  month: string;
  timeZone: string;
}
