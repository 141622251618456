import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserReact } from '@shared/models/tickets.model';
import { Subscription } from 'rxjs';
@Component({
  selector: 'sfl-user-list-dialog',
  templateUrl: './user-list-dialog.component.html',
  styleUrls: ['./user-list-dialog.component.scss']
})
export class UserListDialogComponent implements OnInit {
  subscriptions = new Subscription();
  userList: UserReact[];
  Object = Object;
  pleaseWait = false;
  headerName: string;
  constructor(
    public dialogRef: MatDialogRef<UserListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.userList = this.data.userList;
    this.headerName = this.data.dialogHeader;
  }
  onClickClose() {
    this.dialogRef.close();
  }
}

