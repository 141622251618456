import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { messages } from '@shared/constants/app.constants';
import { AuthService } from '@shared/services';
import { Subscription } from 'rxjs';
import { ChatService } from './chat/chat.service';
import { LayoutConfigService, SplashScreenService } from './layout/services';
declare let gtag: Function;

@Component({
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'BlueHalo';
  loader: boolean;
  private readonly unsubscribe: Subscription[] = [];
  tabActive: boolean = true;
  @ViewChild('notificationSound', { static: true }) notificationSoundRef!: ElementRef;
  subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly splashScreenService: SplashScreenService,
    private readonly authService: AuthService,
    private readonly chatService: ChatService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-173019128-1', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, messages.VALIDATIONS.KT_PAGE_LOADED);
      }
    });
    this.authService.initializeGeolocationScript();
    this.unsubscribe.push(routerSubscription);
    this.subscription.add(
      this.chatService.playAudioOnNewMessage.asObservable().subscribe(msg => {
        if (msg) {
          this.playNotificationSound();
          this.chatService.playAudioOnNewMessageInRoom(null);
        }
      }),
    );
  }

  handleVisibilityChange = () => {
    this.tabActive = !document.hidden;
    if (this.tabActive) {
      document.getElementById('appFavicon').setAttribute('href', '../assets/media/logos/favicon.png');
    }
  };

  playNotificationSound(): void {
    const audioElement: HTMLAudioElement = this.notificationSoundRef.nativeElement;
    audioElement.muted = true;
    if (!this.tabActive) {
      audioElement.muted = false;
      audioElement.play();
      document.getElementById('appFavicon').setAttribute('href', '../assets/media/logos/favicon_.png');
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
