import { Directive, ElementRef, HostListener, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[sflTitleCase]'
})
export class TitleCaseDirective {
  private el: HTMLInputElement;
  constructor(@Optional() private ngControl: NgControl, private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }
  @HostListener("input")
  onInput() {
    if (this.el.value) {
      const pos = this.el.selectionStart; //get the position of the cursor
      const first = this.el.value.substr(0,1).toUpperCase();
       first + this.el.value.substr(1);
      this.el.value = first + this.el.value.substr(1);
      this.el.selectionStart = this.el.selectionEnd = pos;
      if (this.ngControl) {
        this.ngControl.control.setValue(this.el.value, { emit: false })
      }
    }
  }
  //TODO remove once approve.
  // ngOnInit() {
  //   if (this.el.value) {
  //     let first = this.el.value.substr(0,1).toUpperCase();
  //      first + this.el.value.substr(1);
  //     this.el.value = first + this.el.value.substr(1);
  //     if (this.ngControl)
  //       this.ngControl.control.setValue(this.el.value, { emit: false })
  //   }
  // }
  // constructor(private el: ElementRef) {
  // }
  // @HostListener('input') onInput() {
  //   if (this.el.nativeElement.value) {
  //     const inputValue = this.el.nativeElement.value;
  //     this.el.nativeElement.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  //   }
  // }
}