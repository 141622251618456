import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {
  viewLoading = false;
  disableCancel = false;

  constructor(public dialogRef: MatDialogRef<ActivateUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    this.disableCancel = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, messages.MODEL_TIMEOUT);
  }
}
