// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   enableErrorReporting: false,
//   isMockEnabled: false, // You have to switch this, when your real back-end is done
//   // serverUrl: 'http://192.168.0.128:8080/api',
//   serverUrl: 'https://dev-live.bluehalo.global/api',
//   webUrl: 'https://dev-live.bluehalo.global',
//   // webUrl: 'http://192.168.0.123',
//   geoLocationUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places',
//   geoLocationApiKey: 'AIzaSyCve5JqjikhpCS0zHFTO-P0wNAUCbnOCio',
//   socketUrl: 'wss://sx-dev.resolvedx.io/socket',
//   socketLogs: true,
//   isRXA: true,
//   isRXChat: true,
//   urlRXA: 'https://dev-analytics.bluehalo.global',
//   urlRXM: 'https://dev-loto.bluehalo.global',
//   firebase: {
//     apiKey: 'AIzaSyCmpXmCFzaIGazIoUNYZvjVka7Rs_su1PQ',
//     authDomain: 'resolvedx-27f61.firebaseapp.com',
//     databaseURL: 'https://resolvedx-27f61.firebaseio.com',
//     projectId: 'resolvedx-27f61',
//     storageBucket: 'resolvedx-27f61.appspot.com',
//     messagingSenderId: '811169576799',
//     appId: '1:811169576799:web:a07b2da7213656cd75e8ea',
//     measurementId: 'G-HWELG7XWBH',
//   },
// };

export const environment = {
  production: false,
  enableErrorReporting: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  serverUrl: 'https://stage-live.bluehalo.global/api',
  webUrl: 'https://stage-live.bluehalo.global',
  geoLocationUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places',
  geoLocationApiKey: 'AIzaSyCve5JqjikhpCS0zHFTO-P0wNAUCbnOCio',
  socketUrl: 'wss://stage-live.bluehalo.global/socket',
  socketLogs: true,
  isRXA: true,
  isRXChat: true,
  urlRXA: 'https://stage-analytics.bluehalo.global',
  urlRXM: 'https://stage-loto.bluehalo.global',
  firebase: {
    apiKey: 'AIzaSyCmpXmCFzaIGazIoUNYZvjVka7Rs_su1PQ',
    authDomain: 'resolvedx-27f61.firebaseapp.com',
    databaseURL: 'https://resolvedx-27f61.firebaseio.com',
    projectId: 'resolvedx-27f61',
    storageBucket: 'resolvedx-27f61.appspot.com',
    messagingSenderId: '811169576799',
    appId: '1:811169576799:web:a07b2da7213656cd75e8ea',
    measurementId: 'G-HWELG7XWBH',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
