import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import * as objectPath from 'object-path';
import {
  LayoutConfigService,
  MenuConfigService,
  PageConfigService
} from '@layout/services';
import { HtmlClassService } from '../services/html-class.service';
import { LayoutConfig } from '../config/layout.config';
import { MenuConfig } from '../config/menu.config';
import { PageConfig } from '../config/page.config';

@Component({
  selector: 'sfl-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {

  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  desktopHeaderDisplay: boolean;
  fitTop: boolean;
  fluid: boolean;

  private readonly unsubscribe: Subscription[] = [];

  constructor(
    private readonly layoutConfigService: LayoutConfigService,
    private readonly menuConfigService: MenuConfigService,
    private readonly pageConfigService: PageConfigService,
    private readonly htmlClassService: HtmlClassService
  ) {

    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      layoutConfig => {
        // reset body class based on global and page level layout config, refer to html-class.service.ts
        document.body.className = '';
        this.htmlClassService.setConfig(layoutConfig);
      }
    );
    this.unsubscribe.push(subscr);
  }

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.desktopHeaderDisplay = objectPath.get(
      config,
      'header.self.fixed.desktop'
    );
    this.fitTop = objectPath.get(config, 'content.fit-top');
    this.fluid = objectPath.get(config, 'content.width') === 'fluid';

    // let the layout type change
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout');
      });
    });
    this.unsubscribe.push(subscr);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
