import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { messages } from '@shared/constants/app.constants';
import { DeleteEntityDialogComponent } from '..';

@Component({
  selector: 'sfl-alert-info-dialog',
  templateUrl: './alert-info-dialog.component.html',
  styleUrls: ['./alert-info-dialog.component.scss']
})
export class AlertInfoDialogComponent implements OnInit {
  viewLoading = false;

  constructor(public dialogRef: MatDialogRef<DeleteEntityDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, messages.PRINT_TIMEOUT);
  }
}
