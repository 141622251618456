// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-property-selection-dialog',
  templateUrl: './property-selection-dialog.component.html',
  styleUrls: ['./property-selection-dialog.component.scss']
})
export class PropertySelectionDialogComponent implements OnInit {
  viewLoading = false;
  disableCancel = false;
  selectedProperty: string;
  properties = [];
  constructor(public dialogRef: MatDialogRef<PropertySelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    if (this.data.length) {
      this.properties = this.data;
    }
  }
  onLogoutClick() {
    this.dialogRef.close(false);
  }
  onChangeProperty() {
    this.dialogRef.close(this.selectedProperty);
  }
}
