import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { URL } from '@shared/constants/url.constant';
import { User } from '@shared/models';
import { PMCName, PropertyPMC } from '@shared/models/create-building.model';
import { State } from '@shared/models/login.model';
import { MessageVM } from '@shared/models/tickets.model';
import { FeaturesDTOList } from '@shared/models/user-feature.model';
import { Property, PropertyArray } from '@shared/models/user.model';
import { AuthService } from '@shared/services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { BuildingService } from 'src/app/building/building.service';

@Component({
  selector: 'sfl-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserEditComponent implements OnInit, OnDestroy {
  @Output() toggle = new EventEmitter<boolean>();
  user: User = new User();
  id: number;
  oldUser: User;
  selectedTab = 0;
  loading$: Observable<boolean>;
  userForm: FormGroup = this.initializeForm();
  hasFormErrors = false;
  subscriptions: Subscription = new Subscription();
  roles: string[] = [];
  firstName = this.userForm.controls['firstName'];
  lastName = this.userForm.controls['lastName'];
  phoneNumber = this.userForm.controls['phoneNumber'];
  emailId = this.userForm.controls['emailId'];
  roleName = this.userForm.controls['roleName'];
  propertyUserRole = this.userForm.controls['roleName'];
  manager = this.userForm.controls['manager'];
  defaultUser = this.userForm.controls['defaultUser'];
  companyPropertyUserId = this.userForm.controls['companyPropertyUserId'];
  propertySearchField = new FormControl('');
  propertyList: Property[] = [];
  propertyField: Property[] = [];
  state: State[] = [];
  editUser: User = new User();
  userTitle: string;
  isManagerChecked = false;
  isPMCCompany = false;
  propertyId = this.userForm.controls['propertyId'];
  managementCompanyList: PMCName[] = [];
  isManagerProperty = false;
  PHONE_NUMBER_MASK = messages.PHONE_NUMBER_MASK;
  userAuthorities: any = [
    { Name: 'Property POC', Value: 'POC' },
    { Name: 'Manager', Value: messages.MANAGER },
    { Name: 'Assignee', Value: 'USER' }
  ];
  userAccesList: any = [];
  userAccesListForMap: any = [];
  @Input() template: any;
  isDisableButton = false;
  isDefaultPOC = false;
  propertyDetails: Property = new Property();
  featuresDTOList: FeaturesDTOList = new FeaturesDTOList();
  userPosition: string;
  isFeatureUpdate = false;
  isPositionUpdate = false;
  companyPropertyId: number;
  constructor(
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly authService: AuthService,
    private readonly buildingService: BuildingService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  options = { componentRestrictions: { country: [messages.COUNTRY_NAME] } };

  // To auto fill the address into respective fields when selected
  // from autocomplete
  // TODO Remove once confirm
  // public handleAddressChange(address: Address) {
  //   if (address && address.address_components) {
  //     this.addressLine1.setValue(address.name);
  //     address.address_components.forEach(addressComponent => {
  //       switch (addressComponent.types[0]) {
  //         case 'sublocality':
  //           this.addressLine1.setValue(addressComponent.long_name);
  //           break;
  //         case 'locality':
  //           this.city.setValue(addressComponent.long_name);
  //           break;
  //         case 'administrative_area_level_1':
  //           this.stateFormControl.setValue(addressComponent.long_name);
  //           break;
  //         case 'postal_code':
  //           this.zipCode.setValue(addressComponent.long_name);
  //           break;
  //       }
  //     });
  //   }
  // }

  ngOnInit() {
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.getComponentTitle();
    this.getStates();
    this.subscriptions.add(
      this.authService.getEditUserSubject().subscribe((res: User) => {
        if (res) {
          this.isPositionUpdate = false;
          this.isFeatureUpdate = false;
          this.userAccesListForMap = [];
          this.editUser = res;
          this.editUserData(this.editUser);
        }
        this.getAccess(this.editUser);
      })
    );
    this.subscriptions.add(
      this.propertySearchField.valueChanges.subscribe(() => {
        this.filterPropertyMulti();
      })
    );

    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.propertyId = params['propertyId'];
        this.companyPropertyId = params['companyPropertyId'];
      })
    );
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    if (this.roles[0] === messages.ROLE_USER) {
      this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
      this.companyPropertyId = this.propertyDetails.companyPropertyId;
    }
    this.getAccess(this.user);
  }

  // Filters properties from multiselect
  filterPropertyMulti() {
    this.propertyField = this.propertyList.filter(property => {
      if (this.propertySearchField.value) {
        return property.propertyName.includes(this.propertySearchField.value);
      } else {
        return this.propertyList;
      }
    });
  }

  //Get property name list
  getAllProperties(): void {
    this.subscriptions.add(
      this.buildingService.getAllProperty().subscribe((res: PropertyArray) => {
        this.propertyList = res.properties;
        this.propertyField = res.properties;
      })
    );
  }

  // To compare the preselected value and update and reflect data accordingly.
  public objectComparisionFunction = function (option, value): boolean {
    if (option && value) {
      return option === value;
    }
  };

  // This method is for form Control
  initializeForm(): FormGroup {
    const userFormGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_FIRST_NAME)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_LAST_NAME)]),
      phoneNumber: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(messages.VALIDATIONS.MAX_EMAIL)]),
      pmcCompany: new FormControl(''),
      manager: new FormControl(),
      roleName: new FormControl('', [Validators.required]),
      propertyId: new FormControl(''),
      propertyUserRole: new FormControl(''),
      defaultUser: new FormControl(''),
      companyPropertyUserId: new FormControl('')
    });
    return userFormGroup;
  }

  getStates() {
    this.subscriptions.add(
      this.authService.getStates().subscribe((res: State[]) => {
        this.state = res;
      })
    );
  }

  // Get all PMC Company list
  getAllPMCCompany() {
    this.subscriptions.add(
      this.buildingService.getAllPMC().subscribe((res: PropertyPMC) => {
        this.managementCompanyList = res.comapies;
      })
    );
  }

  // To hide fields as per selection of other field or checked checkbox
  handleIsManager(event) {
    if (event.value === messages.POC || event.value === messages.MANAGER) {
      this.userPosition = this.setUserDefaultPosition(event.value);
      this.isDefaultPOC = true;
    } else {
      this.isDefaultPOC = false;
      this.userForm.controls['defaultUser'].setValue(false);
    }
    if (event.checked) {
      this.userForm.controls['propertyId'].setValidators(Validators.required);
    } else {
      this.userForm.controls['propertyId'].clearValidators();
    }
    this.userForm.controls['propertyId'].updateValueAndValidity();
    if (this.editUser.position) {
      this.isPositionUpdate = this.editUser.position === event.value ? false : true;
    }
  }

  // This method is for adding user in the system on basis of roles.
  onSubmit(withBack = false, addUserForm: FormGroupDirective, formStatus) {
    this.hasFormErrors = false;
    const controls = this.userForm.controls;
    if (this.userForm.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      const top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
      }
      return;
    }
    if (this.propertyDetails && this.propertyDetails.propertyId) {
      this.userForm.value.propertyId = this.propertyDetails.propertyId;
    } else {
      this.userForm.value.propertyId = this.propertyId;
    }
    const userData = this.prepareUser();
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    let url;
    if (
      this.roles[0] === messages.ROLE_COMPANY_ADMIN ||
      this.roles[0] === messages.ROLE_CLEANING_POC ||
      this.roles[0] === messages.ROLE_USER
    ) {
      if (this.propertyUserRole.value) {
        userData.position = messages.PMCPOC;
      } else if (this.manager.value) {
        userData.position = messages.MANAGER;
      } else if (userData.position === messages.CLEANINGPOC && this.editUser.emailId) {
        userData.position = messages.CLEANINGPOC;
      } else {
        userData.position = messages.USER;
      }
      url = URL.COMPANY_PROPERTY_USERS;
    } else if (this.roles[0] === messages.ROLE_ADMIN) {
      userData.position = !this.editUser.emailId ? messages.ADMIN : userData.position;
      url = URL.ADD_USER;
    }
    if (!this.propertyUserRole.value) {
      userData.position = messages.USER;
    } else {
      userData.position = this.propertyUserRole.value;
    }
    this.isDisableButton = true;
    if (this.editUser.emailId) {
      this.subscriptions.add(
        this.authService.updateUser(userData, url).subscribe(
          (res: MessageVM) => {
            this.editUser = new User();
            this.authService.setUserSubject(0);
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create);
            this.closeSidebar(addUserForm, formStatus);
          },
          (error: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
          }
        )
      );
    } else {
      this.subscriptions.add(
        this.authService.createUser(userData, url).subscribe(
          (res: MessageVM) => {
            this.authService.setUserSubject(0);
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create);
            this.closeSidebar(addUserForm, formStatus);
          },
          (editError: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(editError.error.message, MessageType.Create);
            this.isDisableButton = false;
          }
        )
      );
    }
  }

  // This method is for showing roleName on basis of role of user
  showRoleName() {
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    if (
      this.roles[0] === messages.ROLE_CLEANING_POC ||
      this.roles[0] === messages.ROLE_COMPANY_ADMIN ||
      this.roles[0] === messages.ROLE_USER ||
      this.roles[0] === messages.MANAGER
    ) {
      return true;
    } else {
      return false;
    }
  }

  prepareUser(): User {
    this.user.companyPropertyId = this.companyPropertyId;
    this.user.companyPropertyUserType = this.roleName.value;
    this.user.position = this.roleName.value;
    this.user.firstName = this.firstName.value;
    this.user.emailId = this.emailId.value;
    this.user.lastName = this.lastName.value;
    this.user.phoneNumber = this.phoneNumber.value;
    this.user.managementCompanyEmail = this.propertyUserRole.value ? this.propertyUserRole.value : null;
    this.user.manager = this.manager.value ? this.manager.value : false;
    this.user.positionUpdate = this.isPositionUpdate;
    this.user.featureUpdate = this.isFeatureUpdate;
    this.user.companyPropertyUserId = this.companyPropertyUserId.value ? this.companyPropertyUserId.value : null;
    this.user.propertyId = this.propertyDetails.propertyId ? this.propertyDetails.propertyId : this.userForm.value.propertyId;
    this.user.defaultUser = this.userForm.value.defaultUser === true ? true : false;
    if (this.editUser.emailId) {
      this.user.featuresDTOS = this.userAccesList;
    } else {
      for (let i = 0; i < this.userAccesList.length; i++) {
        if (this.userAccesList[i].id) {
          this.userAccesList[i].id = null;
        }
      }
      this.user.featuresDTOS = this.userAccesList;
    }
    return this.user;
  }

  // To update data in form
  editUserData(user: User) {
    this.userForm.setValue({
      roleName: user.companyPropertyUserType,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      emailId: user.emailId,
      pmcCompany: user.managementCompanyEmail ? user.managementCompanyEmail : null,
      manager: user.position === messages.MANAGER ? true : false,
      propertyId: user.propertyId ? user.propertyId : null,
      propertyUserRole: user.position ? user.position : null,
      defaultUser: user.defaultUser === true ? true : false,
      companyPropertyUserId: user.companyPropertyUserId
    });
    this.isDefaultPOC = user.companyPropertyUserType === messages.POC || user.companyPropertyUserType === messages.MANAGER ? true : false;
    this.userPosition = this.setUserDefaultPosition(user.companyPropertyUserType);
    this.getComponentTitle();
  }
  setUserDefaultPosition(item) {
    if (item === messages.POC) {
      return messages.POC;
    } else if (item === messages.MANAGER) {
      return messages.Manager;
    }
  }
  // Close the sidebar on click and reset the form
  closeSidebar(addUserForm: FormGroupDirective, formStatus) {
    this.isDisableButton = false;
    addUserForm.resetForm();
    this.editUser = new User();
    this.getComponentTitle();
    this.isManagerChecked = false;
    this.isPMCCompany = false;
    if (formStatus === 'close') {
      this.toggle.emit(false);
    } else {
      this.getAccess(this.editUser);
    }
    this.isDefaultPOC = false;
    this.userForm.controls['propertyId'].clearValidators();
  }

  // To get the title as per action(add, edit).
  getComponentTitle() {
    if (!this.editUser.emailId) {
      this.userForm.controls['emailId'].enable();
      this.userTitle = 'Add User';
    } else {
      this.userForm.controls['emailId'].disable();
      this.userTitle = 'Edit User';
    }
  }
  onFocusOutEvent(item) {
    if (this.userForm.controls.emailId.status === 'INVALID') {
      return;
    }
    this.subscriptions.add(
      this.authService.getUserByEmail(item.target.value).subscribe(
        (res: User) => {
          this.userForm.controls['firstName'].setValue(res.firstName);
          this.userForm.controls['lastName'].setValue(res.lastName);
          this.userForm.controls['phoneNumber'].setValue(res.phoneNumber);
        },
        (error: HttpErrorResponse) => {}
      )
    );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.userForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  getAccess(user: User) {
    this.userAccesList = [];
    if (user.emailId) {
      this.subscriptions.add(
        this.buildingService.getPropertyUserFeature(user.companyPropertyUserId).subscribe((res: any) => {
          this.userAccesList = res;
          this.userAccesList.forEach(element => {
            if ((element.name === 'UPDATE_TICKET' || element.name === 'UPDATE_TICKET_STATUS_ONLY') && element.status) {
              if (element.name === 'UPDATE_TICKET') {
                const STATUS_ONLY_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_ONLY');
                this.userAccesList[STATUS_ONLY_INDEX].status = true;
                this.userAccesList[STATUS_ONLY_INDEX].isDisable = true;
              }
              const STATUS_TO_RESOLVED_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED');
              this.userAccesList[STATUS_TO_RESOLVED_INDEX].status = true;
              this.userAccesList[STATUS_TO_RESOLVED_INDEX].isDisable = true;
              const STATUS_TO_RESOLVED_AND_FLAG_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED_AND_FLAG');
              this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].status = true;
              this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].isDisable = true;
              const STATUS_TO_ARCHIVE_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_ARCHIVE');
              this.userAccesList[STATUS_TO_ARCHIVE_INDEX].status = true;
              this.userAccesList[STATUS_TO_ARCHIVE_INDEX].isDisable = true;
            }
          });
          sessionStorage.setItem('userAddAccess', JSON.stringify(this.userAccesList));
        })
      );
    } else {
      this.subscriptions.add(
        this.buildingService.getPropertyFeature().subscribe((res: any) => {
          this.userAccesList = res;
          this.userAccesList.map(x => (x.status = true));
        })
      );
    }
    this.userAccesList.map(x => (x.isDisable = false));
  }
  onChaneToggle(event, i, item) {
    this.userAccesListForMap = sessionStorage.getItem('userAddAccess');
    this.userAccesList[i].status = event.checked;
    if (item.name === 'UPDATE_TICKET' || item.name === 'UPDATE_TICKET_STATUS_ONLY') {
      if (item.name === 'UPDATE_TICKET') {
        const STATUS_ONLY_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_ONLY');
        this.userAccesList[STATUS_ONLY_INDEX].status = event.checked;
        this.userAccesList[STATUS_ONLY_INDEX].isDisable = event.checked;
      }
      const STATUS_TO_RESOLVED_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED');
      this.userAccesList[STATUS_TO_RESOLVED_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_RESOLVED_INDEX].isDisable = event.checked;
      const STATUS_TO_RESOLVED_AND_FLAG_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_RESOLVED_AND_FLAG');
      this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_RESOLVED_AND_FLAG_INDEX].isDisable = event.checked;
      const STATUS_TO_ARCHIVE_INDEX = this.findIndex(this.userAccesList, 'UPDATE_TICKET_STATUS_TO_ARCHIVE');
      this.userAccesList[STATUS_TO_ARCHIVE_INDEX].status = event.checked;
      this.userAccesList[STATUS_TO_ARCHIVE_INDEX].isDisable = event.checked;
    }
    if (item.name === 'CONTACT_SURVEY_PERFORMER' && event.checked) {
      const PERFORMER_DETAILS_INDEX = this.findIndex(this.userAccesList, 'VIEW_SURVEY_PERFORMER_DETAILS');
      this.userAccesList[PERFORMER_DETAILS_INDEX].status = event.checked;
    }
    if (item.name === 'VIEW_SURVEY_PERFORMER_DETAILS' && !event.checked) {
      const SURVEY_PERFORMER_INDEX = this.findIndex(this.userAccesList, 'CONTACT_SURVEY_PERFORMER');
      this.userAccesList[SURVEY_PERFORMER_INDEX].status = event.checked;
    }
    if (this.editUser.companyPropertyUserType) {
      this.isFeatureUpdate = this.userAccesListForMap !== JSON.stringify(this.userAccesList);
    }
  }
  findIndex(arrayName, itemName) {
    const index = arrayName.findIndex(x => x.name === itemName);
    return index;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    sessionStorage.removeItem('userAddAccess');
  }
}
