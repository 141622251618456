import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { Event, EventListFilter, EventTime } from '@shared/models/event.model';
import { AssigneeArray, MessageVM } from '@shared/models/tickets.model';
import { createRequestOption } from '@shared/services/utils';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private readonly eventListingSubject: Subject<EventTime> = new Subject();
  private readonly editEventSubject: Subject<string> = new Subject();
  private readonly updateEventSubject: Subject<Event> = new Subject();
  private readonly createEventSubject: Subject<string> = new Subject();
  private readonly eventCalendarSubject: Subject<EventTime> = new Subject();
  constructor(private readonly http: HttpClient) {}

  // POST API to get all events on calendar by applying filter
  getAllEvents(eventFilter: EventListFilter): Observable<Event[]> {
    return this.http.post<Event[]>(URL.GET_ALL_EVENTS+`?timeZone=${eventFilter.timeZone}`, eventFilter);
  }

  // GET API to get event by id.
  getEventById(eventId: string): Observable<Event> {
    return this.http.get<Event>(URL.ADD_EVENT + `/${eventId}`);
  }

  // POST API for create event
  createEvent(eventData: Event): Observable<Event> {
    return this.http.post<Event>(URL.ADD_EVENT, eventData);
  }

  // PUT API for update event
  updateEvent(eventData: Event): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.ADD_EVENT, eventData);
  }

  // Getter for listing subject
  getEventListingSubject(): Observable<EventTime> {
    return this.eventListingSubject.asObservable();
  }

  // Setter for event listing subject
  setEventListingSubject(listingData: EventTime) {
    return this.eventListingSubject.next(listingData);
  }

  // Getter for event calendar subject
  getCalendarEventSubject(): Observable<EventTime> {
    return this.eventCalendarSubject.asObservable();
  }

  // Setter for event calendar subject
  setCalendarEventSubject(eventTime: EventTime) {
    this.eventCalendarSubject.next(eventTime);
  }

  // Getter for edit Event subject
  getEditEventSubject(): Observable<string> {
    return this.editEventSubject.asObservable();
  }

  // Setter for edit Event subject
  setEditEventSubject(id: string) {
    this.editEventSubject.next(id);
  }

  //Getter for update Event Subject
  getupdateEventSubject(): Observable<Event> {
    return this.updateEventSubject.asObservable();
  }

  //Setter for update Event Subject
  setUpdateEventSubject(eventData: Event) {
    this.updateEventSubject.next(eventData);
  }
  //Getter for create Event Subject
  getCreateEventSubject(): Observable<string> {
    return this.createEventSubject.asObservable();
  }

  //Setter for create Event Subject
  setCreateEventSubject(propertyId) {
    this.createEventSubject.next(propertyId);
  }
  // GET API for retrieving all assignees
  getAllAssignees(propertyId: string): Observable<AssigneeArray> {
    const propertyid = { companyPropertyId: propertyId};
    return this.http.get<AssigneeArray>(URL.GET_ASSIGNEE_LIST_EVENT, {
      params: createRequestOption(propertyid)
    });
  }
  // DELETE API for event
  deleteEvent(eventId: string, updateType: string): Observable<MessageVM> {
    return this.http.delete<MessageVM>(URL.ADD_EVENT + `/${eventId}?updateType=${updateType}`);
  }
}
