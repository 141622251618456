import { AccountService } from '@account/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { ResetPasswordModel } from '@shared/models/account.model';
import { AuthService } from '@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sfl-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordModel: ResetPasswordModel = new ResetPasswordModel();
  changePasswordForm: FormGroup = this.initializeForm();
  subscriptions = new Subscription();
  loading = false;
  noMatch = false;
  hide = true;
  hideForConfirm = true;
  newPassword = this.changePasswordForm.controls['newPassword'];
  confirmPassword = this.changePasswordForm.controls['confirmPassword'];
  passwordPattern = messages.PASSWORD_PATTERN;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  initializeForm(): FormGroup {
    return new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        // TODO remove once confirm
        // Validators.minLength(messages.VALIDATIONS.MIN_PASSWORD_LENGTH),
        // Validators.maxLength(messages.VALIDATIONS.MAX_PASSWORD_LENGTH)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        // TODO remove once confirm
        // Validators.minLength(messages.VALIDATIONS.MIN_PASSWORD_LENGTH),
        // Validators.maxLength(messages.VALIDATIONS.MAX_PASSWORD_LENGTH)
      ])
    });
  }

  setPasswordForm(changePassword: ResetPasswordModel) {
    return this.changePasswordForm.setValue({
      newPassword: changePassword.newPassword,
      confirmPassword: changePassword.confirmPassword
    });
  }

  setPassword(): ResetPasswordModel {
    this.changePasswordModel.newPassword = this.newPassword.value;
    this.changePasswordModel.confirmPassword = this.confirmPassword.value;
    this.changePasswordModel.email = this.data ? this.data : null;
    return this.changePasswordModel;
  }

  matchPassword() {
    if (this.newPassword.value && this.confirmPassword.value) {
      if (this.newPassword.value !== this.confirmPassword.value) {
        this.noMatch = true;
      } else {
        this.noMatch = false;
      }
    } else {
      this.noMatch = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.authService.setDialogSubject(false);
  }

  onYesClick(): void {
    const controls = this.changePasswordForm.controls;
    if (this.changePasswordForm.invalid || this.noMatch) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      return;
    }
    this.loading = true;
    this.subscriptions.add(
      this.accountService.resetPassword(this.setPassword()).subscribe(
        (res: ResetPasswordModel) => {
          this.loading = false;
          this.dialogRef.close();
          this.authService.setDialogSubject(false);
          this.layoutUtilsService.showActionNotification(ACTION_MESSAGES.CHANGE_PASSWORD_SUCCESS, MessageType.Create);
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
        }
      )
    );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.changePasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  
  onClickVisibilityPassword() {
    this.hide = !this.hide;
  }
  onClickVisibilityPasswordForConfirm() {
    this.hideForConfirm = !this.hideForConfirm;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
