import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { messages } from '@shared/constants/app.constants';
import { AuthService } from '@shared/services';
import * as io from 'socket.io-client';
import * as uuid from 'uuid';
import { ChatService } from './chat.service';
let socket: any = null;
@Injectable({
  providedIn: 'root'
})
export class ChatConfigService {
  constructor(private readonly chatService: ChatService, private readonly authService: AuthService) {}
  //// FOR Chat module test
  isConnected: boolean;
  pingCount = 0;
  deviceUniqueId;
  isLoaderFlag: boolean;
  connect() {
    this.isLoaderFlag = true;
    if (sessionStorage.getItem('deviceUniqueId')) {
      console.log('old', this.deviceUniqueId);
      this.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    } else {
      this.deviceUniqueId = uuid.v4();
      console.log('new', this.deviceUniqueId);
      sessionStorage.setItem('deviceUniqueId', this.deviceUniqueId);
    }
    this.setupConnection(`${environment.webUrl}?deviceUniqueId=${this.deviceUniqueId}`);
  }
  setupConnection(host: any) {
    console.log('calling server to connect socket', host);
    socket = io(host, { transports: ['websocket'], reconnection: false });
    // Listening Events

    socket.on('connect', data => {
      console.log('connected to socket server with id:- ' + socket.id);
      this.connectUser();
    });
    socket.on('disconnect', error => {
      console.log('disconnect to socket:- ' + error);
      if (this.authService.isAuthenticate()) {
        this.setupConnection(`${environment.webUrl}?deviceUniqueId=${this.deviceUniqueId}`);
      }
    });
    socket.on('error', error => {
      console.log('error:- ' + error);
      if (this.authService.isAuthenticate()) {
        this.setupConnection(`${environment.webUrl}?deviceUniqueId=${this.deviceUniqueId}`);
      }
    });
    // Event Listner
    // TODO Remove once complete
    // socket.on('CONNECTED', (data: any) => {
    //   console.log('CONNECTED', data);
    // });

    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.CONNECT_HANDSHAKE_COMPLETE, (data: any) => {
      console.log('CONNECT_HANDSHAKE_COMPLETE', data);
      setTimeout(() => {
        this.initiatePing();
      }, 500);
    });

    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.DISCONNECT_HANDSHAKE_COMPLETE, (data: any) => {
      console.log('DISCONNECT_HANDSHAKE_COMPLETE', data);
      socket.disconnect();
      socket.off();
    });
    // TODO Remove once complete
    // socket.on('USER_CONNECTED', function (data) {
    //   console.log('USER_CONNECTED', data);
    // });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.USER_DISCONNECTED, function (data) {
      console.log('USER_DISCONNECTED', data);
    });

    socket.on('DISCONNECT', (data: any) => {
      console.log('Disconnect', data);
    });

    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_CREATE_ROOM, (data: any) => {
      console.log('ON_CREATE_ROOM', data);
      this.chatService.onCreateRoomChat(data);
    });

    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_ROOM_HISTORY, (data: any) => {
      console.log('ON_ROOM_HISTORY', data);
      this.chatService.setAllLatestChatGroup(data);
    });

    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_CHAT_ROOM, (data: any) => {
      console.log('OnChatRoom', data);
      if (data) {
        this.chatService.getLatestChatGroup(data);
      }
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_ROOM_MESSAGE, (data: any) => {
      console.log('ON_ROOM_MESSAGE', data);
      this.chatService.getLatestChatMessage(data);
      this.chatService.playAudioOnNewMessageInRoom(true);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_UPDATE_ROOM, (data: any) => {
      console.log('ON_UPDATE_ROOM', data);
      this.chatService.onUpdateRoomName(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_JOIN_ROOM, (data: any) => {
      console.log('ON_JOIN_ROOM', data);
      this.chatService.onJoinGroupByUser(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_MAKE_ADMIN, (data: any) => {
      console.log('ON_MAKE_ADMIN', data);
      this.chatService.onMakeAdminToUser(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_REMOVE_USER, (data: any) => {
      console.log('ON_REMOVE_USER', data);
      this.chatService.onRemoveUserToGroup(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_DELETE_ROOM, (data: any) => {
      console.log('ON_DELETE_ROOM', data);
      this.chatService.onDeleteGroupByAdmin(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_USER_DELETE_ROOM, (data: any) => {
      console.log('ON_USER_DELETE_ROOM', data);
      this.chatService.onDeleteGroupByUser(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_LEAVE_ROOM, (data: any) => {
      console.log('ON_LEAVE_ROOM', data);
      this.chatService.onLeaveGroupByUser(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_ARCHIVE_ROOM, (data: any) => {
      console.log('ON_ARCHIVE_ROOM', data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_UNARCHIVE_ROOM, (data: any) => {
      console.log('ON_UNARCHIVE_ROOM', data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_REACT_MESSAGE, (data: any) => {
      console.log('ON_REACT_MESSAGE', data);
      this.chatService.onReactToMsgAck(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_MESSAGE_DELETE, (data: any) => {
      console.log('ON_MESSAGE_DELETE', data);
      this.chatService.onMessageDelete(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_DEACTIVATE_USER, (data: any) => {
      console.log('ON_DEACTIVATE_USER', data);
      this.chatService.onDeactivateUser(data);
    });
    socket.on(messages.CHAT_CONFIG_EVENT_LISTENER.ON_MESSAGE_READ, (data: any) => {
      console.log('ON_MESSAGE_READ', data);
      this.chatService.onMessageRead(data);
    });
    socket.on('pong', (data: any) => {
      this.pingCount++;
      if (this.isLoaderFlag) {
        console.log('Received Pong! Hide Success', this.pingCount);
        this.isLoaderFlag = false;
        this.chatService.onChatPingEvent(true);
      }
      console.log(this.pingCount);
    });
  }
  connectUser() {
    let connectPayload = {
      userToken: sessionStorage.getItem('userId'),
      profilePicUrl: sessionStorage.getItem('imageUrl'),
      displayName: sessionStorage.getItem('fullName'),
      userName: sessionStorage.getItem('fullName'),
      firstName: sessionStorage.getItem('fullName'),
      lastName: sessionStorage.getItem('fullName'),
      emailAddress: sessionStorage.getItem('emailId'),
      deviceUniqueId: sessionStorage.getItem('deviceUniqueId'),
      senderId: ''
    };
    console.log('CONNECT', connectPayload);
    socket.emit(messages.CHAT_CONFIG_EVENT.RX_CONNECT, connectPayload);
  }
  // TODO Remove once complete
  // reconnectSocket() {
  //   this.isConnected = socket.connected;
  //   if (!this.isConnected) {
  //     console.log('call for Reconnect');
  //     socket.io.reconnect();
  //     setTimeout(() => {
  //       this.reconnectSocket();
  //     }, 2000);
  //   } else {
  //     console.log('connection done after reconnect attempt');
  //   }
  // }
  initiatePing() {
    socket.emit('ping');
    console.log('Emit Ping');
  }
  teardownConnection() {
    var disconnectPayload = {
      userToken: sessionStorage.getItem('userId'),
      senderId: sessionStorage.getItem('userId'),
      deviceUniqueId: sessionStorage.getItem('deviceUniqueId')
    };
    console.log('teardownConnection', disconnectPayload);
    socket.emit('DISCONNECT', disconnectPayload);
    socket.disconnect();
    socket.off();
  }

  createChatRoom(jsonObject) {
    console.log('createChatRoom', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.RX_CREATE_ROOM, jsonObject, ack => {
      console.log('Room Created Completed Successfully', ack);
      this.chatService.onChatRoomCreatedEvent(ack);
    });
  }
  getChatRoomMessagesHistory(jsonObject) {
    console.log('getChatRoomMessagesHistory', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.RX_ROOM_HISTORY, jsonObject);
  }
  chatRoomMessageSend(jsonObject) {
    console.log('chatRoomMessageSend', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.ROOM_MESSAGE, jsonObject, ack => {
      console.log('Chat Room Send Message Successfully', ack);
      this.chatService.setMessageSendAck(ack);
    });
  }
  getChatRoomList() {
    let jsonObject = {
      userToken: sessionStorage.getItem('userId'),
      deviceUniqueId: sessionStorage.getItem('deviceUniqueId')
    };
    console.log('getChatRoomList', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.RX_CHAT_ROOM, jsonObject);
  }
  updateChatRoomName(jsonObject) {
    console.log('updateChatRoomName', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.UPDATE_ROOM, jsonObject, ack => {
      console.log('updateChatRoomName Successfully', ack);
      this.chatService.onUpdateRoomNameAck(ack);
    });
  }
  addNewUserToChatRoom(jsonObject) {
    console.log('addNewUserToChatRoom', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.RX_JOIN_ROOM, jsonObject, ack => {
      console.log('addNewUserToChatRoom RX_JOIN_ROOM Successfully', ack);
      this.chatService.onJoinGroupByUserAck(ack);
    });
  }
  makeAdminToChatRoomUser(jsonObject) {
    console.log('makeAdminToChatRoomUser', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.MAKE_ADMIN, jsonObject, ack => {
      console.log('makeAdminToChatRoomUser MAKE_ADMIN Successfully', ack);
      this.chatService.onMakeAdminToUser(ack);
    });
  }
  removeUserFromGroup(jsonObject) {
    console.log('removeUserFromGroup', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.REMOVE_USER, jsonObject, ack => {
      console.log('removeUserFromGroup REMOVE_USER Successfully', ack);
      this.chatService.onRemoveUserToGroup(ack);
    });
  }
  deleteGroup(jsonObject, userRoleType) {
    console.log('deleteGroup', jsonObject, userRoleType);
    if (userRoleType === 'CREATOR') {
      socket.emit(messages.CHAT_CONFIG_EVENT.DELETE_ROOM, jsonObject, ack => {
        console.log('deleteGroup DELETE_ROOM Successfully', ack);
        this.chatService.onDeleteGroupByAdminAck(ack);
      });
    } else {
      socket.emit(messages.CHAT_CONFIG_EVENT.USER_DELETE_ROOM, jsonObject, ack => {
        console.log('deleteGroup USER_DELETE_ROOM Successfully', ack);
        this.chatService.onDeleteGroupByUserAck(ack);
      });
    }
  }
  leaveUserFromGroup(jsonObject) {
    console.log('leaveUserFromGroup', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.LEAVE_ROOM, jsonObject, ack => {
      console.log('leaveUserFromGroup LEAVE_ROOM Successfully', ack);
      this.chatService.onLeaveGroupByUserAck(ack);
    });
  }
  archiveGroupByUser(jsonObject) {
    console.log('archiveGroupByUser', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.ARCHIVE_ROOM, jsonObject, ack => {
      console.log('archiveGroupByUser ARCHIVE_ROOM Successfully', ack);
      this.chatService.onArchiveGroupByUserAck(ack);
    });
  }
  unArchiveGroupByUser(jsonObject) {
    console.log('unArchiveGroupByUser', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.UNARCHIVE_ROOM, jsonObject, ack => {
      console.log('unArchiveGroupByUser UNARCHIVE_ROOM Successfully', ack);
      this.chatService.onUnArchiveGroupByUserAck(ack);
    });
  }

  reactOnMessage(jsonObject) {
    console.log('reactOnMessage', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.REACT_MESSAGE, jsonObject, ack => {
      console.log('REACT_MESSAGE Successfully', ack);
      this.chatService.onReactToMsgAck(ack);
    });
  }

  deleteMessage(jsonObject) {
    console.log('deleteMessage', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.MESSAGE_DELETE, jsonObject, ack => {
      console.log('MESSAGE_DELETE Successfully', ack);
      this.chatService.onMessageDelete(ack);
    });
  }

  markAllMessagesRead(jsonObject) {
    console.log('markAllMessagesRead', jsonObject);
    socket.emit(messages.CHAT_CONFIG_EVENT.MESSAGE_READ, jsonObject, ack => {
      console.log('MESSAGE_READ Successfully', ack);
      this.chatService.onMessageRead(ack);
    });
  }
}
