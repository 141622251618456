// Acount
import { AccountModule } from '@account/account.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Env
import { environment } from '@environment/environment';
// Config
import { LayoutConfig } from '@layout/config/layout.config';
// Layout
import { LayoutModule } from '@layout/layout.module';
// Partials
import { PartialsModule } from '@layout/partials/partials.module';
// Layout Services
import {
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  LayoutUtilsService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from '@layout/services';
import { AuthService, TypesUtilsService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';
// Angular in memory
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Copmponents
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { MessagingService } from '../app/shared/services/messaging.service';
import { AppComponent } from './app.component';
import { rxStompConfig } from './rx-stomp.config';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    PartialsModule,
    SharedModule,
    AccountModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    LayoutModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  exports: [],

  providers: [
    AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    TypesUtilsService,
    LayoutUtilsService,
    MessagingService,
    {
      provide: InjectableRxStompConfig,
      useValue: rxStompConfig,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
