import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { Observable, Subject } from 'rxjs';
import { UserProfileModel } from './my-profile.model';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  subject: Subject<UserProfileModel> = new Subject();
  profileSubject: Subject<number> = new Subject();

  constructor(private readonly httpClient: HttpClient) {}

  getProfileSubject(): Observable<UserProfileModel> {
    return this.subject.asObservable();
  }

  setProfileSubject(profile: UserProfileModel) {
    this.subject.next(profile);
  }

  getResetProfileSubject(): Observable<number> {
    return this.profileSubject.asObservable();
  }

  setResetProfileSubject(subjectNumber: number) {
    this.profileSubject.next(subjectNumber);
  }

  getUserProfile() {
    return this.httpClient.get(URL.GET_USER_PROFILE);
  }

  updateProfilePicture(image: any): Observable<any> {
    return this.httpClient.post(URL.UPDATE_PROFILE_PICTURE, image);
  }

  fetchProfilePicture() {
    const emailId = sessionStorage.getItem('emailId');
    return this.httpClient.get(URL.FETCH_PROFILE_IMAGE + `/${emailId}`);
  }

  updateProfileData(profileData: UserProfileModel) {
    return this.httpClient.put(URL.GET_USER_PROFILE, profileData);
  }
  getPropertyByUser() {
    return this.httpClient.get(URL.COMPANY_PROPERTY_USERS + '/property');
  }
}
