import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
// Layout
import { LayoutConfigService, LayoutRefService } from '@layout/services';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
import { messages } from '@shared/constants/app.constants';
// Object-Path
import * as objectPath from 'object-path';
// HTML Class Service
import { HtmlClassService } from '../services/html-class.service';

@Component({
  selector: 'sfl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // Public properties
  menuHeaderDisplay: boolean;
  fluid: boolean;

  @ViewChild('sflHeader', { static: true }) sflHeader: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly layoutRefService: LayoutRefService,
    private readonly layoutConfigService: LayoutConfigService,
    public loader: LoadingBarService,
    public htmlClassService: HtmlClassService
  ) {
    // page progress bar percentage
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(messages.ROUTE_CONFIG_LOAD_START);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(messages.ROUTE_CONFIG_LOAD_END);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    });
  }

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    // get menu header display option
    this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

    // header width fluid
    this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';
  }

  ngAfterViewInit(): void {
    // keep header element in the service
    this.layoutRefService.addElement('header', this.sflHeader.nativeElement);
  }
}
