import { AccountService } from '@account/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardService } from '@entities/dashboard/dashboard.service';
import { environment } from '@environment/environment';
import { UserProfileModel } from '@layout/my-profile/my-profile.model';
import { MyProfileService } from '@layout/my-profile/my-profile.service';
import { PropertySelectionDialogComponent } from '@layout/partials/content/crud/property-selection-dialog/property-selection-dialog.component';
import { TermsConditionDialogComponent } from '@layout/partials/content/crud/terms-condition-dialog/terms-condition-dialog.component';
import { LayoutUtilsService, MenuAsideService, MessageType } from '@layout/services';
import { DataService } from '@layout/services/data.service';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { User } from '@shared/models';
import { UserModel } from '@shared/models/login.model';
import { UnreadNotificationCount } from '@shared/models/notification.model';
import { AuthService } from '@shared/services';
import { MessagingService } from '@shared/services/messaging.service';
import { Observable, Subscription } from 'rxjs';
import { BuildingService } from 'src/app/building/building.service';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';
import { ChatService } from 'src/app/chat/chat.service';
import { ChatConfigService } from 'src/app/chat/chat_config.service';
import { QuickPanelService } from '../../quick-panel/quick-panel.service';
import { ChatPanelComponent } from '../../chat-panel/chat-panel.component';

@Component({
  selector: 'sfl-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('openModalButton', { static: true }) openModalButton;
  @ViewChild('openModalButtonChat', { static: true }) openModalButtonChat;
  @ViewChild(ChatPanelComponent, { static: false }) chatPanelComponent: ChatPanelComponent;
  user$: Observable<User>;
  user: UserModel = new UserModel();
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge;
  @Input() icon;
  currentpassword: string;
  newpassword: string;
  confirmpassword: string;
  IMAGE_PATH = messages.DEFAULT_IMAGE_PATH;
  enableProfileDrawer = false;
  subscription = new Subscription();
  notificationCount: number;
  hidden = false;
  hasBackdrop = false;
  selectedProperty;
  isAdmin = true;
  properties = [];
  isDisableUser = false;
  isLoopVendor = false;
  userAccesList: any = [];
  isChatShow = environment.isRXChat;
  isChatStart = false;
  isChatStartEnv = environment.isRXChat;
  isChatPermission = false;
  unReadConversationCount: number;
  isChatConversationLoading = true;
  conversationList = [];
  constructor(
    private readonly router: Router,
    public readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly profileService: MyProfileService,
    private readonly notificationService: QuickPanelService,
    private readonly data: DataService,
    private readonly MenuAsideService: MenuAsideService,
    private readonly dahboardService: DashboardService,
    private readonly buildingService: BuildingService,
    private readonly messagingService: MessagingService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly chatConfigService: ChatConfigService,
    private readonly chatService: ChatService,
    private readonly accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.user.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.setUserInfo();
    this.getUnreadCounts();
    this.isLoopVendor = this.user.roles[0] === messages.ROLE_LOOP_VENDOR;
    if (this.user.roles[0] === 'ROLE_COMPANY_ADMIN' || this.user.roles[0] === 'ROLE_ADMIN' || this.user.roles[0] === 'ROLE_LOOP_VENDOR') {
      this.isAdmin = false;
      this.data.changeMessage('');
      this.isDisableUser = true;
    } else {
      this.getPropertyByUser();
    }
    this.authService.getDialogSubject().subscribe((res: boolean) => {
      this.hasBackdrop = res;
    });
    this.subscription.add(
      this.authService.getSubject().subscribe(() => {
        this.setImageInfo();
        this.changeDetection.markForCheck();
      })
    );
    this.subscription.add(
      this.dahboardService.getInactiveProeprtyData().subscribe(() => {
        this.getPropertyByUser();
      })
    );
    this.subscription.add(
      this.notificationService.getUnreadNotificationSubject().subscribe(() => {
        this.getUnreadCount();
      })
    );
    this.subscription.add(
      this.chatService.chatStartPingEvent.subscribe((msg: boolean) => {
        console.log('chat loader hide', msg);
        this.isChatStart = msg;
        this.chatConfigService.getChatRoomList();
      })
    );
    this.subscription.add(
      this.chatService.latestChatGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.unReadConversationCount = msg.filter(x => x.unreadMessageCount).length;
          this.isChatConversationLoading = false;
          this.conversationList = msg;
          this.chatService.getLatestChatGroup(null);
        }
      })
    );
    this.subscription.add(
      this.chatService.latestChatMessageSubscriber.subscribe((msg: any) => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.messages[0].roomId);
          if (selectedConversation > -1 && this.conversationList[selectedConversation].unreadMessageCount === 0 && msg.unreadMessageCount) {
            this.unReadConversationCount = this.unReadConversationCount + 1;
          }
        }
      })
    );
    this.subscription.add(
      this.chatService.onCreateRoomSubscriber.subscribe(msg => {
        if (msg) {
          console.log('onCreateRoomSubscriber', msg);
          this.conversationList = [...this.conversationList, msg];
        }
      })
    );
    this.subscription.add(
      this.chatService.onJoinGroup.subscribe((msg: any) => {
        if (msg) {
          console.log('onJoinGroup', msg);
          this.conversationList = [...this.conversationList, msg.roomMemberDetailDTO];
          this.chatService.onJoinGroupByUser(null);
        }
      })
    );

    this.subscription.add(
      this.chatService.onDeleteGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (
            selectedConversation > -1 &&
            this.conversationList[selectedConversation].unreadMessageCount > 0 &&
            msg.userToken === this.user.userId
          ) {
            this.unReadConversationCount = this.unReadConversationCount - 1;
          }
          this.conversationList = this.conversationList.filter(conversation => conversation.roomId !== msg.roomId);
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAckSubscriber.subscribe((msg: any) => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (selectedConversation > -1 && this.conversationList[selectedConversation].unreadMessageCount > 0) {
            this.unReadConversationCount = this.unReadConversationCount - 1;
          }
          this.conversationList = this.conversationList.filter(conversation => conversation.roomId !== msg.roomId);
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdmin.asObservable().subscribe((msg: any) => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (selectedConversation > -1 && this.conversationList[selectedConversation].unreadMessageCount > 0) {
            this.unReadConversationCount = this.unReadConversationCount - 1;
          }
          this.conversationList = this.conversationList.filter(conversation => conversation.roomId !== msg.roomId);
        }
      })
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdminAck.asObservable().subscribe((msg: any) => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (selectedConversation > -1 && this.conversationList[selectedConversation].unreadMessageCount > 0) {
            this.unReadConversationCount = this.unReadConversationCount - 1;
          }
          this.conversationList = this.conversationList.filter(conversation => conversation.roomId !== msg.roomId);
        }
      })
    );
    // need backend change to make it work
    this.subscription.add(
      this.chatService.onReadMessageAck.asObservable().subscribe(msg => {
        if (msg) {
          const selectedConversation = this.conversationList.findIndex(conversation => conversation.roomId === msg.roomId);
          if (selectedConversation > -1) {
            this.conversationList = [];
            this.chatConfigService.getChatRoomList();
          }
        }
      })
    );
    this.subscription.add(
      this.notificationService.getNotificationPropertySubject().subscribe((res: string) => {
        if (res) {
          const selectProperty = this.properties.filter(x => x.propertyId === res);
          if (selectProperty.length !== 0) {
            this.selectedProperty = selectProperty[0];
            this.onChangeProperty();
          }
        }
      })
    );
    this.getUnreadCount();
    if (environment.isRXChat) {
      setTimeout(() => {
        this.chatConfigService.connect();
      }, 0);
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.chatService.onChatFromTicket.asObservable().subscribe((msg: any) => {
        if (msg) {
          this.getChatHistory(true);
          this.chatService.onChatFromTicketDetails(null);
        }
      })
    );
  }

  setUserInfo() {
    this.user.imageUrl = sessionStorage.getItem('imageUrl');
    this.user.userId = sessionStorage.getItem('userId');
    const fullname = sessionStorage.getItem('fullName');
    //TODO set condition for temporary Once backend changed remove this condition
    if (fullname === 'null null') {
      sessionStorage.setItem('fullName', '');
    }
    this.user.fullName = sessionStorage.getItem('fullName');
    this.user.emailId = sessionStorage.getItem('emailId');
  }

  setImageInfo() {
    this.user.imageUrl = sessionStorage.getItem('imageUrl');
    this.user.fullName = sessionStorage.getItem('fullName');
  }

  logout() {
    this.subscription.add(
      this.messagingService.unSubscribeToken().subscribe((response: UserProfileModel) => {
        this.subscription.add(
          this.authService.logout().subscribe(
            (data: any) => {
              if (environment.isRXChat) {
                this.chatConfigService.teardownConnection();
              }
              this.data.changeMessage('');
              sessionStorage.clear();
              localStorage.clear();
              this.router.navigateByUrl('/auth/login');
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
            }
          )
        );
      })
    );
  }

  openDialog() {
    this.authService.setDialogSubject(true);
    this.dialog.open(ChangePasswordComponent, {
      width: '435px',
      disableClose: true,
      data: this.user.emailId
    });
  }

  openProfile(template: any) {
    this.enableProfileDrawer = true;
    template.toggle();
    this.subscription.add(
      this.profileService.getUserProfile().subscribe((response: UserProfileModel) => {
        this.profileService.setProfileSubject(response);
      })
    );
  }

  // To show unread count on bell icon
  getUnreadCount() {
    this.subscription.add(
      this.notificationService.getUnreadNotification().subscribe((res: UnreadNotificationCount) => {
        this.notificationCount = res.count;
        if (this.notificationCount === 0) {
          this.hidden = true;
        } else {
          this.hidden = false;
        }
      })
    );
  }

  // To set the history notification subject
  getNotificationHistory() {
    this.notificationService.setNotificationHistorySubject();
  }

  closeUserTemplate(template: any) {
    this.profileService.setResetProfileSubject(0);
    template.close();
  }
  onChangeProperty() {
    this.getUserAccess(this.selectedProperty);
    this.isAdmin = true;
    sessionStorage.setItem('propertyDetails', JSON.stringify(this.selectedProperty));
    this.isDisableUser = true;
    this.MenuAsideService.loadMenu(this.selectedProperty.userPosition);
    this.data.changeMessage(this.selectedProperty);
    this.router.navigateByUrl('dashboard');
  }
  getPropertyByUser() {
    this.isAdmin = false;
    this.subscription.add(
      this.profileService.getPropertyByUser().subscribe((response: any) => {
        this.properties = response.properties;
        let dialogRef = null;
        if (this.properties.length === 1) {
          this.selectedProperty = response.properties[0];
          this.onChangeProperty();
          return;
        }
        dialogRef = this.dialog.open(PropertySelectionDialogComponent, {
          data: this.properties,
          disableClose: true,
          width: messages.POPUP
        });
        dialogRef.afterClosed().subscribe(res => {
          this.authService.setDialogSubject(false);
          if (res) {
            this.selectedProperty = res;
            this.onChangeProperty();
          } else {
            this.logout();
          }
        });
      })
    );
  }
  getUserAccess(property) {
    this.subscription.add(
      this.buildingService.getCompanyPropertyUserId(property.companyPropertyId).subscribe(
        (res: any) => {
          this.userAccesList = res;
          sessionStorage.setItem('userAccess', JSON.stringify(this.userAccesList));
          this.MenuAsideService.loadMenu(null);
          // TODO Remove once confirm
          // this.subscription.add(
          //   this.buildingService.getPropertyUserFeature(29).subscribe((res: any) => {
          //   })
          // );
        },
        (addError: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(addError.error.message, MessageType.Create);
        }
      )
    );
  }
  getChatHistory(isFromTicketDetails = false) {
    if (this.isChatPermission) {
      this.openModalButton.nativeElement.click();
      this.chatService.onChatClickedEvent('History clicked!!');
      this.chatService.isConversationLoading = true;
      if (!isFromTicketDetails) {
        this.chatPanelComponent.openConversationList();
      }
    } else {
      this.isChatStart = false;
      this.subscription.add(
        this.authService.account().subscribe(
          (accountInfo: UserModel) => {
            if (accountInfo.chatAgreement) {
              this.isChatPermission = true;
              this.isChatStart = true;
              this.openModalButton.nativeElement.click();
              this.chatService.onChatClickedEvent('History clicked!!');
              this.chatService.isConversationLoading = true;
            } else {
              this.isChatStart = true;
              const dialogRef = this.dialog.open(TermsConditionDialogComponent, {
                data: 'CHAT',
                disableClose: true,
                width: '800px'
              });
              dialogRef.afterClosed().subscribe(res => {
                this.authService.setDialogSubject(false);
                if (res) {
                  this.isChatStart = false;
                  this.subscription.add(
                    this.accountService.addAcceptTermsAndCondition(`"CHAT"`).subscribe(
                      () => {
                        this.isChatPermission = true;
                        this.isChatStart = true;
                        this.openModalButton.nativeElement.click();
                        this.chatService.onChatClickedEvent('History clicked!!');
                        this.chatService.isConversationLoading = true;
                      },
                      (error: HttpErrorResponse) => {
                        this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
                      }
                    )
                  );
                } else {
                  this.layoutUtilsService.showActionNotification(ACTION_MESSAGES.TERMS_AND_CONDITION_CHAT, MessageType.Update);
                  this.isChatStart = true;
                }
              });
            }
          },
          (error: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
          }
        )
      );
    }
  }

  getUnreadCounts(): void {
    this.subscription.add(
      this.chatService.getUnreadChatCount().subscribe((res: UnreadNotificationCount) => {
        this.unReadConversationCount = res.count;
        this.isChatConversationLoading = false;
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
