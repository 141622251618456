import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { UnreadNotificationCount } from '@shared/models/notification.model';
import { TicketFilter, TicketList } from '@shared/models/tickets.model';
import { PageableQuery, createRequestOption } from '@shared/services/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  @Output() chatClickedEvent = new EventEmitter<string>();
  @Output() chatRoomCreatedEvent = new EventEmitter<string>();
  @Output() chatStartPingEvent = new EventEmitter<boolean>();
  isConversationLoading = true;
  public latestChatGroup = new BehaviorSubject('');
  latestChatGroupSubscriber = this.latestChatGroup.asObservable();

  public allChatMessages = new BehaviorSubject('');
  allChatMessageSubscriber = this.allChatMessages.asObservable();

  public messageSendAck = new BehaviorSubject('');
  messageSendAckSubscriber = this.messageSendAck.asObservable();

  public latestChatMessage = new BehaviorSubject('');
  latestChatMessageSubscriber = this.latestChatMessage.asObservable();

  public onCreateRoom = new BehaviorSubject('');
  onCreateRoomSubscriber = this.onCreateRoom.asObservable();

  public onUpdateRoom = new BehaviorSubject('');
  onUpdateRoomSubscriber = this.onUpdateRoom.asObservable();

  public onUpdateRoomAck = new BehaviorSubject('');
  onUpdateRoomAckSubscriber = this.onUpdateRoomAck.asObservable();

  public onMakeAdmin = new BehaviorSubject('');
  onMakeAdminSubscriber = this.onMakeAdmin.asObservable();

  public onRemoveUser = new BehaviorSubject('');
  onRemoveUserSubscriber = this.onRemoveUser.asObservable();

  public onDeleteGroup = new BehaviorSubject('');
  onDeleteGroupSubscriber = this.onDeleteGroup.asObservable();

  public onDeleteGroupAck = new BehaviorSubject('');
  onDeleteGroupAckSubscriber = this.onDeleteGroupAck.asObservable();

  public onLeaveGroup = new BehaviorSubject('');
  onLeaveGroupSubscriber = this.onLeaveGroup.asObservable();

  public onLeaveGroupAck = new BehaviorSubject('');
  onLeaveGroupAckSubscriber = this.onLeaveGroupAck.asObservable();

  public onArchiveGroupAck = new BehaviorSubject('');
  onArchiveGroupAckSubscriber = this.onArchiveGroupAck.asObservable();

  public onUnArchiveGroupAck = new BehaviorSubject('');
  onUnArchiveGroupAckSubscriber = this.onUnArchiveGroupAck.asObservable();

  public onJoinGroup = new BehaviorSubject('');
  public onJoinGroupAck = new BehaviorSubject('');

  public onDeleteGroupAdmin = new BehaviorSubject('');
  public onDeleteGroupAdminAck = new BehaviorSubject('');

  public onReactMessageAck = new BehaviorSubject(null);
  public onDeleteMessageAck = new BehaviorSubject(null);
  public onDeactivateUserAck = new BehaviorSubject(null);
  public onReadMessageAck = new BehaviorSubject(null);
  public onChatFromTicket = new BehaviorSubject(null);
  public playAudioOnNewMessage = new BehaviorSubject(null);

  private readonly propertyChatMessageListingSubject: Subject<any> = new Subject();
  // TODO Remove once done
  // public propertyChatMessage = new BehaviorSubject('');
  // propertyChatMessageSubscriber = this.propertyChatMessage.asObservable();

  // public ticketChatMessage = new BehaviorSubject('');
  // ticketChatMessageSubscriber = this.ticketChatMessage.asObservable();

  constructor(private readonly http: HttpClient) {}
  getUserByPropertyId(userId: any): Observable<any> {
    return this.http.get<any>(URL.GET_PROPERTY_USERS + `?companyPropertyId=${userId}`);
  }
  getUserByTicketId(ticketNumber: any): Observable<any> {
    return this.http.get<any>(URL.GET_TICKET_USER_CHAT + `?ticketNumber=${ticketNumber}`);
  }

  getPropertyUserByRoomId(roomId: any): Observable<any> {
    return this.http.get<any>(URL.GET_PROPERTY_USERS + `?roomId=${roomId}`);
  }
  getTicketUserByRoomId(roomId: any): Observable<any> {
    return this.http.get<any>(URL.GET_TICKET_USER_CHAT + `?roomId=${roomId}`);
  }

  getReactedUsers(clientMessageId: any, reaction: string): Observable<any> {
    return this.http.get<any>(URL.REACTED_USERS + `?clientMessageId=${clientMessageId}&messageReactionType=${reaction}`);
  }

  getSearchedGroups(page: number, size: number, searchedTerm: string): Observable<any> {
    return this.http.post<any>(URL.SEARCH_GROUPS + `?page=${page}&size=${size}&keyword=${searchedTerm}`, null);
  }

  getSearchedMessages(page: number, size: number, searchedTerm: string): Observable<any> {
    return this.http.post<any>(URL.SEARCH_MESSAGES + `?page=${page}&size=${size}&keyword=${searchedTerm}`, null);
  }

  muteUnmuteNotificationsForChat(muteNotification: boolean, roomId: number, userToken: string): Observable<void> {
    return this.http.put<void>(URL.MUTE_UNMUTE_NOTIFICATION + `?roomId=${roomId}&userToken=${userToken}&isMute=${muteNotification}`, null);
  }

  // POST API for getting ticket listing with filters
  getTicketByPropertyId(pageableObject: PageableQuery, ticketFilter: TicketFilter): Observable<TicketList> {
    return this.http.post<TicketList>(URL.ADD_TICKET + '/search', ticketFilter, {
      params: createRequestOption(pageableObject)
    });
  }
  getRoomMemberDetails(roomId: any): Observable<any> {
    return this.http.get<any>(URL.GET_ROOM_MEMBERS_DETAILS + `?roomId=${roomId}`);
  }

  getPropertyForChat(userId: any): Observable<any> {
    return this.http.get<any>(URL.GET_PROPERTY_CHAT);
  }

  getUnreadChatCount(): Observable<UnreadNotificationCount> {
    return this.http.get<UnreadNotificationCount>(URL.UNREAD_CHAT_COUNT);
  }
  getLatestChatGroup(message: string) {
    this.latestChatGroup.next(message);
  }

  setAllLatestChatGroup(message: any) {
    this.allChatMessages.next(message);
  }

  setMessageSendAck(message: string) {
    this.messageSendAck.next(message);
  }
  getLatestChatMessage(message: any) {
    this.latestChatMessage.next(message);
  }
  onCreateRoomChat(message: string) {
    this.onCreateRoom.next(message);
  }
  onUpdateRoomName(message: string) {
    this.onUpdateRoom.next(message);
  }
  onUpdateRoomNameAck(message: string) {
    this.onUpdateRoomAck.next(message);
  }

  onMakeAdminToUser(message: string) {
    this.onMakeAdmin.next(message);
  }
  onRemoveUserToGroup(message: any) {
    this.onRemoveUser.next(message);
  }
  onDeleteGroupByUser(message: string) {
    this.onDeleteGroup.next(message);
  }
  onDeleteGroupByUserAck(message: any) {
    this.onDeleteGroupAck.next(message);
  }
  onDeleteGroupByAdmin(message: string) {
    this.onDeleteGroupAdmin.next(message);
  }
  onDeleteGroupByAdminAck(message: any) {
    this.onDeleteGroupAdminAck.next(message);
  }
  onLeaveGroupByUser(message: any) {
    this.onLeaveGroup.next(message);
  }
  onLeaveGroupByUserAck(message: any) {
    this.onLeaveGroupAck.next(message);
  }
  onArchiveGroupByUserAck(message: string) {
    this.onArchiveGroupAck.next(message);
  }
  onUnArchiveGroupByUserAck(message: string) {
    this.onUnArchiveGroupAck.next(message);
  }
  onJoinGroupByUser(message: any) {
    this.onJoinGroup.next(message);
  }

  onJoinGroupByUserAck(message: any) {
    this.onJoinGroupAck.next(message);
  }

  onReactToMsgAck(message: any) {
    this.onReactMessageAck.next(message);
  }

  onMessageDelete(message: any) {
    this.onDeleteMessageAck.next(message);
  }

  onDeactivateUser(message: any) {
    this.onDeactivateUserAck.next(message);
  }

  playAudioOnNewMessageInRoom(shouldPlay: boolean) {
    this.playAudioOnNewMessage.next(shouldPlay);
  }

  onMessageRead(message: any) {
    this.onReadMessageAck.next(message);
  }
  onChatFromTicketDetails(message: any) {
    this.onChatFromTicket.next(message);
  }
  //  TODO Remove once done
  // setPropertyChatMessage(message: any) {
  //   this.propertyChatMessage.next(message);
  // }
  // setTicketChatMessage(message: any) {
  //   this.ticketChatMessage.next(message);
  // }
  onChatClickedEvent(msg: string) {
    this.chatClickedEvent.emit(msg);
  }

  onChatRoomCreatedEvent(msg: any) {
    this.chatRoomCreatedEvent.emit(msg);
  }

  onChatPingEvent(msg: boolean) {
    this.chatStartPingEvent.emit(msg);
  }
  uploadAttachment(userId, fileType: any, multipartFiles): Observable<any> {
    return this.http.post(`${URL.UPLOAD_ATTACHMENTS}/${userId}?type=${fileType}`, multipartFiles);
  }
}
