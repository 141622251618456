import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { ForgotPasswordModel, ResetPasswordModel } from '@shared/models/account.model';
import { UserModel } from '@shared/models/login.model';
import { MessagingService } from '@shared/services/messaging.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private readonly httpClient: HttpClient, private readonly messagingService: MessagingService) {}

  //POST call for forget password
  forgotPasswordInit(emailId: ForgotPasswordModel): Observable<ForgotPasswordModel> {
    return this.httpClient.post<ForgotPasswordModel>(URL.FORGET_PASSWORD_INIT, emailId);
  }

  //POST call for resetting the password of user.
  resetPassword(resetPasswordData: ResetPasswordModel): Observable<ResetPasswordModel> {
    return this.httpClient.post<ResetPasswordModel>(URL.RESET_PASSWORD_URL, resetPasswordData);
  }

  //POST call for resetting the password of user.
  addAcceptTermsAndCondition(resetPasswordData): Observable<any> {
    return this.httpClient.post<any>(URL.ACCEPT_TERMS_AND_CONDITION, resetPasswordData);
  }

  storeUserInfoInStorage(accountInfo: UserModel): void {
    sessionStorage.setItem('roles', JSON.stringify(accountInfo.roles));
    sessionStorage.setItem('imageUrl', accountInfo.imageUrl);
    sessionStorage.setItem('fullName', accountInfo.fullName);
    sessionStorage.setItem('emailId', accountInfo.emailId);
    sessionStorage.setItem('userId', accountInfo.userId);
    sessionStorage.setItem('agreement', accountInfo.agreement);
    sessionStorage.setItem('chatAgreement', accountInfo.chatAgreement);
  }

  storeToken(token: string): void {
    sessionStorage.setItem('token', JSON.stringify(token));
  }

  initNotificationService(): void {
    this.messagingService.requestPermission();
    this.messagingService.getFirebaseToken();
    this.messagingService.receiveMessage();
  }
}
