import { Pageable } from '@shared/services/utils';
import { State } from './login.model';

export class CleaningCompany {
  id: number;
  companyName: string;
  companyEmailId: string;
  accessToken: string;
  refreshToken: string;
  phoneNumber: string;
  fax: string;
  contactPerson: string;
  status: string;
  adminEmailId: string;
  note: string;
  emailId: string;
  address: Address = new Address();
  editStatus: string;
  adminFirstName: string;
  adminLastName: string;
  adminPhoneNumber: string;
  mobile: string;
  user: CleaningCompanyUser = new CleaningCompanyUser();
  billingUser: CleaningCompanyBillingUser = new CleaningCompanyBillingUser();
  companyDisplayName: string;

  clear(): void {
    this.companyName = '';
    this.companyEmailId = '';
    this.phoneNumber = '';
    this.contactPerson = '';
  }
}

export class Address {
  city: string;
  addressLine1: string;
  addressLine2: string;
  state: State[] = [];
  zipCode: string;
}

export class CleaningUser {
  address: Address = new Address();
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber: 0;
  roleName: string;
}

export class CleaningCompanyUser {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: number;
  adminPhoneNumber: number;
}

export class AdminCleaningUser {
  users: CleaningUser[] = [];
}

export class NonAssociate {
  default: boolean;
  emailId: string;
}

export class NonAssociateAdmin {
  candidates: NonAssociate[] = [];
}

export class CleaningPageableModel extends Pageable {
  content: CleaningCompany[] = [];
}

export class CleaningCompanyBillingUser {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: number;
}
