import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { LoopVendorPOC } from '@shared/models/loopVendorPOC.model';
import { SurveyPerformerDetails, SurveyTemplateContactedInfoDTO } from '@shared/models/openSurvey.model';
import { QrCode } from '@shared/models/qr-code.model';
import {
  Category,
  CategoryComment,
  NewCategoryComment,
  NewSentimentalComment,
  Sentiment,
  SentimentalComment
} from '@shared/models/ticket-ai.model';
import {
  AssigneeArray,
  Attachment,
  AttachmentList,
  CCPOCByProperty,
  CheckInInfo,
  MessageVM,
  Note,
  NoteList,
  NoteReaction,
  ObserverArray,
  ObserversAndAssignees,
  PMCPOCByProperty,
  PropertyArray,
  StandPOCByProperty,
  TagList,
  TicketFilter,
  TicketList,
  Tickets,
  UserReact
} from '@shared/models/tickets.model';
import { ManagerArray, User } from '@shared/models/user.model';
import { AttachmentParam, createRequestOption, NoteParam, PageableQuery } from '@shared/services/utils';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private readonly ticketListingSubject: Subject<number> = new Subject();
  private readonly editTicketSubject = new Subject<Tickets>();
  private readonly ticketSubject = new Subject<number>();
  private readonly noteListingSubject: Subject<number> = new Subject();
  constructor(private readonly http: HttpClient) {}

  // POST API for getting ticket listing with filters
  getAllTickets(pageableObject: PageableQuery, ticketFilter: TicketFilter): Observable<TicketList> {
    return this.http.post<TicketList>(URL.ADD_TICKET + '/search', ticketFilter, {
      params: createRequestOption(pageableObject)
    });
  }

  // Getter for listing subject
  getTicketListingSubject(): Observable<number> {
    return this.ticketListingSubject.asObservable();
  }

  // Setter for ticket listing subject
  setTicketListingSubject(listingData: number) {
    return this.ticketListingSubject.next(listingData);
  }

  getTicketById(ticketNumber: number): Observable<Tickets> {
    return this.http.get<Tickets>(URL.API_TICKET_URL + `/${ticketNumber}`);
  }

  // Getter for edit ticket data subject.
  getEditTicketSubject(): Observable<Tickets> {
    return this.editTicketSubject.asObservable();
  }

  // Setter for edit ticket data subject.
  setEditTicketSubject(ticketInfo: Tickets) {
    this.editTicketSubject.next(ticketInfo);
  }

  // GET API for retrieving all observers
  getAllObservers(propertyId: string): Observable<ObserverArray> {
    const propertyid = { propertyId: propertyId };
    return this.http.get<ObserverArray>(URL.GET_OBSERVER_LIST, {
      params: createRequestOption(propertyid)
    });
  }

  // GET API for get all observers and assigness list on listing page
  getAllObserversAndAssignees(): Observable<ObserversAndAssignees[]> {
    return this.http.get<ObserversAndAssignees[]>(URL.GET_ALL_OBSERVERS_AND_ASSIGNEES);
  }

  // GET API for retrieving all properties
  getAllProperty(): Observable<PropertyArray> {
    return this.http.get<PropertyArray>(URL.GET_PROPERTY_LIST);
  }

  // GET API for retrieving all assignees
  getAllAssignees(propertyId: string): Observable<AssigneeArray> {
    const propertyid = { propertyId: propertyId };
    return this.http.get<AssigneeArray>(URL.GET_ASSIGNEE_LIST, {
      params: createRequestOption(propertyid)
    });
  }

  // GET API for retrieving all PMC POC on basis of property
  getAllPOCByProperty(propertyId: string, ticketId: string): Observable<PMCPOCByProperty[]> {
    return this.http.post<PMCPOCByProperty[]>(URL.GET_PMC_POC_BY_PROPERTY + `/${propertyId}/` + 'pmc-poc', { ticketId: ticketId });
  }

  // POST API to upload image
  uploadImage(image: FormData, fileType: string): Observable<Attachment> {
    const filetype = { 'file-type': fileType };
    return this.http.post<Attachment>(URL.UPLOAD_ATTACHMENT, image, {
      params: createRequestOption(filetype)
    });
  }

  //TODO Once confirm i will remove this // GET API for retrieving all tags
  // getAllTags(propertyId: string): Observable<TagList> {
  //   return this.http.get<TagList>(URL.GET_TAG_LIST + `/by-property?propertyId=${propertyId}`);
  // }

  // POST API to create ticket
  addTicket(ticketData: Tickets): Observable<Tickets> {
    return this.http.post<Tickets>(URL.ADD_TICKET, ticketData);
  }

  // PUT API to update ticket
  updateTicket(ticketData: Tickets): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.ADD_TICKET, ticketData);
  }

  // POST API for adding a note
  addNote(noteData: Note): Observable<Note> {
    return this.http.post<Note>(URL.ADD_NOTE, noteData);
  }

  // GET API for ticket details.
  getTicket(ticketNumber): Observable<Tickets> {
    const params = new HttpParams().set('ticketNumber', ticketNumber);
    return this.http.get<Tickets>(URL.GET_USER_BY_TICKET_ID, { params });
  }

  // GET API for note listing
  getNoteList(ticketNumber: string, pageableObject: PageableQuery): Observable<NoteList> {
    return this.http.get<NoteList>(URL.GET_NOTE_LIST, {
      params: createRequestOption(this.createNoteParams(pageableObject, ticketNumber))
    });
  }

  // create pageable object
  createNoteParams(pageableObject, ticketNumber): NoteParam {
    const noteParam = new NoteParam();
    noteParam.ticketNumber = ticketNumber;
    noteParam.fields = pageableObject.fields;
    noteParam.page = pageableObject.page;
    noteParam.size = pageableObject.size;
    noteParam.sort = pageableObject.sort;
    return noteParam;
  }

  // GET API for getting all attachments
  getAttachments(noteId: number, pageableObject: PageableQuery): Observable<AttachmentList> {
    return this.http.get<AttachmentList>(URL.GET_ATTACHMENT_LIST, {
      params: createRequestOption(this.createAttachmentParams(noteId, pageableObject))
    });
  }

  // create pageable object
  createAttachmentParams(noteId: number, pageableObject): AttachmentParam {
    const attachmentParam = new AttachmentParam();
    attachmentParam.noteId = noteId;
    attachmentParam.fields = pageableObject.fields;
    attachmentParam.page = pageableObject.page;
    attachmentParam.size = pageableObject.size;
    attachmentParam.sort = pageableObject.sort;
    return attachmentParam;
  }

  // getter form updated data
  getTicketSubject(): Observable<number> {
    return this.ticketSubject.asObservable();
  }

  // setter from updated data
  setTicketSubject(ticketData: number) {
    return this.ticketSubject.next(ticketData);
  }

  // Getter for note listing
  getNoteListingSubject(): Observable<number> {
    return this.noteListingSubject.asObservable();
  }

  // Setter for note listing
  setNoteListingSubject(listingData: number) {
    this.noteListingSubject.next(listingData);
  }

  // PUT API to update ticket notification
  updateNotificationStatus(ticketId: string, firebaseToken: string): Observable<Tickets> {
    return this.http.put<Tickets>(URL.ADD_TICKET + `/${ticketId}/${firebaseToken}/toggle-notification`, {});
  }

  // PUT API to update the status of ticket
  changeStatus(ticketId: string, status: string): Observable<MessageVM> {
    const changeStatus = { status: status };
    return this.http.put<MessageVM>(
      URL.CHANGE_TICKET_STATUS + `${ticketId}`,
      {},
      {
        params: createRequestOption(changeStatus)
      }
    );
  }

  // GET API for retrieving all CC POC on basis of property
  getAllCCPOCByProperty(propertyId: string, ticketId: string): Observable<CCPOCByProperty[]> {
    return this.http.post<CCPOCByProperty[]>(URL.GET_PMC_POC_BY_PROPERTY + `/${propertyId}/` + 'cc-poc', { ticketId: ticketId });
  }

  // GET API for retrieving all stand POC on basis of property
  getAllStandPOCByProperty(propertyId: string, ticketId: string): Observable<StandPOCByProperty[]> {
    return this.http.post<StandPOCByProperty[]>(URL.GET_PMC_POC_BY_PROPERTY + `/${propertyId}/` + 'stand-poc', { ticketId: ticketId });
  }
  //TO DO Once confrim i will remove this// Get Property POC for Ticket
  // getPropertyPOCByPropertyId(propertyId: string): Observable<any[]> {
  //   return this.http.post<any[]>(URL.ADD_TICKET + `/${propertyId}/property-poc`, {});
  // }

  //TO DO Once confirm i will remove this // Get LoopVendor for Ticket
  // getLoopVendorByPropertyId(propertyId: string): Observable<LoopVendor[]> {
  //   return this.http.get<LoopVendor[]>(URL.LOOP_VENDORS + `/list?propertyId=${propertyId}`);
  // }
  // Get LoopVendorPOC for Ticket
  getLoopVendorPOCByPropertyId(loopVendorID: string): Observable<LoopVendorPOC[]> {
    return this.http.get<LoopVendorPOC[]>(URL.LOOP_VENDORS_POC + `/list?loopVendorId=${loopVendorID}`);
  }
  // Get Performer details by id
  getPrformerDetailsById(ticketId: string): Observable<SurveyPerformerDetails> {
    return this.http.get<SurveyPerformerDetails>(URL.GET_PERFORMER_INFO_BY_TICKET_ID + `?ticketId=${ticketId}`);
  }
  // Get Performer contact by
  getSurveyPerformerInfoContactedBy(surveyContactedInfo: SurveyTemplateContactedInfoDTO): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.ADD_SURVEY_PERFORMER_DESCRIPTION, surveyContactedInfo);
  }
  // Get Property Manager for Ticket
  getPropertyManagerByPropertyId(propertyId: string): Observable<ManagerArray> {
    return this.http.post<ManagerArray>(URL.ADD_TICKET + `/${propertyId}/manager`, {});
  }
  // Get profile details by email
  getProfileDetailsbyEmail(email: string): Observable<SurveyPerformerDetails> {
    return this.http.get<SurveyPerformerDetails>(URL.PROFILE_BY_EMAIL + `/${email}`);
  }
  // Get Checkin by Ticket ID
  getCheckinsbyId(ticketId: string): Observable<CheckInInfo[]> {
    return this.http.get<CheckInInfo[]>(URL.CHECKINS + `?ticketId=${ticketId}`);
  }
  // New API from companyPropertyId get POC
  getPropertyUserByCompanyPropertyId(companyPropertyId: number, companyPropertyUserType: string): Observable<User[]> {
    return this.http.get<User[]>(URL.COMPANY_PROPERTY_USERS + `/detail/${companyPropertyId}/type/${companyPropertyUserType}`);
  }
  // New API from companyPropertyId get LoopVendor for Ticket
  getLoopVendorByCompanyPropertyId(companyPropertyId: string, companyPropertyUserType: string): Observable<User[]> {
    return this.http.get<User[]>(URL.COMPANY_PROPERTIES + `/${companyPropertyId}/type/${companyPropertyUserType}`);
  }
  // New API from companyPropertyId get API for retrieving all tags
  getAllTagsByCompanyPropertyId(companyPropertyId: number): Observable<TagList> {
    return this.http.get<TagList>(URL.GET_TAG_LIST + `/company-property/${companyPropertyId}`);
  }
  getQrCodeUrlByQrCodeId(qrCodeId: string): Observable<QrCode> {
    return this.http.get<QrCode>(URL.GET_QRCODE + `/url?qrCodeId=${qrCodeId}`);
  }
  reactOnNoteId(noteId: number, reactionName: string): Observable<NoteReaction> {
    return this.http.put<NoteReaction>(URL.NOTE_REACTION + `/react?noteId=${noteId}&reaction=${reactionName}`, {});
  }
  getUsersForReactionOnNote(noteId: number, reactionName: string): Observable<UserReact[]> {
    return this.http.get<UserReact[]>(URL.NOTE_REACTION + `?noteId=${noteId}&reaction=${reactionName}`, {});
  }
  // post note seen for Ticket
  addViewNote(ticketId: string): Observable<ManagerArray> {
    return this.http.post<ManagerArray>(URL.ADD_TICKET + `/add-view-note?ticketNumber=${ticketId}`, {});
  }

  //GET API for sentiment
  getTicketSentiment(ticketId: string): Observable<Sentiment> {
    return this.http.get<Sentiment>(URL.GET_SENTIMENTS + `/${ticketId}`);
  }

  //POST API for sentiment comments
  addSentimentComment(comment: NewSentimentalComment): Observable<SentimentalComment> {
    return this.http.post<SentimentalComment>(URL.COMMENTS, comment);
  }

  //PUT API for sentiment comments
  editSentimentComment(comment: NewSentimentalComment): Observable<SentimentalComment> {
    return this.http.put<SentimentalComment>(URL.COMMENTS, comment);
  }

  //GET API for categories
  getTicketCategory(ticketId: string): Observable<Category> {
    return this.http.get<Category>(URL.GET_CATEGORIES + `/${ticketId}`);
  }

  //POST API for category comments
  addCategoryComment(comment: NewCategoryComment): Observable<CategoryComment> {
    return this.http.post<CategoryComment>(URL.COMMENT, comment);
  }

  //PUT API for category comments
  editCategoryComment(comment: NewCategoryComment): Observable<CategoryComment> {
    return this.http.put<CategoryComment>(URL.COMMENT, comment);
  }
}
