import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sfl-cart',
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit, AfterViewInit {
  @Input() icon = 'flaticon2-shopping-cart-1';
  @Input() iconType: '' | 'brand';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  constructor() {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
