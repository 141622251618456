import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards';
import { AuthService, InterceptService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';
import { AccountComponent } from './account.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, MatButtonModule, RouterModule.forChild(routes), SharedModule],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }
  ],
  exports: [AccountComponent],
  declarations: [AccountComponent, LoginComponent, ForgotPasswordComponent, AuthNoticeComponent, ResetPasswordComponent]
})
export class AccountModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AccountModule,
      providers: [AuthService, AuthGuard]
    };
  }
}
