import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
// import { LayoutConfigModel } from '@shared/models';

@Injectable()
export class LayoutConfigService {
  onConfigUpdated$: Subject<LayoutConfigModel>;
  layoutConfig: LayoutConfigModel;

  constructor() {
    this.onConfigUpdated$ = new Subject();
  }

  saveConfig(layoutConfig: LayoutConfigModel): void {
    if (layoutConfig) {
      sessionStorage.setItem('layoutConfig', JSON.stringify(layoutConfig));
    }
  }

  getSavedConfig(): LayoutConfigModel {
    const config = sessionStorage.getItem('layoutConfig');
    try {
      return JSON.parse(config);
    } catch (e) {}
  }

  resetConfig(): void {
    sessionStorage.removeItem('layoutConfig');
  }

  getConfig(path?: string): LayoutConfigModel | any {
    this.layoutConfig = this.getSavedConfig();
    if (path) {
      // if path is specified, get the value within object
      return objectPath.get(this.layoutConfig, path);
    }

    return this.layoutConfig;
  }

  setConfig(value: any, save?: boolean): void {
    this.layoutConfig = merge(this.layoutConfig, value);

    if (save) {
      this.saveConfig(this.layoutConfig);
    }

    // fire off an event that all subscribers will listen
    this.onConfigUpdated$.next(this.layoutConfig);
  }

  getLogo(): string {
    const menuAsideLeftSkin = objectPath.get(this.layoutConfig, 'brand.self.skin');
    // set brand logo
    const logoObject = objectPath.get(this.layoutConfig, 'self.logo');

    let logo;
    if (typeof logoObject === 'string') {
      logo = logoObject;
    }
    if (typeof logoObject === 'object') {
      logo = objectPath.get(logoObject, menuAsideLeftSkin + '');
    }
    if (typeof logo === 'undefined') {
      try {
        const logos = objectPath.get(this.layoutConfig, 'self.logo');
        logo = logos[Object.keys(logos)[0]];
      } catch (e) {}
    }
    return logo;
  }

  getStickyLogo(): string {
    let logo = objectPath.get(this.layoutConfig, 'self.logo.sticky');
    if (typeof logo === 'undefined') {
      logo = this.getLogo();
    }
    return logo + '';
  }

  loadConfigs(config: LayoutConfigModel) {
    this.layoutConfig = this.getSavedConfig();
    // use saved config as priority, or load new config if demo does not matched
    if (!this.layoutConfig || objectPath.get(this.layoutConfig, 'demo') !== config.demo) {
      this.layoutConfig = config;
    }
    this.saveConfig(this.layoutConfig);
  }

  reloadConfigs(): LayoutConfigModel {
    this.layoutConfig = this.getSavedConfig();
    this.onConfigUpdated$.next(this.layoutConfig);
    return this.layoutConfig;
  }
}

export interface LayoutConfigModel {
  demo: string;
  self: {
    layout?: string;
    body?: {
      'background-image'?: string;
      class?: string;
      'background-position'?: string;
      'background-size'?: string;
    };
    logo: any | string;
  };
  portlet?: {
    sticky: {
      offset: number;
    };
  };
  loader: {
    enabled: boolean;
    type?: string | 'default' | 'spinner-message' | 'spinner-logo';
    logo?: string;
    message?: string;
  };
  colors: {
    state?: any;
    base: {
      label: string[];
      shape: string[];
    };
  };
  width?: string;
  header: {
    self: {
      skin?: string;
      width?: string;
      fixed: {
        desktop: any;
        mobile: boolean;
      };
    };
    // not implemented yet
    topbar?: {
      self?: {
        width?: string;
      };
      search?: {
        display: boolean;
        layout: 'offcanvas' | 'dropdown';
        dropdown?: {
          style: 'light' | 'dark';
        };
      };
      notifications?: {
        display: boolean;
        layout: 'offcanvas' | 'dropdown';
        dropdown: {
          style: 'light' | 'dark';
        };
      };
      'quick-actions'?: {
        display: boolean;
        layout: 'offcanvas' | 'dropdown';
        dropdown: {
          style: 'light' | 'dark';
        };
      };
      user?: {
        display: boolean;
        layout: 'offcanvas' | 'dropdown';
        dropdown: {
          style: 'light' | 'dark';
        };
      };
      languages?: {
        display: boolean;
      };
      cart?: {
        display: boolean;
      };
      'my-cart'?: any;
      'quick-panel'?: {
        display: boolean;
      };
    };
    search?: {
      display: boolean;
    };
    menu?: {
      self: {
        display: boolean;
        layout?: string;
        'root-arrow'?: boolean;
        width?: string;
      };
      desktop: {
        arrow: boolean;
        toggle: string;
        submenu: {
          skin?: string;
          arrow: boolean;
        };
      };
      mobile: {
        submenu: {
          skin: string;
          accordion: boolean;
        };
      };
    };
  };
  brand?: {
    self: {
      skin: string;
    };
  };
  aside?: {
    self: {
      skin?: string;
      display: boolean;
      fixed?: boolean | any;
      minimize?: {
        toggle: boolean;
        default: boolean;
      };
    };
    footer?: {
      self: {
        display: boolean;
      };
    };
    menu: {
      'root-arrow'?: boolean;
      dropdown: boolean;
      scroll: boolean;
      submenu: {
        accordion: boolean;
        dropdown: {
          arrow: boolean;
          'hover-timeout': number;
        };
      };
    };
  };
  'aside-secondary'?: {
    self: {
      display: boolean;
      layout?: string;
      expanded?: boolean;
    };
  };
  subheader?: {
    display: boolean;
    fixed?: boolean;
    width?: string;
    layout?: string;
    style?: 'light' | 'solid' | 'transparent';
    daterangepicker?: {
      display: boolean;
    };
  };
  content?: any;
  footer?: {
    self?: any;
  };
  'quick-panel'?: {
    display?: boolean;
  };
}
