import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { messages } from '@shared/constants/app.constants';
import { URL } from '@shared/constants/url.constant';
import { User } from '@shared/models';
import { RequestModel, ResponseModel, State, UserModel } from '@shared/models/login.model';
import { Ticket } from '@shared/models/ticket.model';
import { MessageVM } from '@shared/models/tickets.model';
import { DeleteUserModel, PropertyArray, UserPageableModel } from '@shared/models/user.model';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PageableQuery, UserPageableQuery, createRequestOption } from './utils';
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly http: HttpClient) {}

  private readonly subject = new Subject<number>();
  private readonly userSubject = new Subject<number>();
  private readonly usermanagementUserSubject = new Subject<User>();
  private readonly editUserSubject = new Subject<User>();
  private readonly dialogStatus = new Subject<boolean>();
  public redirectChange = new Subject<Ticket>();

  getSubject(): Observable<number> {
    return this.subject.asObservable();
  }

  setSubject(data: number) {
    this.subject.next(data);
  }

  setRedirectSubject() {
    this.redirectChange.next();
  }

  // POST request for login.
  login(loginRequest: RequestModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(URL.LOGIN_URL, loginRequest);
  }
  // POST request for login.
  logout(): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${URL.LOGOUT_URL}`, null);
  }
  isAuthenticate() {
    const token = JSON.parse(sessionStorage.getItem('token'));
    if (token != null) {
      return true;
    }
  }

  // GET request for getting basic account info after login of user.
  account(): Observable<UserModel> {
    return this.http.get<UserModel>(URL.ACCOUNT_INFO);
  }

  // GET request for fetching list of all states.
  getStates(): Observable<State[]> {
    return this.http.get<State[]>(URL.GET_STATES);
  }

  getUserSubject(): Observable<number> {
    return this.userSubject.asObservable();
  }

  setUserSubject(userData: number) {
    return this.userSubject.next(userData);
  }

  getUserManagementUserSubject(): Observable<User> {
    return this.usermanagementUserSubject.asObservable();
  }

  setUserManagementUserSubject(userData) {
    return this.usermanagementUserSubject.next(userData);
  }

  register(user: User): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set(messages.CONTENT_TYPE, messages.APPLICATION_JSON);
    return this.http.post<User>(URL.API_USERS_URL, user, { headers: httpHeaders }).pipe(
      map((res: User) => {
        return res;
      }),
    );
  }

  getEditUserSubject(): Observable<User> {
    return this.editUserSubject.asObservable();
  }

  setEditUserSubject(editUserInfo: User) {
    return this.editUserSubject.next(editUserInfo);
  }

  /*
   * Submit forgot password request
   */
  public requestPassword(email: string): Observable<any> {
    return this.http.get(URL.API_USERS_URL + '/forgot?=' + email).pipe(catchError(this.handleError('forgot-password', [])));
  }

  getAllUsers(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.ADD_USER, {
      params: createRequestOption(pageableObject),
    });
  }
  getAllPropertyUsers(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.PROPERTY_USER + '/all', {
      params: createRequestOption(pageableObject),
    });
  }

  // GET API to fetch user list as per company.
  getUserByCompany(pageableObject: PageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.FETCH_USER_BY_COMPANY, {
      params: createRequestOption(pageableObject),
    });
  }

  // GET API for user details.
  getUserByEmail(emailId: string): Observable<User> {
    return this.http.get<User>(URL.GET_USER_DATA_BY_EMAIL + `${emailId}/info`);
  }

  // DELETE => delete the user from the server
  deleteUser(emailId: DeleteUserModel) {
    return this.http.request('delete', URL.ADD_USER, { body: emailId });
  }

  // UPDATE => PUT: update the user on the server
  updateUser(user: User, url: string): Observable<MessageVM> {
    return this.http.put<MessageVM>(url, user);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User, url: string): Observable<MessageVM> {
    return this.http.post<MessageVM>(url, user);
  }

  // To set that dialog box is open
  setDialogSubject(open: boolean) {
    this.dialogStatus.next(open);
  }

  // To get that whether dialog box is open
  getDialogSubject(): Observable<boolean> {
    return this.dialogStatus.asObservable();
  }

  // To integrate Google API key at initialize of app
  initializeGeolocationScript() {
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.setAttribute('src', environment.geoLocationUrl + '&key=' + environment.geoLocationApiKey);
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'geolocation');
  }

  // To provide event for google analytics
  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      return of(result);
    };
  }

  // GET API for retrieving all properties
  getAllProperty(): Observable<PropertyArray> {
    return this.http.get<PropertyArray>(URL.GET_PROPERTY_LIST);
  }

  generateUserProfileQrCode(isGenerate): Observable<any> {
    return this.http.get<any>(`${URL.GENERATE_REMOVE_USER_PROFILE_QR_CODE}?profileQrCode=${isGenerate}`);
  }
}
