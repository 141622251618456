import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { QuickPanelService } from '@layout/partials/layout/quick-panel/quick-panel.service';
import { URL } from '@shared/constants/url.constant';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  subscriptions = new Subscription();

  constructor(
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly httpClient: HttpClient,
    private readonly notificationService: QuickPanelService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging: any) => {
      _messaging.onMessage(payload => {
        this.notificationService.setUnreadNotificationSubject(0);
        const userEmail = sessionStorage.getItem('emailId');
        if (payload.data.userEmail !== userEmail) {
          this.currentMessage.next(payload);
        }
      });
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   */
  requestPermission() {
    this.subscriptions.add(
      this.angularFireMessaging.requestToken.subscribe(
        token => {},
        err => {
          console.error('Unable to get permission to notify.', err);
        }
      )
    );
  }

  getFirebaseToken() {
    this.subscriptions.add(
      this.angularFireMessaging.getToken.subscribe(
        token => {
          sessionStorage.setItem('registrationToken', token);
          this.subscribeToken(token).subscribe();
          console.log(token);
        },
        err => {
          console.error('Unable to get permission to notify.', err);
        }
      )
    );
  }

  // To subscribe firebase token
  subscribeToken(subscribeVM: string) {
    return this.httpClient.post(URL.SUBSCRIBE_TO_TOPIC, { token: subscribeVM });
  }

  // To unSubscribe firebase token
  unSubscribeToken() {
    const subscribeVM = sessionStorage.getItem('registrationToken');
    return this.httpClient.post(URL.UNSUBSCRIBE_TO_TOPIC, { token: subscribeVM });
  }
  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.subscriptions.add(
      this.angularFireMessaging.messages.subscribe(
        payload => {
          // Will remove this console.log() later
          console.log('new message received. ', payload);
          this.notificationService.setUnreadNotificationSubject(0);
          this.currentMessage.next(payload);
        },
        () => {}
      )
    );
  }
}
