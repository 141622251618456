import { Pageable } from '@shared/services/utils';
export class LoopVendorPOC {
  id: number;
  loopVendorId: number;
  emailId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  defaultVendorPOC: boolean;
  propertyId: string;
}

export class LoopVendorPOCPageableModel extends Pageable {
  content: LoopVendorPOC[] = [];
}
