import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private readonly layoutUtilsService: LayoutUtilsService) {}

  loading = false;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = JSON.parse(sessionStorage.getItem('token'));
    try {
      if (!token) {
        if (request.url.includes('upload-attachments')) {
          request = request.clone({
            headers: request.headers
          });
        } else {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json')
          });
        }
      } else {
        if (
          request.url.includes('import') ||
          request.url.includes('upload-image') ||
          request.url.includes('upload-attachments') ||
          request.url.includes('qr-codes/send-image') ||
          request.url.includes('upload-company-logo') ||
          request.url.includes('chat/files/sync')
        ) {
          request = request.clone({
            headers: request.headers.append('Authorization', 'Bearer ' + token)
          });
        } else {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json').append('Authorization', 'Bearer ' + token)
          });
        }
      }
    } catch (error) {}

    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          if (response instanceof HttpResponse) {
            this.loading = false;
          }
          return response;
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          // TODO: Future use
          // if (error.status === 401 && !request.url.includes('api/authenticate')) {
          // this.router.navigateByUrl(messages.ROUTE_LOGIN);
          // sessionStorage.clear();
          // localStorage.clear();
          // this.layoutUtilsService.showActionNotification(
          // messages.ROLE_CHANGE,
          // MessageType.Create
          // );
          // }
          if (error.status === messages.INTERNAL_SERVER_ERROR) {
            this.layoutUtilsService.showActionNotification(messages.SERVER_DOWN, MessageType.Create);
          }
        }
      )
    );
  }
}
