import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { messages } from '@shared/constants/app.constants';
@Directive({
  selector: '[sflUserFeature]'
})
export class UserFeatureDirective implements OnInit {
  @Input("moduleType") moduleType: string;
  @Input("accessType") accessType: string;
  roles: string[] = [];
  featureList;
  css ='pointer-events'
  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.roles = JSON.parse(sessionStorage.getItem('roles'));
    if (this.roles[0] === messages.ROLE_COMPANY_ADMIN || this.roles[0] === messages.ROLE_LOOP_VENDOR) {
      if (this.moduleType === "VIEW_SURVEY_PERFORMER_DETAILS" && this.roles[0] === messages.ROLE_LOOP_VENDOR) {
        this.elementRef.nativeElement.style.display = "none";
      }
      return
    }
    this.featureList = JSON.parse(sessionStorage.getItem('userAccess'));
    this.elementRef.nativeElement.style.display = "none";
    this.checkAccess();
  }
  checkAccess() {
    const accessControls: any = this.featureList;
    const module: any = accessControls.find(access => access.name === this.moduleType);
    if (this.moduleType !== 'CONTACT_SURVEY_PERFORMER') {
      this.elementRef.nativeElement.style.display = module.status ? "block" : "none";
    } else {
      this.elementRef.nativeElement.style.display =  "block";
      this.elementRef.nativeElement.style['pointer-events'] = module.status ? 'all' : 'none';
    }
    
  }
}
