import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private readonly http: HttpClient) {}
  //This method is for get UUID for RXA
  getIdForRedirectToRXA(item: string): Observable<any> {
    const isRestRequest = item === 'analytics';
    return this.http.get<any>(URL.GET_UUID_FOR_RXA + `?isRestRequest=${isRestRequest}`);
  }
  //This method is for get UUID for RXA
  getTokenForRedirectFromRXA(uuid: string): Observable<string> {
    return this.http.get(URL.GET_TOKEN_FOR_RX + `?uuid=${uuid}`, {
      responseType: 'text',
    });
  }
}
