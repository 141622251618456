import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '@shared/constants/url.constant';
import { NotificationContent, NotificationDelete, UnreadNotificationCount } from '@shared/models/notification.model';
import { MessageVM } from '@shared/models/tickets.model';
import { NotificationPageableQuery } from '@shared/services/utils';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from '../../../../shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class QuickPanelService {
  unreadNotificationSubject: Subject<number> = new Subject();
  NotificationPropertySubject: Subject<string> = new Subject();
  notificationHistorySubject = new Subject();
  constructor(private readonly http: HttpClient) {}

  // Get list of notification history
  getNotificationListing(pageableObject: NotificationPageableQuery, propertyId): Observable<NotificationContent> {
    return this.http.get<NotificationContent>(URL.GET_NOTIFICATION_LISTING + '?propertyId=' + propertyId, {
      params: createRequestOption(pageableObject)
    });
  }

  // API for single notification read
  readSingleNotification(id: string) {
    return this.http.put(URL.READ_SINGLE_NOTIFICATION + `/${id}`, {});
  }

  // API for notification count
  getUnreadNotification(): Observable<UnreadNotificationCount> {
    return this.http.get<UnreadNotificationCount>(URL.GET_UNREAD_NOTIFICATION_COUNT);
  }

  // API for notification marked all as read
  markAllAsRead(propertyId) {
    return this.http.put(URL.MARK_ALL_AS_READ + `?propertyId=${propertyId}`, {});
  }

  // GET subject for count to update on reading notification
  getUnreadNotificationSubject(): Observable<number> {
    return this.unreadNotificationSubject.asObservable();
  }

  // SET subject for count to update on reading notification
  setUnreadNotificationSubject(countData: number) {
    this.unreadNotificationSubject.next(countData);
  }

  // GET subject for count to update on reading notification
  getNotificationPropertySubject(): Observable<string> {
    return this.NotificationPropertySubject.asObservable();
  }

  // SET subject for count to update on reading notification
  setNotificationPropertySubject(propertyId: string) {
    this.NotificationPropertySubject.next(propertyId);
  }

  // GET subject for count to update on reading notification
  getNotificationHistorySubject() {
    return this.notificationHistorySubject.asObservable();
  }

  // SET subject for count to update on reading notification
  setNotificationHistorySubject() {
    this.notificationHistorySubject.next();
  }
  getPropertyByUser() {
    return this.http.get(URL.GET_PROPERTY_LIST);
  }
  // DELETE => API for notification delete
  deleteNotification(notificationArray: NotificationDelete): Observable<MessageVM> {
    return this.http.request<MessageVM>('delete', URL.GET_NOTIFICATION_LISTING, { body: notificationArray });
  }
  //Read Notification for Ticket
  notificationReadTicket(redirectionId: string) {
    const notificationType = 'TICKET';
    return this.http.put(
      URL.GET_NOTIFICATION_LISTING + '/mark-read?redirectionId=' + redirectionId,
      {},
      { params: createRequestOption({ notificationType }) }
    );
  }
  //Read Notification
  notificationRead(redirectionId: string) {
    return this.http.put(URL.GET_NOTIFICATION_LISTING + '/mark-read?redirectionId=' + redirectionId, {});
  }
}
