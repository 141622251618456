import { Pageable } from '@shared/services/utils';

export class Notification {
  title: string;
  body: string;
  read: boolean;
  notificationId: string;
  createdDate: string;
  displayDate: string;
  redirectionId: string;
  type: string;
  propertyId: string;
  propertyName: string;
}

export class NotificationContent extends Pageable {
  content: Notification[] = [];
}

export class UnreadNotificationCount {
  count: number;
}

export class NotificationDelete {
  notificationID: string[] = [];
  propertyId: string;
  status: boolean;
}
