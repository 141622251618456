import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { messages } from '@shared/constants/app.constants';
import { Property } from '@shared/models/create-building.model';
import { UserModel } from '@shared/models/login.model';
import { PageableQueryChatProperty, concatenateString } from '@shared/services/utils';
import { Subscription } from 'rxjs';
import { BuildingService } from 'src/app/building/building.service';
import { ChatService } from '../chat.service';
@Component({
  selector: 'sfl-chat-property-list',
  templateUrl: './chat-property-list.component.html',
  styleUrls: ['./chat-property-list.component.scss']
})
export class ChatPropertyListComponent implements OnInit, OnDestroy {
  @Output() startUserList: EventEmitter<string> = new EventEmitter<string>();
  @Output() backToChatHistoryList: EventEmitter<string> = new EventEmitter<string>();
  subscriptions = new Subscription();
  pageableQuery: PageableQueryChatProperty = new PageableQueryChatProperty();
  dataSource = new MatTableDataSource<Property>();
  totalElements: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedIndex = 0;
  selectedProperty: any;
  isSpinner = false;
  searchText: string;
  isLoadAllData = false;
  isLoopVendor = false;
  user: UserModel = new UserModel();
  constructor(private readonly buildingService: BuildingService, private readonly chatService: ChatService) {}

  ngOnInit() {
    this.user.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.isLoopVendor = this.user.roles[0] === messages.ROLE_LOOP_VENDOR;
    this.setPageableObject(messages.DEFAULT_PROPERTY_SORT_FIELD, messages.SORTBYDIRESCTION, 0);
    this.getBuildingList();
  }
  getBuildingList() {
    if (this.isLoadAllData) {
      return;
    }
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getPropertyForChat(this.pageableQuery).subscribe((res: any) => {
        if (res) {
          this.isSpinner = false;
          res.properties.forEach(content => {
            content.fullAddress = concatenateString(
              content.address.addressLine1,
              content.address.city,
              content.address.state,
              content.address.zipCode
            );
          });
          this.dataSource.filteredData.push(...res.properties);
          // this.totalElements = res.totalElements;
          // this.pageableQuery.size = res.pageable.pageSize;
          // this.pageableQuery.page = res.pageable.pageNumber + 1;
          this.dataSource.filteredData = this.dataSource.filteredData.filter(x => x.userPosition !== 'LOOP_VENDOR');
          this.selectedProperty = this.dataSource.filteredData[0];
          if (this.totalElements === this.dataSource.filteredData.length || this.totalElements < this.dataSource.filteredData.length) {
            this.isLoadAllData = true;
          }
        }
      })
    );
  }
  // This method is for providing values to pageableQuery object.
  setPageableObject(field, sort, page, size: number = messages.DEFAULT_PAGE_SIZE) {
    this.pageableQuery.page = page;
    this.pageableQuery.size = 50;
    this.pageableQuery.sort = sort;
    this.pageableQuery.fields = field;
  }
  onClickUserList() {
    this.startUserList.emit(this.selectedProperty);
  }
  onClickChatHistoryList() {
    this.backToChatHistoryList.emit();
  }
  onClickSelect(events, item, index) {
    this.selectedIndex = index;
    this.selectedProperty = item;
  }
  onScroll() {
    // this.getBuildingList();
  }
  onChangeSearch(event) {
    this.pageableQuery.page = 0;
    this.pageableQuery.search = this.searchText;
    this.isLoadAllData = false;
    this.dataSource.filteredData = [];
    this.getBuildingList();
  }
  getSelected(items) {
    if (items.companyPropertyId === this.selectedProperty.companyPropertyId) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
