import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { URL } from '@shared/constants/url.constant';
import {
  AddTenant,
  DeleteObserver,
  Employees,
  ManageArea,
  Observers,
  PMCPOCName,
  Property,
  PropertyCCPOC,
  PropertyDataByEmail,
  PropertyList,
  PropertyPMC,
  ReplaceStandAlonePOC,
  Tenant,
  TenantList,
  UpdateCCPOC,
} from '@shared/models/create-building.model';
import { EventList } from '@shared/models/event.model';
import { LoopProperty } from '@shared/models/loopProperty.model';
import { LoopPropertyPOC } from '@shared/models/loopPropertyPOC.model';
import { Company, LoopVendor } from '@shared/models/loopVendor.model';
import { LoopVendorPOCPageableModel } from '@shared/models/loopVendorPOC.model';
import { MessageVM, TicketFilter, TicketList } from '@shared/models/tickets.model';
import { PropertyArray, PropertyUserPageableQuery, User, UserPageableModel } from '@shared/models/user.model';
import {
  PageableQuery,
  PageableQueryChatProperty,
  PageableQueryLoopPropertyPOC,
  PageableQueryLoopVendorPOC,
  PageableQueryTenant,
  UserPageableQuery,
  createRequestOption,
} from '@shared/services/utils';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private readonly CCPOCSubject: Subject<PropertyCCPOC> = new Subject();
  private readonly PMCSubject: Subject<PropertyPMC> = new Subject();
  private readonly propertyListingSubject: Subject<number> = new Subject();
  private readonly editPropertySubject: Subject<Property> = new Subject();
  private readonly updateCCPOCSubject = new Subject<UpdateCCPOC>();
  private readonly observerListingSubject: Subject<string> = new Subject();
  private readonly areaListingSubject: Subject<Property> = new Subject();
  private readonly qrCodeListingSubject: Subject<Property> = new Subject();
  private readonly tenantListingSubject: Subject<string> = new Subject();
  private readonly editLoopVendorSubject: Subject<number> = new Subject();
  private readonly loopVendorSubject = new Subject<number>();
  private readonly loopVendorPOCSubject = new Subject<number>();
  private readonly editLoopVendorPOCSubject: Subject<User> = new Subject();
  private readonly editLoopPropeertySubject: Subject<LoopProperty> = new Subject();
  private readonly editLoopPropeertyIdSubject: Subject<LoopProperty> = new Subject();
  private readonly editLoopPropertyPOCSubject: Subject<LoopPropertyPOC> = new Subject();
  private readonly editLoopVendorIdSubject: Subject<LoopVendor> = new Subject();
  private readonly observersSubject = new Subject<number>();
  private readonly editObserverForPropertySubject: Subject<User> = new Subject();
  private readonly userProfileQrSubject: Subject<string> = new Subject();
  constructor(private readonly http: HttpClient) {}

  // This method calls the API to fetch the property data.
  getAllBuilding(pageableObject: PageableQuery): Observable<PropertyList> {
    return this.http.get<PropertyList>(URL.FETCH_PROPERTIES, {
      params: createRequestOption(pageableObject),
    });
  }

  // This method calls the API to fetch the property data on basis of different company login.
  getBuildingByCompany(pageableObject: PageableQueryChatProperty): Observable<PropertyList> {
    return this.http.get<PropertyList>(URL.FETCH_PROPERTIES_BY_COMPANY, {
      params: createRequestOption(pageableObject),
    });
  }

  // This method gets the upto date data when new property is added
  // on listing page.
  getPropertyListingSubject(): Observable<number> {
    return this.propertyListingSubject.asObservable();
  }

  // This method sets the data when new property is added
  // to provide up-to-date on listing page.
  setPropertyListingSubject(listingData: number) {
    this.propertyListingSubject.next(listingData);
  }

  // getter for update data
  getEditPropertySubject(): Observable<Property> {
    return this.editPropertySubject.asObservable();
  }

  // Setter for property data from listing
  setEditPropertyData(editData: Property) {
    this.editPropertySubject.next(editData);
  }

  // GET API for property details by propertyId
  getBuildingById(propertyId: string): Observable<Property> {
    return this.http.get<Property>(URL.FETCH_PROPERTIES + `/${propertyId}`);
  }

  // This method is for creating Property.
  addProperty(propertyData: Property): Observable<Property> {
    return this.http.post<Property>(URL.ADD_PROPERTY, propertyData);
  }

  // PUT API for updating basic property details.
  updateProperty(propertyData: Property): Observable<Property> {
    return this.http.put<Property>(URL.UPDATE_PROPERTY, propertyData);
  }

  // PUT call for replacing POC of property.
  replacePOC(POCData: Property): Observable<Property> {
    return this.http.put<Property>(URL.REPLACE_POC, POCData);
  }

  // PUT call for replacing POC of property.
  replacePOCStandAloneList(POCData: ReplaceStandAlonePOC): Observable<Property> {
    return this.http.put<Property>(URL.REPLACE_POC, POCData);
  }

  // This method is for API call for getting CC poc listing.
  getCCPOCUser(): Observable<PropertyCCPOC> {
    return this.http.get<PropertyCCPOC>(URL.FETCH_CC_POC);
  }

  // GET call for etching CC POC list on update page.
  updateCCPOCUserList(propertyid: string): Observable<UpdateCCPOC> {
    const propertyId = { property_id: propertyid };
    return this.http.get<UpdateCCPOC>(URL.UPDATE_CC_POC_LIST, {
      params: createRequestOption(propertyId),
    });
  }

  // This method is for get data while click of button.
  getCCPOCSubject(): Observable<PropertyCCPOC> {
    return this.CCPOCSubject.asObservable();
  }

  // Getter for update CC POC
  getUpdateCCPOCSubject(): Observable<UpdateCCPOC> {
    return this.updateCCPOCSubject.asObservable();
  }

  // Setter for update CC POC
  setUpdateCCPOCSubject(ccPOC: UpdateCCPOC) {
    this.updateCCPOCSubject.next(ccPOC);
  }

  // Get list of PMC POC users from PMC Company Selected
  getPOCByCompany(companyName: string): Observable<PMCPOCName[]> {
    return this.http.get<PMCPOCName[]>(URL.GET_PMC_POC_BY_COMPANY + `/${companyName}` + '/pmc-poc');
  }

  // GET call for fetching POC data by email.
  getPOCDataByEmail(emailId: string): Observable<PropertyDataByEmail> {
    return this.http.get<PropertyDataByEmail>(URL.GET_USER_DATA_BY_EMAIL + `${emailId}` + '/info');
  }

  // This method is for set data.
  setCCPOCSubject(cleaningPOC: PropertyCCPOC) {
    this.CCPOCSubject.next(cleaningPOC);
  }

  // This method is for API call for getting PMC name listing.
  getAllPMC(): Observable<PropertyPMC> {
    return this.http.get<PropertyPMC>(URL.FETCH_PMC);
  }

  // This method is for get data while click of button.
  getPMCUserSubject(): Observable<PropertyPMC> {
    return this.PMCSubject.asObservable();
  }

  // This method is for set data on click.
  setPMCUserSubject(pmcUser: PropertyPMC) {
    this.PMCSubject.next(pmcUser);
  }

  // POST API to create observers
  createObservers(defaultObserver: Observers, propertyId: string): Observable<Observers> {
    return this.http.post<Observers>(URL.ADD_DEFAULT_OBSERVER + `/${propertyId}`, defaultObserver);
  }

  // Getter for observer listing subject
  getObserverListingSubject(): Observable<string> {
    return this.observerListingSubject.asObservable();
  }

  // Setter for observer listing subject
  setObservableListingSubject(listingData: string) {
    return this.observerListingSubject.next(listingData);
  }

  // Getter for tenant listing subject
  getTenantListingSubject(): Observable<string> {
    return this.tenantListingSubject.asObservable();
  }

  // Setter for tenant listing subject
  setTenantListingSubject(listingData: string) {
    return this.tenantListingSubject.next(listingData);
  }

  // Getter for area listing subject
  getAreaListingSubject(): Observable<Property> {
    return this.areaListingSubject.asObservable();
  }

  // Setter for area listing subject
  setAreaListingSubject(listingData: Property) {
    return this.areaListingSubject.next(listingData);
  }

  // GET API for list of employees
  getAllEmployees(): Observable<Employees> {
    return this.http.get<Employees>(URL.ADD_COMPANY_USER);
  }

  // GET API for list of all areas for particular property
  getAreas(propertyId: string): Observable<ManageArea> {
    return this.http.get<ManageArea>(URL.MANAGE_AREA + `/${propertyId}`);
  }

  // POST API for creating areas
  createArea(manageArea: ManageArea): Observable<ManageArea> {
    return this.http.post<ManageArea>(URL.MANAGE_AREA, manageArea);
  }

  // GET API for all observers.
  getAllDefaultObservers(propertyId: string): Observable<Observers> {
    return this.http.get<Observers>(URL.ADD_DEFAULT_OBSERVER + `/${propertyId}`);
  }

  // GET API for all tenants
  getTenant(propertyid: string): Observable<AddTenant[]> {
    const propertyId = { propertyId: propertyid };
    return this.http.get<AddTenant[]>(URL.ADD_PROPERTY + '/tenant', {
      params: createRequestOption(propertyId),
    });
  }

  // DELETE API a particular observer
  deleteObserver(emailId: string, propertyId: string): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(environment.serverUrl + `/${emailId}/observers/${propertyId}`);
  }

  // POST API to create tenant
  createTenant(tenants: Tenant): Observable<Tenant> {
    return this.http.post<Tenant>(URL.ADD_PROPERTY + '/tenant', tenants);
  }

  //DELETE API for tenant
  deleteTenant(propertyId: string, suitno: string): Observable<DeleteObserver> {
    const suitNumber = { suitNumber: suitno };
    return this.http.delete<DeleteObserver>(URL.ADD_PROPERTY + `/tenant/${propertyId}`, {
      params: createRequestOption(suitNumber),
    });
  }

  // GET API for property id to fetch the list of ticket
  getTickets(pageableObject: PageableQuery, ticketFilter: TicketFilter): Observable<TicketList> {
    return this.http.post<TicketList>(URL.ADD_TICKET + '/search', ticketFilter, {
      params: createRequestOption(pageableObject),
    });
  }

  // GET API for property id to fetch the list of event
  getAllEvent(pageableObject: PageableQuery, propertyId: string): Observable<EventList> {
    return this.http.get<EventList>(URL.GET_EVENT_ID + '/' + propertyId, {
      params: createRequestOption(pageableObject),
    });
  }

  // GET API for property id to fetch the list of tenant
  getAllTenant(pageableObject: PageableQueryTenant): Observable<TenantList> {
    return this.http.get<TenantList>(URL.GET_TENANT_ID, {
      params: createRequestOption(pageableObject),
    });
  }
  // Getter for qrCode listing subject
  getQrCodeSubject(): Observable<Property> {
    return this.qrCodeListingSubject.asObservable();
  }

  // Setter for qrCode listing subject
  setQrCodeSubject(listingData: Property) {
    return this.qrCodeListingSubject.next(listingData);
  }

  // GET API for retrieving all properties
  getAllProperty(): Observable<PropertyArray> {
    return this.http.get<PropertyArray>(URL.GET_PROPERTY_LIST);
  }
  // TO DO remove once confirm module
  // //GET API for users under perticular property

  // getAllUserProperty(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
  //   return this.http.get<UserPageableModel>(URL.PROPERTY_USER, {
  //     params: createRequestOption(pageableObject)
  //   });
  // }
  //GET API for users under perticular property

  getAllUserProperty(pageableObject: PropertyUserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.COMPANY_PROPERTY_USERS, {
      params: createRequestOption(pageableObject),
    });
  }
  //POST API for Create LoopVendors
  createLoopVendor(loopVendor: LoopVendor): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.COMPANY, loopVendor);
  }

  // getter for update data
  getEditLoopVendorSubject(): Observable<number> {
    return this.editLoopVendorSubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopVendorData(editData: number) {
    this.editLoopVendorSubject.next(editData);
  }

  getAllLoopVendorsProperty(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.COMPANY_PROPERTIES, {
      params: createRequestOption(pageableObject),
    });
  }
  updateLoopVendor(loopVendor: Company): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.COMPANY, loopVendor);
  }

  getLoopVendorSubject(): Observable<number> {
    return this.loopVendorSubject.asObservable();
  }

  setLoopVendotSubject(userData: number) {
    return this.loopVendorSubject.next(userData);
  }

  //API for get loopVendor Detail by ID
  getLoopVendorByID(id: string): Observable<LoopVendor> {
    return this.http.get<LoopVendor>(URL.LOOP_VENDORS + `/${id}`);
  }

  //API for Post loopVendorPOC
  createLoopVendorPOC(loopVendorPOC: User): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.COMPANY_PROPERTY_USERS, loopVendorPOC);
  }

  //API for Get List loopVendorPOC
  getAllLoopVendorsPOCs(pageableObject: PageableQueryLoopVendorPOC): Observable<LoopVendorPOCPageableModel> {
    return this.http.get<LoopVendorPOCPageableModel>(URL.LOOP_VENDORS_POC, {
      params: createRequestOption(pageableObject),
    });
  }

  //API for update loopvendor POC
  updateLoopVendorPOC(loopVendorPOC: User): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.COMPANY_PROPERTY_USERS, loopVendorPOC);
  }

  //Set Data for update List
  setLoopVendorPOCSubject(userData: number) {
    return this.loopVendorPOCSubject.next(userData);
  }
  //Get Data for update List
  getLoopVendorPOCSubject(): Observable<number> {
    return this.loopVendorPOCSubject.asObservable();
  }
  // getter for update data
  getEditLoopVendorPOCSubject(): Observable<User> {
    return this.editLoopVendorPOCSubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopVendorPOCData(editData: User) {
    this.editLoopVendorPOCSubject.next(editData);
  }
  // POST API to create LoopVendorProperty
  createLoopVendorProperty(loopproperty: LoopProperty): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.LOOP_PROPERTY, loopproperty);
  }

  // API for Delete Loop Vendor
  deleteLoopVendor(id: number, status: boolean): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(URL.COMPANY_PROPERTY + `/${id}?status=${status}`);
  }
  // API for Delete Loop Vendor
  deleteLoopVendorPOC(emailId: string, loopVendorId: number, status: boolean): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(URL.LOOP_VENDORS_POC + `/${loopVendorId}?emailId=${emailId}&status= ${status}`);
  }
  // API for get all loop property
  getAllLoopProperty(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.LOOP_PROPERTY, {
      params: createRequestOption(pageableObject),
    });
  }
  // Getter loop property
  getLoopPropertySubject(): Observable<number> {
    return this.loopVendorSubject.asObservable();
  }
  // setter loop property
  setLoopPropertySubject(userData: number) {
    return this.loopVendorSubject.next(userData);
  }
  //API for get loopVendor Detail by ID
  getLoopPropertyByID(id: string): Observable<LoopProperty> {
    return this.http.get<LoopProperty>(URL.LOOP_PROPERTY + `/${id}`);
  }
  //API for Post loopVendorPOC
  createLoopProperyPOC(loopPorpertyPOC: LoopPropertyPOC): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.LOOP_PROPERTY_POC, loopPorpertyPOC);
  }
  //API for Get List loopVendorPOC
  getAllLoopPropertiesPOCs(pageableObject: PageableQueryLoopPropertyPOC): Observable<LoopVendorPOCPageableModel> {
    return this.http.get<LoopVendorPOCPageableModel>(URL.LOOP_PROPERTY_POC, {
      params: createRequestOption(pageableObject),
    });
  }
  // Getter loop property POC
  getLoopPropertyPOCSubject(): Observable<number> {
    return this.loopVendorSubject.asObservable();
  }
  // setter loop property POC
  setLoopPropertyPOCSubject(userData: number) {
    return this.loopVendorSubject.next(userData);
  }

  // getter for update data
  getEditLoopPropertySubject(): Observable<LoopProperty> {
    return this.editLoopPropeertySubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopPropertyData(editData: LoopProperty) {
    this.editLoopPropeertySubject.next(editData);
  }
  //API for Update loop property
  updateLoopProperty(loopproperty: LoopProperty): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.LOOP_PROPERTY, loopproperty);
  }

  // getter for update data
  getEditLoopPropertyPOCSubject(): Observable<LoopPropertyPOC> {
    return this.editLoopPropertyPOCSubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopPropertyPOCData(editData: LoopPropertyPOC) {
    this.editLoopPropertyPOCSubject.next(editData);
  }
  // API for Delete Loop Property
  deleteLoopProperty(id: number): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(URL.LOOP_PROPERTY + `/${id}`);
  }
  // API for Delete Loop Property
  deleteLoopPropertyPOC(emailId: string, loopVendorId: number, status: boolean): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(URL.LOOP_PROPERTY_POC + `/${loopVendorId}?emailId=${emailId}&status= ${status}`);
  }
  // getter for update data
  getEditLoopPropertyIDSubject(): Observable<LoopProperty> {
    return this.editLoopPropeertyIdSubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopPropertyIDData(editData: LoopProperty) {
    this.editLoopPropeertyIdSubject.next(editData);
  }

  // getter for update data
  getEditLoopVendorIDSubject(): Observable<LoopVendor> {
    return this.editLoopVendorIdSubject.asObservable();
  }

  // Setter for property data from listing
  setEditLoopVendorIDData(editData: LoopVendor) {
    this.editLoopVendorIdSubject.next(editData);
  }
  // TO DO Once confirm will remove this
  //API for Post Observer
  // createObserver(observer: ObserversProperty): Observable<MessageVM> {
  //   return this.http.post<MessageVM>(URL.OBSERVERS, observer);
  // }

  //API for Post Observer
  createObserver(observer: User[]): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.COMPANY_PROPERTY_USERS + '/bulk', observer);
  }
  getAllObservers(pageableObject: UserPageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.OBSERVERS, {
      params: createRequestOption(pageableObject),
    });
  }

  getObserverSubject(): Observable<number> {
    return this.observersSubject.asObservable();
  }

  setObserverSubject(userData: number) {
    return this.observersSubject.next(userData);
  }

  // API for Delete observer for property
  deleteObserverForProperty(id: Number): Observable<DeleteObserver> {
    return this.http.delete<DeleteObserver>(URL.OBSERVERS + `/${id}`);
  }
  // getter for Observer Data
  getObserverForPropertySubject(): Observable<User> {
    return this.editObserverForPropertySubject.asObservable();
  }

  getUserProfileQrSubject(): Observable<string> {
    return this.userProfileQrSubject.asObservable();
  }

  setUserProfileQrSubject(profileQrCode: string) {
    return this.userProfileQrSubject.next(profileQrCode);
  }

  // Setter for Observer data from listing
  setObserverForPropertyData(editData: User) {
    this.editObserverForPropertySubject.next(editData);
  }
  //API for Update Observer
  updateObserverForProperty(observer: User): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.COMPANY_PROPERTY_USERS, observer);
  }
  // TO DO Remove once confirm
  //API for activate deactivate user
  // userStatusChnage(id, email, status): Observable<MessageVM> {
  //   return this.http.delete<MessageVM>(URL.PROPERTY_USER + `/${id}?emailId=${email}&&status=${status}`);
  // }
  //API for activate deactivate user
  userStatusChnage(id, status): Observable<MessageVM> {
    return this.http.delete<MessageVM>(URL.COMPANY_PROPERTY_USERS + `/${id}?status=${status}`);
  }
  userStatusChnageWithPermission(id, status): Observable<MessageVM> {
    return this.http.delete<MessageVM>(URL.COMPANY_PROPERTY_USERS + `/${id}?status=${status}&&hasUserGivenConsent=${status}`);
  }
  selectStatusChange(pageableObject: PageableQuery): Observable<User> {
    return this.http.get<User>(URL.PROPERTY_USER, {
      params: createRequestOption(pageableObject),
    });
  }
  observerStatusChnage(emailId, propertyId, status): Observable<MessageVM> {
    return this.http.delete<MessageVM>(URL.OBSERVERS + `/?emailId=${emailId}&&propertyId=${propertyId}&&status=${status}`);
  }
  selectObserverStatusChange(pageableObject: PageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.OBSERVERS, {
      params: createRequestOption(pageableObject),
    });
  }
  // GET API for LoopVendor Details
  getLoopVendorByEmail(emailId: string): Observable<LoopVendor> {
    return this.http.get<LoopVendor>(URL.LOOP_VENDORS + `/info?vendorEmail=${emailId}`);
  }
  //TO DO Remove once confirm
  //API for Get List loopVendorPOC
  // getAllUsersForLoopVendor(pageableObject: PageableQuery): Observable<UserPageableModel> {
  //   return this.http.get<UserPageableModel>(URL.LOOP_VENDORS_POC + '/vendor', {
  //     params: createRequestOption(pageableObject)
  //   });
  // }
  selectLoopVendorStatusChange(pageableObject: PageableQuery): Observable<LoopVendor> {
    return this.http.get<LoopVendor>(URL.LOOP_VENDORS, {
      params: createRequestOption(pageableObject),
    });
  }
  selectLoopVendorPOCStatusChange(pageableObject: PageableQuery): Observable<UserPageableModel> {
    return this.http.get<UserPageableModel>(URL.LOOP_VENDORS_POC + '/vendor', {
      params: createRequestOption(pageableObject),
    });
  }
  // GET API for Property User Feature.
  getPropertyFeature(): Observable<any> {
    return this.http.get<any>(URL.PROPERTY_USER_FEATURE);
  }
  //TO DO Remove once confirm
  // GET API for Property User Feature.
  // getPropertyUserFeature(propertyId, userEmail): Observable<any> {
  //   return this.http.get<any>(URL.PROPERTY_USER_FEATURE + `?propertyId=${propertyId}&userEmail=${userEmail}`);
  // }
  // GET API for Property User Feature.
  getPropertyUserFeature(companyPropertyUserId: number): Observable<any> {
    return this.http.get<any>(URL.PROPERTY_USER_FEATURE + `/company-property-users/${companyPropertyUserId}`);
  }
  // PUT API for Property User Feature.
  putPropertyUserFeature(propertyData: any): Observable<any> {
    return this.http.put<any>(URL.PROPERTY_USER_FEATURE, propertyData);
  }
  //get loopvendor by id
  getCompanyPropertyDetailsById(id: number, loopVendorType: string, pocType: string): Observable<LoopVendor> {
    return this.http.get<LoopVendor>(URL.COMPANY_PROPERTY + `/${id}?companyPropertyType=${loopVendorType}&companyPropertyUserType=${pocType}`);
  }
  // UPDATE => PUT: update the user on the server
  updateUser(user: User): Observable<MessageVM> {
    return this.http.put<MessageVM>(URL.COMPANY_PROPERTY_USERS, user);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User): Observable<MessageVM> {
    return this.http.post<MessageVM>(URL.COMPANY_PROPERTY_USERS, user);
  }
  getCompanyPropertyUserId(companyPropertyId: number): Observable<any> {
    return this.http.get<any>(URL.PROPERTY_USER_FEATURE + `/company-property/${companyPropertyId}`);
  }
  // GET API for Company Details By Email
  getCompanyByEmail(emailId: string): Observable<LoopVendor> {
    return this.http.get<LoopVendor>(URL.COMPANY + `/${emailId}`);
  }
  // New API from companyPropertyId get LoopVendor for Ticket
  getPropertyDetailsByCompanyPropertyId(companyPropertyId: number): Observable<any> {
    return this.http.get<any>(URL.COMPANY_PROPERTIES + `/${companyPropertyId}`);
  }
}
