import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {
  DEFAULT_IMAGE = messages.DEFAULT_IMAGE;
  constructor(public dialogRef: MatDialogRef<ViewProfileComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
