import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from '@layout/services';
import * as objectPath from 'object-path';

@Component({
  selector: 'sfl-subheader',
  templateUrl: './subheader.component.html'
})
export class SubheaderComponent implements OnInit {

  layout: string;
  fluid: boolean;
  clear: boolean;

  constructor(private readonly layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    this.layout = objectPath.get(config, 'subheader.layout');
    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
    this.clear = objectPath.get(config, 'subheader.clear');
  }
}
