import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { NoteReaction, UserReact } from '@shared/models/tickets.model';
import { Subscription } from 'rxjs';
import { TicketsService } from 'src/app/tickets/tickets.service';
import { messages } from '@shared/constants/app.constants';
@Component({
  selector: 'sfl-reacted-user-list',
  templateUrl: './reacted-user-list.component.html',
  styleUrls: ['./reacted-user-list.component.scss']
})
export class ReactedUserListComponent implements OnInit {
  subscriptions = new Subscription();
  userList: UserReact[];
  IMAGE_PATH = messages.DEFAULT_IMAGE_PATH;
  emojiList = ['LIKE', 'DISLIKE', 'QUESTION', 'EXCLAMATORY'];
  Object = Object;
  pleaseWait = false;
  constructor(
    public dialogRef: MatDialogRef<ReactedUserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ticketService: TicketsService,
    private readonly layoutUtilsService: LayoutUtilsService) { }

  ngOnInit() {
    this.getUserList();
  }
  getUserList() {
    this.pleaseWait = true;
    this.subscriptions.add(
      this.ticketService.getUsersForReactionOnNote( this.data.noteId, this.data.reactionName).subscribe(
        (res:UserReact[]) => {
          this.userList = res;
          this.pleaseWait = false;
        },
        (error: HttpErrorResponse) => {
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
        }
      )
    );
  }
  onClickReaction(key) {
    this.data.reactionName = key;
    this.getUserList();
  }

  onClickClose() {
    this.dialogRef.close();
  }
  emojiPathReacted(emoji) {
    if (emoji === 'LIKE') {
      return messages.LIKE_EMOJI_SELECTED
    } else if (emoji === 'QUESTION') {
      return messages.QUESTION_EMOJI_SELECTED
    } else if (emoji === 'EXCLAMATORY') {
      return messages.EXCLAMATORY_EMOJI_SELECTED
    } else if (emoji === 'DISLIKE') {
      return messages.DISLIKE_EMOJI_SELECTED
    }
  }
  emojiPathReaction(emoji) {
    if (emoji === 'LIKE') {
      return messages.LIKE_EMOJI_BLACK
    } else if (emoji === 'QUESTION') {
      return messages.QUESTION_EMOJI_BLACK
    } else if (emoji === 'EXCLAMATORY') {
      return messages.EXCLAMATORY_EMOJI_BLACK
    } else if (emoji === 'DISLIKE') {
      return messages.DISLIKE_EMOJI_BLACK
    }
  }
  setReactionPosition(items) {
    if (items) {
    const reactionCounts = {
        "LIKE": items.LIKE ? items.LIKE:0,
        "DISLIKE": items.DISLIKE ? items.DISLIKE : 0,
        "QUESTION": items.QUESTION ? items.QUESTION : 0,
        "EXCLAMATORY": items.EXCLAMATORY ? items.EXCLAMATORY : 0
      }
      return reactionCounts;
    }
  }
}
