import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthNotice, AuthNoticeService } from '@shared/auth-notice';

@Component({
  selector: 'sfl-auth-notice',
  templateUrl: './auth-notice.component.html'
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
  @Output() type: any;
  @Output() messageInfo: any = '';

  subscriptions = new  Subscription();

  constructor(
    public authNoticeService: AuthNoticeService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.authNoticeService.onNoticeChanged$.subscribe(
        (notice: AuthNotice) => {
          notice = Object.assign({}, { message: '', type: '' }, notice);
          this.messageInfo = notice.message;
          this.type = notice.type;
          this.cdr.markForCheck();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
