import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OffcanvasOptions } from '@shared/directives';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/chat/chat.service';
@Component({
  selector: 'sfl-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent implements OnInit, OnDestroy {
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'sfl-quick-panel',
    closeBy: 'kt_chat_panel_close_btn',
    toggleBy: 'kt_chat_panel_toggler_btn'
  };
  @ViewChild('closeSideBar', { static: false }) myDiv: ElementRef<HTMLElement>;
  dataSource: any;
  isChatHistory = true;
  isChatPropertyList = false;
  isChatUserList = false;
  isChatTicketList = false;
  isConversationRoom = false;
  isConversationInfo = false;
  isSearchGroupOrMessage = false;
  isChatOpenFromSearchPage = false;
  companyPropertyForUsers: any;
  companyPropertyForTicket: any;
  roomDetails: any;
  isChatPropertyStatus: string;
  selectedTicket: any;
  selectedRoom: any;
  searchTerm: string;
  subscription = new Subscription();
  constructor(private readonly chatService: ChatService) {}

  ngOnInit() {
    this.subscription.add(
      this.chatService.onChatFromTicket.asObservable().subscribe((msg: any) => {
        if (msg) {
          this.onClickUserListFromTicket(msg);
          this.chatService.onChatFromTicketDetails(null);
        }
      })
    );
  }
  onClickPropertyList(item) {
    console.log('onClickPropertyList', item);
    this.selectedTicket = null;
    this.isChatPropertyStatus = item;
    this.isChatHistory = false;
    this.isChatPropertyList = true;
    this.isChatUserList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    this.isSearchGroupOrMessage = false;
  }
  onClickUserList(item) {
    console.log('onClickUserList,onClickTicketList');
    if (item.roomId) {
      this.selectedRoom = item;
      this.companyPropertyForUsers = null;
      this.companyPropertyForTicket = null;
    } else {
      this.selectedRoom = null;
      this.companyPropertyForUsers = item;
      this.companyPropertyForTicket = item;
    }
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isSearchGroupOrMessage = false;
    if (this.isChatPropertyStatus === 'Ticket' && !item.roomId) {
      this.isChatTicketList = true;
    } else {
      this.isChatUserList = true;
    }
  }
  onClickChatHistory() {
    console.log('onClickChatHistory');
    this.isChatPropertyList = false;
    this.isChatUserList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    if (this.isChatOpenFromSearchPage) {
      this.isSearchGroupOrMessage = true;
      this.isChatHistory = false;
    } else {
      this.isChatHistory = true;
      this.isSearchGroupOrMessage = false;
      this.onSearchTermChanged(null);
    }
  }
  onClickConversationRoom(items) {
    console.log('onClickConversationRoom', items);
    this.isChatOpenFromSearchPage = this.isSearchGroupOrMessage;
    this.roomDetails = items;
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isChatUserList = false;
    this.isConversationRoom = true;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    this.isSearchGroupOrMessage = false;
  }
  onClickConversationInfo(items) {
    console.log('onClickConversationInfo');
    this.roomDetails = items;
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isChatUserList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = true;
    this.isChatTicketList = false;
    this.isSearchGroupOrMessage = false;
  }
  onClickUserListFromTicket(selectedTicket) {
    console.log('onClickUserList', selectedTicket);
    this.roomDetails = null;
    this.selectedTicket = selectedTicket;
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    this.isChatUserList = true;
    this.isSearchGroupOrMessage = false;
  }

  onSearchMessageOrGroup(search: boolean): void {
    this.roomDetails = null;
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    this.isChatUserList = false;
    this.isSearchGroupOrMessage = true;
  }

  onSearchTermChanged(search: string): void {
    this.searchTerm = search;
  }

  openConversationList() {
    this.isChatHistory = true;
    this.isChatPropertyList = false;
    this.isChatUserList = false;
    this.isChatTicketList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isSearchGroupOrMessage = false;
    this.isChatOpenFromSearchPage = false;
  }

  ngOnDestroy(): void {
    this.isChatHistory = false;
    this.isChatPropertyList = false;
    this.isChatUserList = false;
    this.isConversationRoom = false;
    this.isConversationInfo = false;
    this.isChatTicketList = false;
    this.isSearchGroupOrMessage = false;
  }
}
