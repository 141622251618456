import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { UserModel } from '@shared/models/login.model';
import { PropertyDetails } from '@shared/models/user.model';
import { AuthService } from '@shared/services';
import { Subscription } from 'rxjs';
import { UploadImageModel, UserProfileModel } from './my-profile.model';
import { MyProfileService } from './my-profile.service';

@Component({
  selector: 'sfl-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  @Input() template: any;
  userProfile: UserProfileModel = new UserProfileModel();
  myProfileForm: FormGroup = this.initializeForm();
  image: File;
  userProfileModel: UserProfileModel = new UserProfileModel();
  user: UserModel = new UserModel();
  subscription = new Subscription();

  firstName = this.myProfileForm.controls['firstName'];
  lastName = this.myProfileForm.controls['lastName'];
  phoneNumber = this.myProfileForm.controls['phoneNumber'];
  emailId = this.myProfileForm.controls['emailId'];
  notification = this.myProfileForm.controls['notification'];
  companyName = this.myProfileForm.controls['companyName'];
  suitNumber = this.myProfileForm.controls['suitNumber'];
  openSurveyNotification = this.myProfileForm.controls['openSurveyNotification'];

  imageUrl: string;
  formData = new FormData();
  loading = false;
  PHONE_NUMBER_MASK = messages.PHONE_NUMBER_MASK;
  IMAGE_URL = messages.DEFAULT_IMAGE_PATH;
  registrationToken: string;
  isLoopVendor = false;
  isShowSurveyCheckbox = true;
  propertyDetails: PropertyDetails = new PropertyDetails();

  constructor(
    private readonly profileService: MyProfileService,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly layoutService: LayoutUtilsService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.user.roles = JSON.parse(sessionStorage.getItem('roles'));
    this.propertyDetails = JSON.parse(sessionStorage.getItem('propertyDetails'));
    this.registrationToken = sessionStorage.getItem('registrationToken');
    this.subscription.add(
      this.profileService.getProfileSubject().subscribe((res: UserProfileModel) => {
        sessionStorage.setItem('imageUrl', res.imageUrl);
        this.getProfileData(res);
        this.authService.setSubject(0);
        this.changeDetection.detectChanges();
      })
    );
    this.subscription.add(
      this.profileService.getResetProfileSubject().subscribe(() => {
        this.resetProfileData();
      })
    );
    if (this.user.roles[0] === messages.ROLE_LOOP_VENDOR) {
      this.isLoopVendor = true;
    } else {
      this.isLoopVendor = false;
    }
    if (this.user.roles[0] === messages.ROLE_LOOP_VENDOR || this.propertyDetails.userPosition === messages.LOOP_VENDOR_POC_POSITION) {
      this.isShowSurveyCheckbox = false;
    }
  }

  initializeForm(): FormGroup {
    return new FormGroup({
      companyName: new FormControl('', [Validators.maxLength(messages.VALIDATIONS.MAX_SEVENTY_FIVE)]),
      suitNumber: new FormControl('', [Validators.maxLength(messages.VALIDATIONS.MAX_SUIT_NO)]),
      phoneNumber: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      notification: new FormControl(true),
      firstName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_FIRST_NAME)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(messages.VALIDATIONS.MAX_LAST_NAME)]),
      openSurveyNotification: new FormControl(true)
    });
  }

  getProfileData(userProfile: UserProfileModel) {
    this.myProfileForm.setValue({
      companyName: userProfile.companyName,
      suitNumber: userProfile.suitNumber,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      emailId: userProfile.emailId,
      phoneNumber: userProfile.phoneNumber,
      notification: userProfile.notification,
      openSurveyNotification: userProfile.openSurveyNotification
    });

    this.imageUrl = userProfile.imageUrl;
  }

  imageUpload(event: any) {
    //TODO set condition for temporary Once backend changed remove this condition
    // if (this.user.roles[0] === messages.ROLE_LOOP_VENDOR) {
    //   this.layoutService.showActionNotification(ACTION_MESSAGES.CURRENTLY_WORKING_ON_IT, MessageType.Create);
    //   return;
    // }
    this.image = event.target.files[0];
    this.loading = true;
    if (this.image) {
      this.formData.set('file', this.image, this.image.name);
      this.subscription.add(
        this.profileService.updateProfilePicture(this.formData).subscribe((res: UploadImageModel) => {
          this.loading = false;
          this.imageUrl = res.documentUrl;
          sessionStorage.setItem('imageUrl', this.imageUrl);
          this.authService.setSubject(0);
          this.changeDetection.detectChanges();
        })
      );
    }
  }

  createUserProfile(): UserProfileModel {
    this.userProfile.firstName = this.firstName.value;
    this.userProfile.lastName = this.lastName.value;
    this.userProfile.emailId = this.emailId.value;
    this.userProfile.phoneNumber = this.phoneNumber.value;
    this.userProfile.notification = this.notification.value;
    this.userProfile.openSurveyNotification = this.openSurveyNotification.value;
    this.userProfile.firebaseToken = this.registrationToken;
    return this.userProfile;
  }

  resetProfileData() {
    this.companyName.reset();
    this.suitNumber.reset();

    this.firstName.reset();
    this.lastName.reset();
    this.emailId.reset();
    this.phoneNumber.reset();
  }

  onSubmit() {
    const controls = this.myProfileForm.controls;

    if (this.myProfileForm.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;
    this.userProfileModel = this.createUserProfile();
    this.subscription.add(
      this.profileService.updateProfileData(this.userProfileModel).subscribe(
        () => {
          this.loading = false;
          sessionStorage.setItem('fullName', this.userProfileModel.firstName + ' ' + this.userProfileModel.lastName);
          this.authService.setSubject(0);
          this.layoutService.showActionNotification(ACTION_MESSAGES.PROFILE_UPDATE_SUCCESS, MessageType.Create);
        },
        err => {
          this.loading = false;
        }
      )
    );
    this.closeSidebar();
  }

  closeSidebar() {
    this.profileService.setResetProfileSubject(0);
    this.template.close();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.myProfileForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
