import { AccountService } from '@account/account.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthNoticeService } from '@shared/auth-notice';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { ForgotPasswordModel } from '@shared/models/account.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'sfl-forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  loading = false;
  errors: any = [];
  forgetPasswordModel = new ForgotPasswordModel();
  subscription = new Subscription();

  private readonly unsubscribe: Subject<any>;

  constructor(
    public authNoticeService: AuthNoticeService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly accountService: AccountService
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegistrationForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
    this.subscription.unsubscribe();
  }

  initRegistrationForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(320)])]
    });
  }

  // This method will submit the email of forgot password user
  // and will redirect to login page.
  submit() {
    const controls = this.forgotPasswordForm.controls;
    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;

    this.forgetPasswordModel.emailId = controls.email.value;
    this.subscription.add(
      this.accountService.forgotPasswordInit(this.forgetPasswordModel).subscribe(
        response => {
          if (response) {
            this.loading = false;
            this.authNoticeService.setNotice(ACTION_MESSAGES.FORGET_PASSWORD_INIT_SUCCESS, 'success');
            this.router.navigateByUrl('/auth/login');
            sessionStorage.clear();
            localStorage.clear();
          }
        },
        () => {
          this.loading = false;
          this.authNoticeService.setNotice(ACTION_MESSAGES.INVALID_EMAIL, 'danger');
        }
      )
    );
  }
  onClickBack() {
    this.authNoticeService.setNotice(null);
    this.router.navigateByUrl('/auth/login');
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
