// Anglar
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';

// Directive
import {
  ContentAnimateDirective,
  HeaderDirective,
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  StickyDirective,
  TabClickEventDirective,
  ToggleDirective,
  TitleCaseDirective
} from '@shared/directives';

// Pipe
import { FirstLetterPipe, GetObjectPipe, DateTimeSlotPipe } from '@shared/pipes';
import {
  RequiredNoSpaceValidator,
  PasswordValidator,
  PhoneValidator,
  EmailValidator,
  AutofocusDirective
} from './directives/validator.directive';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { HttpClientModule } from '@angular/common/http';
import {
  MatNativeDateModule,
  MatMenuModule,
  MatSelectModule,
  MatInputModule,
  MatTableModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTooltipModule,
  MatDialogModule,
  MatRadioModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatProgressBarModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { UserEditComponent } from '../building/user-edit/user-edit.component';
import { ObserverEditComponent } from '../building/observer-edit/observer-edit.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserFeatureDirective } from './directives/user-feature.directive';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    NgxFullCalendarModule,
    HttpClientModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSidenavModule,
    NgxMatSelectSearchModule,
    GooglePlaceModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatDividerModule
  ],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    TitleCaseDirective,
    AutofocusDirective,
    // pipes
    GetObjectPipe,
    DateTimeSlotPipe,
    FirstLetterPipe,
    RequiredNoSpaceValidator,
    PasswordValidator,
    PhoneValidator,
    EmailValidator,
    EventCalendarComponent,
    UserFeatureDirective
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    ContentAnimateDirective,
    StickyDirective,
    TitleCaseDirective,
    AutofocusDirective,
    // pipes
    GetObjectPipe,
    DateTimeSlotPipe,
    FirstLetterPipe,
    NgxMaskModule,
    RequiredNoSpaceValidator,
    PasswordValidator,
    PhoneValidator,
    EmailValidator,
    EventCalendarComponent,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSidenavModule,
    NgxMatSelectSearchModule,
    GooglePlaceModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDividerModule,
    UserFeatureDirective
  ],
  providers: [DatePipe]
})
export class SharedModule {}
