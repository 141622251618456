import { AccountService } from '@account/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { UserProfileModel } from '@layout/my-profile/my-profile.model';
import { TermsConditionDialogComponent } from '@layout/partials/content/crud/terms-condition-dialog/terms-condition-dialog.component';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { DataService } from '@layout/services/data.service';
import { LayoutService } from '@layout/services/layout.service';
import { AuthNoticeService } from '@shared/auth-notice';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { ResetPasswordModel } from '@shared/models/account.model';
import { UserModel } from '@shared/models/login.model';
import { AuthService } from '@shared/services';
import { MessagingService } from '@shared/services/messaging.service';
import { Subscription } from 'rxjs';
import { ChatConfigService } from 'src/app/chat/chat_config.service';

@Component({
  selector: 'sfl-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  constructor(
    public readonly authNoticeService: AuthNoticeService,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly messagingService: MessagingService,
    private readonly auth: AuthService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly layoutService: LayoutService,
    private readonly matDialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly chatConfigService: ChatConfigService,
    private readonly data: DataService,
  ) {}

  resetPasswordForm: FormGroup = this.initResetPasswordForm();
  loading = false;
  subscription = new Subscription();
  noMatch = false;
  resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
  newPassword = this.resetPasswordForm.controls['newPassword'];
  confirmPassword = this.resetPasswordForm.controls['confirmPassword'];
  hide = true;
  returnUrl: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.loading = false;
    this.subscription.unsubscribe();
  }

  matchNewCurrentPassword() {
    const controls = this.resetPasswordForm.controls;
    if (controls.newPassword.value && controls.confirmPassword.value) {
      if (controls.newPassword.value !== controls.confirmPassword.value) {
        this.noMatch = true;
      } else {
        this.noMatch = false;
      }
    } else {
      this.noMatch = false;
    }
  }

  initResetPasswordForm(): FormGroup {
    return new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(messages.VALIDATIONS.MIN_PASSWORD_LENGTH),
        Validators.maxLength(messages.VALIDATIONS.MAX_PASSWORD_LENGTH),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(messages.VALIDATIONS.MIN_PASSWORD_LENGTH),
        Validators.maxLength(messages.VALIDATIONS.MAX_PASSWORD_LENGTH),
      ]),
    });
  }

  setPasswordForm(changePassword: ResetPasswordModel) {
    return this.resetPasswordForm.setValue({
      newPassword: changePassword.newPassword,
      confirmPassword: changePassword.confirmPassword,
    });
  }

  setPassword(): ResetPasswordModel {
    this.resetPasswordModel.newPassword = this.newPassword.value;
    this.resetPasswordModel.confirmPassword = this.confirmPassword.value;
    this.resetPasswordModel.email = null;
    return this.resetPasswordModel;
  }

  // This method is for resetting the password of user.
  submit() {
    const controls = this.resetPasswordForm.controls;
    if (this.resetPasswordForm.invalid || this.noMatch) {
      Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
      return;
    }
    this.loading = true;
    this.subscription.add(
      this.accountService.resetPassword(this.setPassword()).subscribe(
        response => {
          if (response) {
            this.authNoticeService.setNotice(ACTION_MESSAGES.RESET_PASSWORD_SUCCESS, 'success');
            this.redirectToAuthPage();
          }
        },
        () => {
          this.loading = false;
        },
      ),
    );
  }

  redirectToAuthPage() {
    this.accountService.initNotificationService();
    this.accountInfo();
  }

  accountInfo(): void {
    this.subscription.add(
      this.auth.account().subscribe((accountInfo: UserModel) => {
        this.loading = false;
        this.afterAccountInfo(accountInfo);
      }),
    );
  }

  afterAccountInfo(accountInfo: UserModel): void {
    this.accountService.storeUserInfoInStorage(accountInfo);
    if (!accountInfo.agreement) {
      this.showAgreements();
    } else {
      this.setRedirect();
    }
  }

  showAgreements(): void {
    environment.isRXChat ? this.getIsTermAccept() : this.setRedirect();
  }

  setRedirect() {
    if (this.returnUrl) {
      this.subscription.add(
        this.layoutService.getIdForRedirectToRXA('analytics').subscribe(
          (uuid: string) => {
            window.location.href = `${environment.urlRXA}/redirect?uuid=${uuid}&&returnUrl=${this.returnUrl}`;
          },
          (error: HttpErrorResponse) => {
            this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
          },
        ),
      );
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  getIsTermAccept() {
    const dialogRef = this.matDialog.open(TermsConditionDialogComponent, {
      data: 'NORMAL',
      disableClose: true,
      height: '90%',
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.subscription.add(
          this.accountService.addAcceptTermsAndCondition(`"NORMAL"`).subscribe(
            () => {
              this.setRedirect();
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
            },
          ),
        );
      } else {
        this.layoutUtilsService.showActionNotification(ACTION_MESSAGES.TERMS_AND_CONDITION_LOGIN, MessageType.Update);
        this.subscription.add(
          this.auth.logout().subscribe(
            (data: any) => {
              this.subscription.add(this.messagingService.unSubscribeToken().subscribe((response: UserProfileModel) => {}));
              if (environment.isRXChat) {
                this.chatConfigService.teardownConnection();
              }
              this.data.changeMessage('');
              sessionStorage.clear();
              localStorage.clear();
              this.router.navigateByUrl('/auth/login');
            },
            (error: HttpErrorResponse) => {
              this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Update);
            },
          ),
        );
      }
    });
  }

  onClickVisibilityPassword() {
    this.hide = !this.hide;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
