import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { messages } from '@shared/constants/app.constants';

@Component({
  selector: 'sfl-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})
export class GalleryDialogComponent implements OnInit {
  viewLoading = false;
  disableCancel = false;
  selectedProperty: string;
  properties = [];
  singleView: any = [];
  index = 0;
  PLAY_AUDIO_IMAGE = messages.PLAY_AUDIO_IMAGE;
  PAUSE_AUDIO_IMAGE = messages.PAUSE_AUDIO_IMAGE;
  audioUrl = new Audio();
  isPlay = false;
  constructor(public dialogRef: MatDialogRef<GalleryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.index = this.data.index;
    this.singleView.push(this.data.attachment[this.index])
  }
  onLogoutClick() {
    this.dialogRef.close(false);
  }
  onClickNext() {
    this.isPlay = false;
    this.audioUrl.pause();
    this.singleView = [];
    this.index += 1;
    this.singleView.push(this.data.attachment[this.index])
  }
  onClickBack() {
    this.isPlay = false;
    this.audioUrl.pause();
    this.singleView = [];
    this.index -= 1;
    this.singleView.push(this.data.attachment[this.index])
  }
  onPlay(documentUrl) {
    this.audioUrl.src = documentUrl;
    this.audioUrl.play();
    this.isPlay = true;
  }
  onPause() {
    this.isPlay = false;
    this.audioUrl.pause();
  }
  onClickYes() {
    this.isPlay = false;
    this.audioUrl.pause();
    this.dialogRef.close(false);
  }
}