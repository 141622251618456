import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from '@layout/base/base.component';
import { AuthGuard, CleaningCompanyGuard, EventGuard, InspectionGuard, PMCGuard, PropertyGuard, TicketGuard } from '@shared/guards';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },

  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'inspection-detail-report',
    loadChildren: () => import('./inspection-detail-report/inspection-detail-report.module').then(m => m.InspectionDetailReportModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'open-survey-performer',
    loadChildren: () => import('./open-survey-performer/open-survey-performer.module').then(m => m.OpenSurveyPerformerModule)
  },
  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./entities/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('./entities/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'cleaning-companies',
        canActivateChild: [CleaningCompanyGuard],
        loadChildren: () => import('./cleaning-companies/cleaning-companies.module').then(m => m.CleaningCompaniesModule)
      },
      {
        path: 'property-management-companies',
        canActivateChild: [PMCGuard],
        loadChildren: () =>
          import('./property-management-company/property-management-company.module').then(m => m.PropertyManagementCompanyModule)
      },
      {
        path: 'building',
        canActivateChild: [PropertyGuard],
        loadChildren: () => import('./building/building.module').then(m => m.BuildingModule)
      },
      {
        path: 'qr-code',
        loadChildren: () => import('./qr-code/qr-code.module').then(m => m.QrCodeModule)
      },
      {
        path: 'inspection-question',
        loadChildren: () => import('./inspection/inspection.module').then(m => m.InspectionModule)
      },
      {
        path: 'inspection-question-config',
        loadChildren: () =>
          import('./inspection-question-config/inspection-question-config.module').then(m => m.InspectionQuestionConfigModule)
      },
      {
        path: 'inspections',
        canActivateChild: [InspectionGuard],
        loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule)
      },
      {
        path: 'tickets',
        canActivateChild: [TicketGuard],
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'events',
        canActivateChild: [EventGuard],
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'surveys',
        loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule)
      },
      {
        path: 'feedback',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'open-survey',
        loadChildren: () => import('./open-survey/open-survey.module').then(m => m.OpenSurveyModule)
      },
      {
        path: 'loop-vendors',
        loadChildren: () => import('./loop-vendor/loop-vendor.module').then(m => m.LoopVendorModule)
      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
