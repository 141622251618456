import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from '@layout/services';
import * as objectPath from 'object-path';

@Component({
  selector: 'sfl-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  fluid: boolean;

  constructor(private readonly layoutConfigService: LayoutConfigService) {}

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();

    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
  }
}
