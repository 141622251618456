import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { MyProfileService } from '@layout/my-profile/my-profile.service';
import { GalleryDialogComponent } from '@layout/partials/content/crud/gallery-dialog/gallery-dialog.component';
import { messages } from '@shared/constants/app.constants';
import { ACTION_MESSAGES } from '@shared/constants/messages.constant';
import { Alert } from '@shared/models/base.model';
import { ReactionsList, chatConfigDTO } from '@shared/models/chat.model';
import { AudioRecordingService } from '@shared/services/audio-recording.service';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import * as uuid from 'uuid';
import { ChatService } from '../chat.service';
import { ChatConfigService } from '../chat_config.service';
@Component({
  selector: 'sfl-conversation-room',
  templateUrl: './conversation-room.component.html',
  styleUrls: ['./conversation-room.component.scss'],
})
export class ConversationRoomComponent implements OnInit, OnDestroy {
  @Output() backToUserList: EventEmitter<string> = new EventEmitter<string>();
  @Output() startConversationInfo: EventEmitter<string> = new EventEmitter<string>();
  @Output() backToHistoryList: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  @ViewChild(AppComponent, { static: true }) appComponent: AppComponent;
  messagesList = [];
  highlightMessageClientId: string;
  IMAGE_PATH = messages.DEFAULT_IMAGE_PATH;
  SINGLE_USER_IMAGE_PATH = messages.SINGLE_USER_IMAGE_PATH;
  GROUP_USER_IMAGE_PATH = messages.GROUP_USER_IMAGE_PATH;
  CURSOR_POINTER = messages.CURSOR_POINTER;
  messageObj: chatConfigDTO = new chatConfigDTO();
  chatRoom: chatConfigDTO = new chatConfigDTO();
  messageText = '';
  @Input('selectedRoomDetails') selectedRoomDetails: any;
  subscription = new Subscription();
  userId = sessionStorage.getItem('userId');
  isScrollActive = true;
  isSpinner = false;
  selectedMessageDetails;
  menuOpenedForMessageClientId: string;
  showEmojisForClientId: string;
  showEmojisPanel = false;
  emojiList = ['LIKE', 'DISLIKE', 'QUESTION', 'EXCLAMATORY'];
  selectedMessageForReactions;
  selectedReaction;
  reactedUserList: Array<ReactionsList>;
  result: String = '';
  formData = new FormData();
  previews = [];
  selectedFiles?: FileList;
  isRecording = false;
  blobUrl;
  isAttachmentLimitOver = false;
  isAttachmentUpload = false;
  isButtonHidden: boolean = true;
  formDataArray = [];
  startPositionForScroll: number = 0;

  constructor(
    private readonly chatConfigService: ChatConfigService,
    private readonly chatService: ChatService,
    private readonly profileService: MyProfileService,
    private readonly audioService: AudioRecordingService,
    private readonly sanitizer: DomSanitizer,
    private readonly dialogRef: MatDialog,
  ) {
    this.subscription.add(
      this.audioService.recordingFailed().subscribe(() => {
        this.isRecording = false;
      }),
    );

    this.subscription.add(
      this.audioService.getRecordedBlob().subscribe(audioData => {
        if (audioData) {
          this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(audioData.blob));
        }
      }),
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.chatService.allChatMessageSubscriber.subscribe((msg: any) => {
        if (msg) {
          this.isSpinner = false;
          if (this.messagesList.length) {
            this.messagesList = this.messagesList.filter(msg => !msg.isTimeLabel);
          }
          this.messagesList = [...this.messagesList, ...msg.messages];
          let startDateTime = new Date(this.messagesList[this.messagesList.length - 1].timestamp);
          let endDateTime = new Date(this.messagesList[0].timestamp);
          let timeInterval = 15;
          let timeStamp = [endDateTime];
          for (let i = 0; i <= 1; i++) {
            endDateTime = new Date(endDateTime.getTime() - timeInterval * 60 * 1000);
            if (endDateTime > startDateTime) {
              timeStamp.push(endDateTime);
              i--;
            }
          }

          let tempObj = [];
          if (timeStamp.length === 1) {
            tempObj = this.messagesList.map(message => {
              return { ...message, isTimeLabel: false };
            });
            tempObj.push({ label: this.messagesList[this.messagesList.length - 1].timestamp, isTimeLabel: true });
          } else {
            for (let time of timeStamp) {
              const from = time;
              const to = new Date(time.getTime() - timeInterval * 60 * 1000);
              let count = 0;
              for (const message of this.messagesList) {
                const messageTime = new Date(message.timestamp);
                if (from >= messageTime && messageTime > to) {
                  message['isTimeLabel'] = false;
                  tempObj.push(message);
                  count++;
                }
              }
              if (count) {
                tempObj.push({ label: to, isTimeLabel: true });
              }
            }
          }

          this.messagesList = tempObj.filter((obj, index, self) => {
            if (!obj.clientMessageId) {
              return true;
            }
            const firstIndex = self.findIndex(item => item.clientMessageId === obj.clientMessageId);
            return index === firstIndex;
          });

          this.isScrollActive = msg.messages.length === 0 ? false : true;
          console.log('messages', this.messagesList);
          if (this.selectedRoomDetails.unreadMessageCount && this.messagesList.length) {
            this.initMarkAllMessageAsRead();
          }
        }
      }),
    );
    this.subscription.add(
      this.chatService.messageSendAckSubscriber.subscribe((msg: any) => {
        if (msg.acknowledgementStatus === 'MESSAGE_SENT') {
          if (this.messagesList.length === 0 || this.messagesList === undefined) {
            this.messageText = '';
            if (this.messageObj.sender.userToken) {
              this.messagesList.push({ ...this.messageObj, parent: this.selectedMessageDetails, messageStatus: true, isTimeLabel: false });
              this.messagesList.push({ isTimeLabel: true, label: this.messageObj.timestamp });
            }
          } else {
            this.messageText = '';
            if (this.messageObj.sender.userToken) {
              const startTime = new Date(this.messagesList[0].timestamp);
              const endTime = new Date(startTime.getTime() + 15 * 60 * 1000);
              const currentMessageDateTime = new Date(this.messageObj.timestamp);
              if (currentMessageDateTime > endTime) {
                this.messagesList = [
                  { ...this.messageObj, parent: this.selectedMessageDetails, messageStatus: true, isTimeLabel: false },
                  { isTimeLabel: true, label: this.messageObj.timestamp },
                ].concat(this.messagesList);
              } else {
                this.messagesList = [{ ...this.messageObj, parent: this.selectedMessageDetails, messageStatus: true, isTimeLabel: false }].concat(this.messagesList);
              }
            }
            this.messageObj = new chatConfigDTO();
            if (msg) {
              if (this.messagesList[0].clientMessageId === this.messagesList[1].clientMessageId) {
                this.messagesList.shift();
              }
            }
          }
          if (this.selectedMessageDetails) {
            setTimeout(() => {
              document.getElementById(msg.messageId).scrollIntoView({
                behavior: 'smooth',
              });
            }, 0);
          }
          this.selectedMessageDetails = null;
        }
      }),
    );
    this.subscription.add(
      this.chatService.latestChatMessageSubscriber.subscribe((msg: any) => {
        if (msg && this.selectedRoomDetails.roomId === msg.messages[0].roomId) {
          if (this.messagesList.length === 0 || this.messagesList === undefined) {
            this.messagesList.push({ ...msg.messages[0], isTimeLabel: false });
            this.messagesList.push({ isTimeLabel: true, label: msg.messages[0].timestamp });
          } else {
            if (this.userId === msg.messages[0].userToken) {
              if (this.messagesList[0].clientMessageId === msg.messages[0].clientMessageId) {
                return;
              }
            }
            const startTime = new Date(this.messagesList[0].timestamp);

            const endTime = new Date(startTime.getTime() + 15 * 60 * 1000);
            const currentMessageDateTime = new Date(msg.messages[0].timestamp);
            if (currentMessageDateTime > endTime) {
              this.messagesList = [
                { ...msg.messages[0], isTimeLabel: false },
                { isTimeLabel: true, label: msg.messages[0].timestamp },
              ].concat(this.messagesList);
            } else {
              this.messagesList = [{ ...msg.messages[0], isTimeLabel: false }].concat(this.messagesList);
            }
          }
          if (this.messagesList.length) {
            this.initMarkAllMessageAsRead();
          }
          console.log('Latest Messages', this.messagesList);
        }
      }),
    );
    this.subscription.add(
      this.chatService.chatRoomCreatedEvent.subscribe(msg => {
        if (msg) {
          this.selectedRoomDetails = msg;
          this.messagesList = [];
        }
      }),
    );
    this.subscription.add(
      this.chatService.onRemoveUserSubscriber.subscribe((msg: any) => {
        if (msg) {
          const isUserRemovedFromRoom = this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId;
          const isLoggedInUserAndRemovedUserAreSame = msg.messageDTO.userToken === sessionStorage.getItem('userId');
          if (isUserRemovedFromRoom) {
            this.messagesList = [msg.messageDTO].concat(this.messagesList);
            if (isLoggedInUserAndRemovedUserAreSame) {
              this.selectedRoomDetails.isUserLeft = false;
            }
          }
          this.chatService.onRemoveUserToGroup(null);
        }
      }),
    );

    this.subscription.add(
      this.chatService.onJoinGroup.asObservable().subscribe((msg: any) => {
        if (msg) {
          const isUserRemovedFromRoom = this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId;
          if (isUserRemovedFromRoom) {
            this.messagesList = [msg.messageDTO].concat(this.messagesList);
          }
          this.chatService.onJoinGroupByUser(null);
        }
      }),
    );
    this.subscription.add(
      this.chatService.onLeaveGroupSubscriber.subscribe((msg: any) => {
        if (msg) {
          const isUserRemovedFromRoom = this.selectedRoomDetails.roomId === msg.roomMemberDetailDTO.roomId;
          if (isUserRemovedFromRoom) {
            this.messagesList = [msg.messageDTO].concat(this.messagesList);
          }
          this.chatService.onLeaveGroupByUser(false);
        }
      }),
    );
    this.subscription.add(
      this.chatService.onUpdateRoomSubscriber.subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomId) {
            this.selectedRoomDetails.roomName = msg.roomName;
            this.chatService.onUpdateRoomName(null);
          }
        }
      }),
    );
    this.subscription.add(
      this.chatService.onDeleteGroupAdmin.asObservable().subscribe((msg: any) => {
        if (msg) {
          if (this.selectedRoomDetails.roomId === msg.roomId) {
            this.onClickBackToHistoryList();
            this.chatService.onDeleteGroupByAdmin(null);
          }
        }
      }),
    );
    this.subscription.add(
      this.chatService.onDeleteMessageAck.asObservable().subscribe(msg => {
        if (msg) {
          let deletedMessageIndex: number;
          if (msg.acknowledgementStatus === 'MESSAGE_DELETED') {
            deletedMessageIndex = this.messagesList.findIndex(messageDetail => messageDetail.clientMessageId === msg.messageId);
          } else {
            deletedMessageIndex = this.messagesList.findIndex(messageDetail => messageDetail.clientMessageId === msg.clientMessageId);
          }
          this.messagesList[deletedMessageIndex].messageStatus = false;
          this.chatService.onMessageDelete(null);
        }
      }),
    );
    this.subscription.add(
      this.chatService.onReactMessageAck.asObservable().subscribe(msg => {
        if (msg) {
          const selectedReactionMessageIndex = this.messagesList.findIndex(messageDetail => messageDetail.clientMessageId === msg.clientMessageId);

          this.messagesList[selectedReactionMessageIndex] = {
            ...this.messagesList[selectedReactionMessageIndex],
            reactions: msg.responseDTOS,
            currentUserReaction: msg.currentUserReaction,
          };

          this.chatService.onReactToMsgAck(null);
          this.showEmojisForClientId = null;
        }
      }),
    );
    this.subscription.add(
      this.chatService.onDeactivateUserAck.asObservable().subscribe(msg => {
        if (msg) {
          const selectedRoomDetailsIndex = msg.roomIds.findIndex(roomId => roomId === this.selectedRoomDetails.roomId);
          if (selectedRoomDetailsIndex > -1) {
            this.messagesList = [msg.messageDTO].concat(this.messagesList);
          }
        }
      }),
    );
    this.subscription.add(
      this.audioService.getRecordedBlob().subscribe(blob => {
        if (blob) {
          if (this.previews.length + 1 > 4) {
            this.isAttachmentLimitOver = true;
            return;
          }
          this.isAttachmentLimitOver = false;
          // this.formData.append('multipartFiles', blob.blob, blob.title);
          this.formDataArray.push({ data: blob.blob, type: 'AUDIO', name: blob.title });
          this.previews.push({ data: blob.blob, type: 'AUDIO', name: blob.title });

          // this.isImage = true;
          // this.subscription.add(
          //   this.ticketService.uploadImage(formData, 'AUDIO').subscribe(
          //     (res: Attachment) => {
          //       this.isImage = false;
          //       this.audioUrls.push({
          //         documentUrl: res.documentUrl,
          //         documentType: 'AUDIO'
          //       });
          //       this.isSaveEnabled = false;
          //     },
          //     (error: HttpErrorResponse) => {
          //       this.isImage = false;
          //       this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Create);
          //     }
          //   )
          // );
        }
      }),
    );
  }

  initMarkAllMessageAsRead(newIndex?: number) {
    let index = newIndex !== undefined ? newIndex : this.messagesList.length - 1;
    if (this.messagesList[index].clientMessageId) {
      this.markAllMessagesAsRead(this.messagesList[index].clientMessageId);
      return;
    } else {
      this.initMarkAllMessageAsRead(index - 1);
    }
  }

  markAllMessagesAsRead(clientMessageId) {
    if (this.messagesList.length && clientMessageId) {
      const params = {
        clientMessageId: clientMessageId,
        userToken: sessionStorage.getItem('userId'),
        roomId: this.selectedRoomDetails.roomId,
        deviceUniqueId: sessionStorage.getItem('deviceUniqueId'),
      };
      this.chatConfigService.markAllMessagesRead(params);
    }
  }

  onClickBackToHistoryList() {
    this.messagesList = [];
    this.chatService.setAllLatestChatGroup('');
    this.chatService.getLatestChatMessage('');
    this.chatService.getLatestChatGroup('');
    this.chatConfigService.getChatRoomList();
    this.selectedRoomDetails = null;
    this.backToHistoryList.emit();
  }
  onClickConversationInfo() {
    this.startConversationInfo.emit(this.selectedRoomDetails);
  }
  onClickSendMessage() {
    if (this.messageText.trim() === '' && this.previews.length === 0) {
      return;
    }
    if (this.previews.length > 0) {
      for (const file of this.formDataArray) {
        this.formData.append('multipartFiles', file.data, file.name);
      }
      this.sendAttachment(this.previews[0].type, this.formData);
    } else {
      this.sendMessage();
    }
  }
  sendMessage() {
    this.messageObj.message = this.messageText;
    this.messageObj.roomName = this.selectedRoomDetails.roomName;
    this.messageObj.userToken = sessionStorage.getItem('userId');
    this.messageObj.contentType = 'TEXT';
    this.messageObj.sender.userToken = sessionStorage.getItem('userId');
    this.messageObj.roomId = this.selectedRoomDetails.roomId;
    this.messageObj.isNotificationActive = true;
    this.messageObj.chatAttachments;
    this.messageObj.clientMessageId = uuid.v4();
    this.messageObj.recipients = null;
    this.messageObj.chatType = 'ROOM';
    this.messageObj.parentMessageId = this.selectedMessageDetails ? this.selectedMessageDetails.clientMessageId : null;
    const current = new Date();
    this.messageObj.timestamp = current.getTime();
    this.messageObj.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.chatRoomMessageSend(this.messageObj);
    this.previews = [];
    this.formData = new FormData();
    this.isAttachmentLimitOver = false;
    this.formDataArray = [];
  }

  onScrollUpShowDownArrow(event: any) {
    const scrollContainer = this.scrollContainer.nativeElement;
    if (this.startPositionForScroll >= event.target.offsetHeight + event.target.scrollTop + 50) {
      this.isButtonHidden = false;
    }

    if (!scrollContainer.scrollTop) {
      this.isButtonHidden = true;
    }

    this.startPositionForScroll = event.target.offsetHeight + event.target.scrollTop + 50;
  }

  onScroll(event) {
    if (this.isScrollActive) {
      this.isSpinner = true;
      this.chatRoom.userToken = sessionStorage.getItem('userId');
      this.chatRoom.roomId = this.selectedRoomDetails.roomId;
      this.chatRoom.size = messages.DEFAULT_PAGE_SIZE;
      this.chatRoom.page = this.chatRoom.page ? this.chatRoom.page + 1 : 1;
      this.chatRoom.target = this.selectedRoomDetails.roomName;
      this.chatRoom.sender.userToken = sessionStorage.getItem('userId');
      this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
      this.chatConfigService.getChatRoomMessagesHistory(this.chatRoom);
    }
  }

  onReply(message) {
    this.selectedMessageDetails = message;
    document.getElementById('messageBox').focus();
  }

  onMenuOpen(clientId: string) {
    this.menuOpenedForMessageClientId = clientId;
  }

  onMessageDelete(message) {
    const deleteParams = {
      clientMessageId: message.clientMessageId,
      userToken: message.userToken,
      roomId: message.roomId,
      messageStatus: false,
      deviceUniqueId: sessionStorage.getItem('deviceUniqueId'),
    };
    this.chatConfigService.deleteMessage(deleteParams);
  }

  emojiPathReacted(emoji) {
    if (emoji === 'LIKE') {
      return messages.LIKE_EMOJI_SELECTED;
    } else if (emoji === 'QUESTION') {
      return messages.QUESTION_EMOJI_SELECTED;
    } else if (emoji === 'EXCLAMATORY') {
      return messages.EXCLAMATORY_EMOJI_SELECTED;
    } else if (emoji === 'DISLIKE') {
      return messages.DISLIKE_EMOJI_SELECTED;
    }
  }

  scrolledToTheParentMessage(message) {
    if (!message.parent || this.menuOpenedForMessageClientId) return;
    setTimeout(() => {
      this.highlightMessageClientId = message.parent.clientMessageId;
      if (this.messagesList.some(messageDetail => message.parent.clientMessageId === messageDetail.clientMessageId)) {
        document.getElementById(message.parent.clientMessageId).scrollIntoView({
          behavior: 'smooth',
        });
        setTimeout(() => {
          this.highlightMessageClientId = undefined;
        }, 2000);
      } else {
        this.isSpinner = true;
        this.chatRoom.userToken = sessionStorage.getItem('userId');
        this.chatRoom.roomId = this.selectedRoomDetails.roomId;
        this.chatRoom.size = messages.DEFAULT_PAGE_SIZE;
        this.chatRoom.page = this.chatRoom.page ? this.chatRoom.page + 1 : 1;
        this.chatRoom.target = this.selectedRoomDetails.roomName;
        this.chatRoom.sender.userToken = sessionStorage.getItem('userId');
        this.chatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
        this.chatConfigService.getChatRoomMessagesHistory(this.chatRoom);
        this.scrolledToTheParentMessage(message);
      }
    }, 100);
  }

  emojiPathReaction(emoji, currentReaction) {
    if (emoji === 'LIKE') {
      return currentReaction === 'LIKE' ? messages.LIKE_EMOJI_SELECTED : messages.LIKE_EMOJI;
    } else if (emoji === 'QUESTION') {
      return currentReaction === 'QUESTION' ? messages.QUESTION_EMOJI_SELECTED : messages.QUESTION_EMOJI;
    } else if (emoji === 'EXCLAMATORY') {
      return currentReaction === 'EXCLAMATORY' ? messages.EXCLAMATORY_EMOJI_SELECTED : messages.EXCLAMATORY_EMOJI;
    } else if (emoji === 'DISLIKE') {
      return currentReaction === 'DISLIKE' ? messages.DISLIKE_EMOJI_SELECTED : messages.DISLIKE_EMOJI;
    }
  }

  onReactEmoji(message, emoji) {
    const reactionParams = {
      reaction: emoji,
      senderId: message.sender.id,
      clientMessageId: message.clientMessageId,
      timestamp: message.timestamp,
      userToken: this.userId,
      roomId: message.roomId,
      deviceUniqueId: sessionStorage.getItem('deviceUniqueId'),
    };
    this.chatConfigService.reactOnMessage(reactionParams);
  }

  removeReactionPanel() {
    this.showEmojisForClientId = null;
    this.showEmojisPanel = false;
  }

  removePanelOnOtherMessageClick(message) {
    if (this.showEmojisForClientId === message.clientMessageId) return;
    this.removeReactionPanel();
  }

  toggleReactionPanel(message) {
    if (this.showEmojisForClientId && this.showEmojisForClientId === message.clientMessageId) {
      this.removeReactionPanel();
      return;
    }
    this.showEmojisPanel = true;
    this.showEmojisForClientId = message.clientMessageId;
  }

  toggleReactedUserList(message, reaction) {
    this.reactedUserList = [];
    this.selectedMessageForReactions = message;
    this.selectedReaction = reaction;
    if (message) {
      this.subscription.add(
        this.chatService.getReactedUsers(message.clientMessageId, reaction.reaction).subscribe(response => {
          this.reactedUserList = response;
        }),
      );
    }
  }
  onSelectFile(event: any): void {
    let fileType: string;
    if (this.previews.length + event.target.files.length > 4) {
      this.isAttachmentLimitOver = true;
      return;
    }
    this.isAttachmentLimitOver = false;
    this.selectedFiles = event.target.files;
    for (const file of event.target.files) {
      const reader = new FileReader();
      // this.formData.append('multipartFiles', file, file.name);
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        fileType = 'IMAGE';
      } else if (file.type.indexOf('video') > -1) {
        fileType = 'VIDEO';
      } else if (file.type.indexOf('audio') > -1) {
        fileType = 'AUDIO';
      }
      this.formDataArray.push({ data: file, type: fileType, name: file.name });
      reader.onload = (e: any) => {
        this.previews.push({ data: e.target.result, type: fileType, name: file.name });
      };
    }
    // this.subscription.add(
    //   this.chatService.uploadAttachment(sessionStorage.getItem('userId'), fileType, formData).subscribe((res: any) => {
    //     for (const file of res) {
    //       this.messageObj.attachments.push({ url: file.documentUrl, type: fileType });
    //     }
    //   })
    // );
  }
  record() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioService.startRecording();
    } else if (this.isRecording) {
      this.isRecording = false;
      this.audioService.stopRecording();
    }
  }

  deleteAttachmentInList(index) {
    this.messageObj.chatAttachments.splice(index, 1);
    this.previews.splice(index, 1);
    this.isAttachmentLimitOver = false;
    this.formDataArray.splice(index, 1);
    if ((this.previews, length === 0 && this.formDataArray.length === 0)) {
      this.formDataArray = [];
      this.previews = [];
    }
  }
  sendAttachment(attachmentType, formData) {
    this.isAttachmentUpload = true;
    this.subscription.add(
      this.chatService.uploadAttachment(sessionStorage.getItem('userId'), attachmentType, formData).subscribe((res: any) => {
        for (const file of res) {
          this.messageObj.chatAttachments.push({ url: file.documentUrl, type: attachmentType });
          this.isAttachmentUpload = false;
        }
        this.sendMessage();
      }),
    );
  }

  viewAttachments(chatAttachments, imgIndex) {
    const alertData = new Alert();
    alertData.description = ACTION_MESSAGES.ALERT_FOR_DUPLICATE_SURVEY;
    const dialogRef = this.dialogRef.open(GalleryDialogComponent, {
      data: { attachment: chatAttachments, index: imgIndex },
      disableClose: true,
      width: '60%',
      height: '85%',
      minHeight: '430px',
    });
  }

  scrollToBottom(): void {
    const container = this.scrollContainer.nativeElement;
    container.scrollTop = container.scrollHeight;
    this.isButtonHidden = true;
  }

  userByName(index, user) {
    return user.clientMessageId;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
