import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatProgressBarModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { SharedModule } from '@shared/shared.module';
import { PortletComponent } from './portlet.component';
import { PortletBodyComponent } from './portlet-body.component';
import { PortletFooterComponent } from './portlet-footer.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  declarations: [
    PortletComponent,
    PortletBodyComponent,
    PortletFooterComponent
  ],
  exports: [
    PortletComponent,
    PortletBodyComponent,
    PortletFooterComponent
  ]
})
export class PortletModule { }
